export function initBtnDown() {
  var btnDown = document.getElementById("scrollToBottom");

  btnDown.style.display = "flex";

  var heightMappingPanel = document.getElementById("mapping_panel")
    .clientHeight;

  var getScroll = function() {
    if (pageYOffset > heightMappingPanel - window.innerHeight) {
      btnDown.style.opacity = 0;
    } else {
      btnDown.style.opacity = 1;
    }
  };

  window.addEventListener("scroll", getScroll);
}

export function scrollToBottom() {

  var scrollHeight = Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight
  );
  
  var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  if(isIE11){
    window.scrollTo(0,scrollHeight);
  } else {
  window.scrollBy({ top: scrollHeight, behavior: "smooth" });
  }
}
