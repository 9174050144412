$(".re_analyses.index").ready(function() {
  var _drawCounterEcho;
  var dataTableElement = $("#re_analyses_list_table")

  var $reAnalysesTable = dataTableElement.DataTable({
    dom: '<"toolbar">flrtip',
    order: [[5, "desc"]],
    language: {
      searchPlaceholder: "User name"
    },
    paging: true,
    page: 1,
    bAutoWidth: false,
    processing: true,
    serverSide: true,
    lengthMenu: [
      [10, 25, 50, 100],
      [10, 25, 50, 100]
    ],
    searchDelay: 1500,
    ajax: function(d, callback) {
      var data = d;
      _drawCounterEcho = data.draw;
      data.page =
        dataTableElement
          .DataTable()
          .page() + 1;
      data.query = data.search.value;
      data.direction = data.order[0]["dir"];

      if (window.$reAnalysesTable !== undefined) {
        data.sort = window.$reAnalysesTable.columns().header()[
          data.order[0]["column"]
          ].dataset.sortValue;
      }

      delete data.draw;
      delete data.columns;
      delete data.order;
      delete data.search;
      delete data.start;

      $.ajax({
        url: "/re_analyses.json",
        type: "GET",
        data: data,
        success: function(data) {
          callback({
            draw: _drawCounterEcho,
            data: data.data,
            recordsTotal: data.recordsTotal,
            recordsFiltered: data.recordsTotal
          });
        }
      });
    },
    columns: [
      { data: "organisation_name" },
      { data: "date_range_from" },
      { data: "date_range_to" },
      { data: "status" },
      { data: "user_name" },
      { data: "updated_at" }
    ]
  });

  window.$reAnalysesTable = $reAnalysesTable;
});