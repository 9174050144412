import { initDataTable } from "./common/helpers";

$(".picq_fields.index").ready(function() {
  initDataTable({
    tableId: "#picq_fields_table",
    orderVal: 1,
    targetsVal: [0, 7, 8]
  });
});

$(".picq_fields.edit").ready(function() {
  $("#picq_field_data_type").dropdown();
  $("#picq_field_mandatory").dropdown();
});
