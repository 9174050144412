import {
  initDataTable,
  toggleProfileCoderIdField,
  raiseBackendServiceError,
} from "./common/helpers"
import validatePasswordComplexity from "./common/services/password_complexity.service"

$(document).ready(function () {
  initDataTable({tableId: "#users_table", orderVal: 1, targetsVal: [0, 7]})
})

$(".users.admin_new, .users.admin_create").ready(function () {
  toggleProfileCoderIdField("#new_user")

  $("[data-name=profileRoleIdInput]").change(function () {
    toggleProfileCoderIdField("#new_user")
  })
})

document.addEventListener("DOMContentLoaded", function () {
  if (
    document.querySelector(".users.confirm") ||
    document.querySelector(".users.reset_password") ||
    document.querySelector(".users.set_password") ||
    document.querySelector(".users.edit_password") ||
    document.querySelector(".users.update_password")
  ) {
    validatePasswordComplexity(document.forms[0])
  }
})

function resetPasswordForAllUsersOfOrganisationUrl(organisationId) {
  return "/organisations/" + organisationId + "/users/reset_confirmations.json"
}

export function resetPasswordForAllUsersOfOrganisation(event, organisationId) {
  event.preventDefault()

  var message =
    "All users will be logged out and became unconfirmed, confirmation emails will be sent.\nAre you sure?"

  if (confirm(message)) {
    $.ajax({
      type: "POST",
      contentType: "application/json",
      url: resetPasswordForAllUsersOfOrganisationUrl(organisationId),
      success: function (response) {
        toastr.success(response.message)
      },
      error: function (response) {
        raiseBackendServiceError(response)
      },
    })
  }
}
