export var modalComponent = (function() {
  var showModal = function(params) {
    // kinda react props, remake later
    var selectorId = params.selectorId;
    var header = params.header;
    var text = params.text;
    var btnOk = params.ok;
    var btnCancel = params.cancel;
    var onApproveFunction = params.onApproveFunction;
    var onDenyFunction = params.onDenyFunction;

    $("[data-header]").text(header);
    $("[data-text]").text(text);
    $("[data-btn-ok]").text(btnOk);

    $("#" + selectorId)
      .modal({
        onApprove: function() {
          if (onApproveFunction !== undefined) {
            onApproveFunction();
          }
        },
        onDeny: function() {
          if (onDenyFunction !== undefined) {
            onDenyFunction();
          }
        }
      })
      .modal("show");
  };

  return {
    showModal: showModal
  };
})();
