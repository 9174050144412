import { initDataTable } from "./common/helpers";
import { modalComponent } from "./common/components/modal.component";
import {
  showFullScreenSpinner,
  hideFullScreenSpinner
} from "./reports/components/loading_cirlce_component";

var notificationBulkToggleUrl = "/api/v1/notifications/bulk_toggle.json";
var notificationToggleUrl = "/api/v1/notifications/toggle.json";
var dropNotificationSettingsUrl = "/api/v1/notifications/drop";

function toggleAllProfilesCheckboxesBy(degree, notificationKind, state) {
  $(`[name*='-${degree}-${notificationKind}']:enabled`).prop("checked", state);
}

function toggledToAsText(toggledTo) {
  return toggledTo ? "ON" : "OFF";
}

function sendNotificationBulkToggle(element) {
  var data = {
    organisation_id: element.data("organisationId"),
    notification_kind: element.data("notificationKind"),
    degree_kind: element.data("degreeKind")
  };

  $.ajax({
    type: "POST",
    contentType: "application/json",
    url: notificationBulkToggleUrl,
    data: JSON.stringify({ notification: data }),
    success: function(result) {
      if (element.data("notificationKind") === "per_extract_own_episodes")
        toggleAllProfilesCheckboxesBy(
          element.data("degreeKind"),
          element.data("notificationKind"),
          result.state
        );
      else
        toggleStandardUserCheckbox(
          `${element.data("degreeKind")}-${element.data("notificationKind")}`,
          result.state
        );
      setLocalBulk(
        element.data("degreeKind"),
        element.data("notificationKind"),
        result.state
      );
      hideFullScreenSpinner();
      toastr.success(
        `Notifications for all profiles successfully turned ${toggledToAsText(
          result.state
        )} for selected degree`
      );
    },
    error: function functionName(e) {
      toastr.error(e.responseJSON.errors_list);
      hideFullScreenSpinner();
    }
  });
}

function sendNotificationToggle(element) {
  var data = {
    profile_id: element.data("profileId"),
    notification_kind: element.data("notificationKind"),
    degree_kind: element.data("degreeKind")
  };

  $.ajax({
    type: "POST",
    contentType: "application/json",
    url: notificationToggleUrl,
    data: JSON.stringify({ notification: data }),
    success: function(result) {
      sessionStorage.setItem(`${element.data("profileId")}-${element.data(
        "degreeKind"
      )}-${element.data("notificationKind")}`, result.state);
      $(
        `[name*='${element.data("profileId")}-${element.data(
          "degreeKind"
        )}-${element.data("notificationKind")}']`
      ).prop("checked", result.state);
      hideFullScreenSpinner();

      toastr.success(
        `Notification successfully turned ${toggledToAsText(
          result.state
        )} for selected degree`
      );
    },
    error: function functionName(e) {
      toastr.error(e.responseJSON.errors_list);
      hideFullScreenSpinner();
    }
  });
}

function sendDropNotificationSettings(element) {
  var profileId = element.data("profileId");
  var state = false;
  var data = { profile_id: profileId };

  $.ajax({
    type: "DELETE",
    contentType: "application/json",
    url: dropNotificationSettingsUrl,
    data: JSON.stringify({ notification: data }),
    success: function(result) {
      $(`input[data-profile-id='${profileId}']`).prop("checked", state);
      hideFullScreenSpinner();

      toastr.success(
        `Notification successfully turned ${toggledToAsText(
          state
        )} for selected degree`
      );
    },
    error: function functionName(e) {
      toastr.error(e.responseJSON.errors_list);
      hideFullScreenSpinner();
    }
  });
}

$("#profilesNotificationsTable").ready(function() {

  sessionStorage.clear();

  // Initialize on click toggle of profile notification
  $("input.toggle-notification").on("click", function(e) {
    e.preventDefault();
    showFullScreenSpinner();
    sendNotificationToggle($(this));
  });

  // Notifications table
  var orderableColumns = [1, 2];
  var totalColumnsCount = $("#profilesNotificationsTable tr:nth-child(1) td")
    .length;

  var columnsToOrder = [];
  for (var i = 0; i < totalColumnsCount; i++) {
    if (orderableColumns.indexOf(i) == -1) {
      columnsToOrder.push(i);
    }
  }

  initDataTable({
    tableId: "#profilesNotificationsTable",
    targetsVal: columnsToOrder
  });

  // Initialize on click bulk toggle of all profiles notifications in the organisation
  $("#profilesNotificationsTable th.bulk-notifications-toggle-header").on(
    "click",
    function(e) {
      e.preventDefault();
      showFullScreenSpinner();
      sendNotificationBulkToggle($(this));
    }
  );

  $("#profilesNotificationsTable").on("click", function(e) {
    var linkElement = $(e.target).parent();
    if (linkElement.hasClass("dropNotificationSettings")) {
      e.preventDefault();

      var confirmationMessage = linkElement.data("confirmationMessage");

      if (confirm(confirmationMessage)) {
        showFullScreenSpinner();
        sendDropNotificationSettings(linkElement);
      }
    }
  });


  // Since the page and the pagination buttons are removed from the DOM, this retains the EventListener to affect
  // the changes required on to other pages
  // TODO: have the changes pulled from the backend instead
  $("a.paginate_button.item").attr("pageListener", "true");
  $("a.paginate_button.item").on("click", function(e) {
    e.preventDefault();
    getLocalBulk();
  });

  $("div#profilesNotificationsTable_length").on("click", function(e) {
    e.preventDefault();
    getLocalBulk();
  });

  $("#profilesNotificationsTable_paginate").on("click", function(e) {
    e.preventDefault();
    $("a.paginate_button.item").each(function() {
      if ($(this).attr("pageListener") !== "true") {
        $(this).attr("pageListener", "true");
        $(this).on("click", function(e) {
          e.preventDefault();
          getLocalBulk();
        });
      }
    });
  });
});

// Set to session storage so that it doesn't retain on page reload where it will get the actual data from backend
function setLocalBulk(degreeKind, notificationKind, state) {
  // remove all individual session storages with this value
  var toRemoveList = [];
  for (var i = 0; i < sessionStorage.length; i++) {
    if (sessionStorage.key(i).includes(`${degreeKind}-${notificationKind}`)) {
      toRemoveList.push(sessionStorage.key(i));
    }
  }
  while (toRemoveList.length != 0) {
    sessionStorage.removeItem(toRemoveList.pop());
  }
  sessionStorage.setItem(`${degreeKind}-${notificationKind}`, state);
}

function getLocalBulk() {
  var previousStates = {};
  for (var i = 0; i < sessionStorage.length; i++) 
    if (!isNaN(sessionStorage.key(i).split('-')[0]))
      previousStates[`${sessionStorage.key(i).split('-')[1]}-${sessionStorage.key(i).split('-')[2]}`] = sessionStorage.key(i).split('-')[0];
  for (var i = 0; i < sessionStorage.length; i++){
    if (sessionStorage.key(i).split('-')[1] === "per_extract_own_episodes") {
      toggleAllProfilesCheckboxesExcept(
        sessionStorage.key(i).split('-')[0],
        sessionStorage.key(i).split('-')[1],
        String(sessionStorage.getItem(sessionStorage.key(i))).toLowerCase() === "true",
        previousStates
      );
    }
    else
      toggleStandardUserCheckboxExcept(sessionStorage.key(i), String(sessionStorage.getItem(sessionStorage.key(i))).toLowerCase() === "true", previousStates);
  }
}

function toggleStandardUserCheckbox(checkbox_name, state) {
  $('td:contains("Standard User"):visible').parent("tr").find(`[name*='-${checkbox_name}']:enabled`).prop("checked", state);
}

function toggleAllProfilesCheckboxesExcept(degree, notificationKind, state, exceptions) {
  $(`[name*='-${degree}-${notificationKind}']:enabled`).each(function() {
    if (!(Object.keys(exceptions).includes(`${degree}-${notificationKind}`) && 
        exceptions[`${degree}-${notificationKind}`].includes($(this).attr('data-profile-id'))))
      $(this).prop("checked", state);
    else {
      $(this).prop("checked", !state);
    }
  });
}

function toggleStandardUserCheckboxExcept(checkbox_name, state, exceptions) {
  $('td:contains("Standard User"):visible').parent("tr").find(`[name*='-${checkbox_name}']:enabled`).each(function() {
    if (!(Object.keys(exceptions).includes(`${checkbox_name}`) && 
        exceptions[`${checkbox_name}`].includes($(this).attr('data-profile-id'))))
      $(this).prop("checked", state);
    else
      $(this).prop("checked", !state);
  });
}
