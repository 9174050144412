import calendar from "semantic-ui-calendar/src/definitions/modules/calendar";

import {
  showFullScreenSpinner,
  hideFullScreenSpinner
} from "../reports/components/loading_cirlce_component";
import { raiseBackendServiceError } from "../common/helpers";

var checkEpisodesCountPath = "/re_analyses/episodes_count";
var createReAnalysisPath = "/re_analyses";
function indicatorsPath(indicatorSetId) {
  return "/indicator_sets/" + indicatorSetId + "/indicators";
}

var datepickerToSelector = "#datepickerSelectTo";
var datepickerFromSelector = "#datepickerSelectFrom";
var indicatorSetsSelector = "#indicator_set_id";
var indicatorIdsSelector = "#indicator_ids";
var checkEpisodesCountButtonSelector = "#checkEpisodesCountButton";
var createReAnalysisButtonSelector = "#createReAnalysis";
var organisationIdSelector = "#organisation_id";

function dateRangeTo() {
  var element = $(datepickerToSelector);

  return element.prop("disabled") ? null : element.val();
}
function dateRangeFrom() {
  var element = $(datepickerFromSelector);

  return element.prop("disabled") ? null : element.val();
}
function organisationId() {
  return $(organisationIdSelector).val();
}
function indicatorIds() {
  return (
    $(indicatorIdsSelector)
    .dropdown("get value")
    .map(function(indicator) {
      return JSON.parse(String(indicator));
    })
  )
}

function initializeDatepicker() {
  $("#date_range_from_block, #date_range_to_block").calendar({
    type: "month"
  });
}

function updateIndicatorsSelectElement(response) {
  var indicatorsForSelect = response.data.indicators.map(function(indicator) {
    return {
      text: indicator.identifier,
      value: indicator.id,
      name: indicator.identifier
    };
  });

  $(indicatorIdsSelector).empty();
  $(indicatorIdsSelector).dropdown("clear");

  $.each(indicatorsForSelect, function(text, element) {
    $(indicatorIdsSelector).append(
      $("<option></option>")
        .attr("value", element.value)
        .attr("name", element.name)
        .text(element.text)
    );
  });
  $(indicatorIdsSelector).dropdown();
  hideFullScreenSpinner();
}

function fetchIndicators(indicatorSetId) {
  $.ajax({
    url: indicatorsPath(indicatorSetId),
    type: "get",
    dataType: "json",
    success: function(response) {
      updateIndicatorsSelectElement(response);
    },
    error: function(error) {
      hideFullScreenSpinner();
      console.log("Failed to get check episodes count for re-analysis");
    }
  });
}

function checkEpisodesCount() {
  $.ajax({
    url: checkEpisodesCountPath,
    type: "get",
    data: {
      date_range_to: dateRangeTo(),
      date_range_from: dateRangeFrom(),
      organisation_id: organisationId(),
      indicator_ids: indicatorIds(),
    },
    dataType: "json",
    success: function(data) {
      $("#epiodesCount").text(data.episodes_count);
      hideFullScreenSpinner();
    },
    error: function(error) {
      hideFullScreenSpinner();
      raiseBackendServiceError(error);
      console.log("Failed to get check episodes count for re-analysis");
    }
  });
}

function listenCheckEpisodesCountButton() {
  $(checkEpisodesCountButtonSelector).on("click", function(e) {
    e.preventDefault();

    showFullScreenSpinner();
    checkEpisodesCount();
  });
}

function listenIndicatorSetsSelect() {
  $(indicatorSetsSelector).on("change", function() {
    showFullScreenSpinner();
    fetchIndicators($(indicatorSetsSelector).val());
  });
}

function createReAnalysis() {
  $.ajax({
    url: createReAnalysisPath,
    type: "post",
    data: {
      date_range_to: dateRangeTo(),
      date_range_from: dateRangeFrom(),
      organisation_id: organisationId(),
      indicator_ids: indicatorIds(),
    },
    dataType: "json",
    success: function(data) {
      toastr.success("Re-analysis successfully created!");
      hideFullScreenSpinner();
    },
    error: function(error) {
      hideFullScreenSpinner();
      raiseBackendServiceError(error);
    }
  });
}

function listenCreateReAnalysisSubmit() {
  $(createReAnalysisButtonSelector).on("click", function(e) {
    e.preventDefault();

    showFullScreenSpinner();
    createReAnalysis();
  });
}

function disableSubmit() {
  $(createReAnalysisButtonSelector).attr("disabled", true);
  $(checkEpisodesCountButtonSelector).attr("disabled", true);
}

function enableSubmit() {
  $(createReAnalysisButtonSelector).removeAttr("disabled");
  $(checkEpisodesCountButtonSelector).removeAttr("disabled");
}

function checkRequiredDropdowns(elements) {
  var bBuff = [];

  $.each(elements, function(index, element) {
    bBuff.push(
      ($(element).dropdown("get values") &&
        $(element).dropdown("get values").length > 0) === true
    );
  });

  bBuff.length === 0 || (bBuff.numbers && bBuff.numbers.indexOf(false) !== -1)
    ? disableSubmit()
    : enableSubmit();
}

function listenIndicatorsSelect() {
  $(indicatorIdsSelector).dropdown("setting", "onChange", function() {
    if (
      ($(indicatorIdsSelector).dropdown("get values") &&
        $(indicatorIdsSelector).dropdown("get values").length > 0) === true
    ) {
      enableSubmit();
    } else {
      disableSubmit();
    }
  });
}

function listenDosSelect() {
  $("#date_range_select").on("change", function(e) {
    var dateToSelect = $("#datepickerSelectFrom");
    var dateFromSelect = $("#datepickerSelectTo");

    if (e.target.value === "Date range") {
      dateToSelect.prop("disabled", false);
      dateFromSelect.prop("disabled", false);
    } else {
      dateToSelect.prop("disabled", true);
      dateFromSelect.prop("disabled", true);
    }
  });
}

// ==========================================================================>>>
document.addEventListener("DOMContentLoaded", function() {
  if (!document.querySelector(".re_analyses.edit")) return;

  initializeDatepicker();
  listenCheckEpisodesCountButton();
  checkRequiredDropdowns([indicatorSetsSelector, indicatorIdsSelector]);
  listenIndicatorSetsSelect();
  listenIndicatorsSelect();
  listenCreateReAnalysisSubmit();
  listenDosSelect();
  disableSubmit();
});
// <<<==========================================================================
