document.addEventListener("DOMContentLoaded", function () {
  if (!document.querySelector(".reports.drg_report")) return

  reportContainer = $("#report-container").get(0)

  powerbi.bootstrap(reportContainer, {type: "report"})

  var models = window["powerbi-client"].models
  var reportLoadConfig = {
    type: "report",
    tokenType: models.TokenType.Embed,
    viewMode: models.ViewMode.View,

    settings: {
      background: models.BackgroundType.Transparent,
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
      layoutType: models.LayoutType.Custom,
      customLayout: {
        displayOption: models.DisplayOption.FitToPage,
      },
    },
  }
  reportLoadConfig.accessToken = token

  reportLoadConfig.embedUrl = embed_url

  tokenExpiry = token_expiry

  var report = powerbi.embed(reportContainer, reportLoadConfig)

  report.on("loaded", function () {
    console.log("Report load successful")
  })

  report.on("rendered", function () {
    console.log("Report render successful")
  })

  report.off("error")

  report.on("error", function (event) {
    var errorMsg = event.detail

    console.error(errorMsg)
    return
  })
})
