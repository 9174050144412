/*
  Reference: https://datatables.net/plug-ins/sorting/
             https://datatables.net/plug-ins/sorting/date-euro
*/

var alpha = /[^a-zA-Z]/g;
var numeric = /[^0-9]/g;
function alphaNumericSorter(a, b) {
  var aNumeric = a.replace(alpha, "");
  var bNumeric = b.replace(alpha, "");

  if (aNumeric === bNumeric) {
    var aAlpha = parseInt(a.replace(numeric, ""), 10);
    var bAlpha = parseInt(b.replace(numeric, ""), 10);

    return aAlpha === bAlpha ? 0 : aAlpha > bAlpha ? 1 : -1;
  } else {
    return aNumeric > bNumeric ? 1 : -1;
  }
}
  
var DataTablePlugins = function() {
  function initDateSortPlugin() {
    jQuery.extend(jQuery.fn.dataTableExt.oSort, {
      "date-euro-pre": function(a) {
        return Date.parse(
          a
            .split("/")
            .reverse()
            .join("-")
        );
      },

      "date-euro-asc": function(a, b) {
        return a - b;
      },

      "date-euro-desc": function(a, b) {
        return b - a;
      }
    });
  }

  function initStringifiedAlphaNumSortPlugin() { // Currently applied to MRN and UEID columns
    jQuery.extend(jQuery.fn.dataTableExt.oSort, {
      "custom-asc": function ( a, b ) {
          return alphaNumericSorter(a, b);
      },
      "custom-desc": function ( a, b ) {
          return alphaNumericSorter(a, b) * -1;
      },
    });
  }

  return {
    initDateSortPlugin: initDateSortPlugin,
    initStringifiedAlphaNumSortPlugin: initStringifiedAlphaNumSortPlugin
  };
};

export default DataTablePlugins;
