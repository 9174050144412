import {
  raiseBackendServiceError,
  compileEpisodeIndicatorsParams,
  checkUserRoleIs,
} from "../common/helpers"
import {timePeriodSelectComponent} from "./components/indicator_time_period_select_component"
import {dateRangeComponent} from "./components/date_range_component"
import {dateFiledTypeSelectComponent} from "./components/date_filed_type_select_component"
import {reportsSelectsComponent} from "./components/reports_selects_component"
import {degreeSelectComponent} from "./components/degrees_select_component"

let $dataTable
let ACTIVE_ELEM
let current_params
let specialist_enabled = false

document.addEventListener("DOMContentLoaded", function () {
  if (!document.querySelector(".reports.risq_impact_report")) return
  
  window.showChildTable = showChildTable
  specialist_enabled = $("th[data-sort-value='specialist-review-pending']").length !== 0

  timePeriodSelectComponent.initDropdown()
  timePeriodSelectComponent.initEventHandler()
  dateRangeComponent.initDatepicker()
  dateFiledTypeSelectComponent.initDropdown()
  dateFiledTypeSelectComponent.initEventHandler()
  reportsSelectsComponent.initReportsDependentDropdowns()
  degreeSelectComponent.initRisqDropdown()
  $("#indicator_time_period_select").dropdown("set selected", "Date range")
  $("#date_filed_type_select").dropdown("set selected", "dos")
  $("#datepickerSelectInput").show();

  $dataTable = initImpactReportTable("impact_report_table")
  getImpactReport()
})

export var getImpactReport = function () {
  showDataLoading()
  var request = $.ajax({
    url: "/reports/load_risq_impact.json",
    type: "GET",
    data: compileEpisodeIndicatorsParams(),
    success: function (data) {
      current_params = compileEpisodeIndicatorsParams()
      updateTotals(data)
      reorderData(data, $dataTable)
    },
    error: function (error) {
      hideDataLoading()
      raiseBackendServiceError(error)
    },
  })

  window.pendingAjaxRequests = [request]

  return request
}

function initImpactReportTable(tableIdString) {
  if (tableIdString === undefined) return

  var $dataTable = $("#" + tableIdString).DataTable({
    dom: '<"toolbar">flrtip',
    order: [[2, "desc"]],
    targets: "no-sort",
    paging: false,
    bSort: false,
    columnDefs: [
      {orderable: false, targets: [0]},
      {className: "episodeable", targets: [2,3,4,5,6,8].concat((specialist_enabled ? [9,11] : [10]))},
      {width: "3.2%", targets: [0]},
      {width: "13.9%", targets: [1]},
      {width: "4.2%", targets: [2]},
      {width: "8.74%", targets: [3,4,5,6,7,8,9,10,11]},
    ],
    language: {
      searchPlaceholder: "Search...",
    },
    drawCallback: function () {
      showDataLoading()
    },
    columns: [
      {
        data: null,
        defaultContent:
          '<i class="angle icon right" onClick="showChildTable(event)"></i>',
      },
      {className: "impact-datatable-label-column"},
    ].concat(new Array(specialist_enabled ? 11 : 10)),
    createdRow: function (row, data) {},
  })

  $dataTable.on("draw", function () {
    hideDataLoading()
  })

  $("#" + tableIdString + " tbody").on("click", "td", function (e) {
    if (!$(this).hasClass("episodeable")) {
      return
    }
    var col_sort = $dataTable
      .column($(this))
      .header()
      .getAttribute("data-sort-value")
    const tableParams = getTableParams($(this).closest("tr"));
    var paramsForRequest = {
      column_sort: col_sort,
      organisation_id: tableParams['organisation'],
      hospital: tableParams['site'],
      hac_category: tableParams['hac_category'],
      indicator_identifier: tableParams['indicator'],
    }

    generateEpisodeTableHeader($(this).closest("tr"), $dataTable.column($(this)).header().textContent)

    $dataTable.childTable = initEpisodeTable("episodesList", paramsForRequest)
  })

  return $dataTable
}

var updateTotals = function (data) {
  document.getElementById("coder-removal-summary").innerText =
    data.total.coder_removal_rate
  document.getElementById("coder-reviewed-summary").innerText =
    data.total.reviewed_by_coder
  document.getElementById("clinician-removal-summary").innerText =
    data.total.clinician_removal_rate
  document.getElementById("clinician-reviewed-summary").innerText =
    data.total.reviewed_by_clinician
  document.getElementById("fixed-coding-summary").innerText =
    data.total.fixed_resolved_by_coder
  document.getElementById("justified-coding-summary").innerText =
    data.total.justified_by_coder
  document.getElementById("removed-clinician-summary").innerText =
    data.total.hac_status_removed_clinician
  document.getElementById("unreviewed-summary").innerText = data.total.new
}

var reorderData = function (data, dataTable) {
  if (dataTable === undefined) return
  let table_data = []

  data.table_data.forEach(function (element) {
    if (element.separations === 0) return
    table_data.push({
      id: element.id,
      label: element.label,
      new: element.new,
      review_pending: element.review_pending,
      coder_reviewed: element.reviewed_by_coder,
      fixed_resolved_by_coder: element.fixed_resolved_by_coder,
      justified_by_coder: element.justified_by_coder,
      coder_removal_rate: element.coder_removal_rate,
      clinician_reviewed: element.reviewed_by_clinician,
      clinician_review_rate: element.clinician_review_rate,
      specialist_review_pending: element.specialist_review,
      hac_status_removed_clinician: element.hac_status_removed_clinician,
      clinician_removal_rate: element.clinician_removal_rate,
      separations: element.separations,
    })
  })

  dataTable.table().node().setAttribute('table_type', data.table_type)
  $('#' + dataTable.table().node().id).find('th.impact-datatable-label-column')[0].innerText = data.table_type === "hac_category" ? "HAC Category" : data.table_type
  updateImpactReport(table_data, dataTable)
}

var updateImpactReport = function (data, dataTable) {
  if (dataTable === undefined) return
  dataTable.clear()

  $.each(data, function (index, elem) {
    dataTable.row.add([
      elem["id"],
      elem["label"],
      elem["new"],
      elem["coder_reviewed"],
      elem["fixed_resolved_by_coder"],
      elem["justified_by_coder"],
      elem["review_pending"],
      elem["coder_removal_rate"],
      elem["clinician_reviewed"]].concat(
      (specialist_enabled ? [elem["specialist_review_pending"]] : []),
      [elem["clinician_review_rate"],
      elem["hac_status_removed_clinician"],
      elem["clinician_removal_rate"],
    ]))
  })

  dataTable.draw()
}

function showChildTable(event) {
  var button = $(event.target)
  var tr = button.closest("tr")
  const tableParams = getTableParams(tr);
  var dataTable = tr.closest("table").DataTable()
  var row = dataTable.row(tr)
  if (button.hasClass("right")) {
    button.removeClass("right")
    button.addClass("down")
    createChildTable(row, tableParams)
  } else {
    button.removeClass("down")
    button.addClass("right")
    destroyChildTable(row)
  }
}

function createChildTable(row, tableParams) {
  var table = $("<table/>")
  row.child(table).show()
  $.ajax({
    url: "/reports/load_risq_impact.json",
    type: "GET",
    data: {...current_params, organisation_id: tableParams['organisation'], hospital: tableParams['site'], hac_category: tableParams['hac_category']},
    success: function (data) {
      var childTable = table.DataTable({
        columnDefs: [
          {className: "episodeable", targets: ([2,3,4,5,6,8].concat((specialist_enabled ? [9,11] : [10])))},
        ],
        columns: [
          {
            data: null,
            defaultContent:
              data.table_type === 'indicator'
                ? '<div style="display:none;"></div>'
                : '<i class="angle icon right" onClick="showChildTable(event)"></i>',
            width: "3.2%",
          },
          {
            className: "impact-datatable-label-column",
            width: (specialist_enabled ? "8.5%" : "13.9%"),
          },
          {width: "4.2%"},
          {width: "8.74%"},
          {width: "8.74%"},
          {width: "8.74%"},
          {width: "8.74%"},
          {width: "8.74%"},
          {width: "8.74%"},
          {width: "8.74%"},
          {width: "8.74%"},
          {width: "8.74%"}].concat(specialist_enabled ? [{width: "8.74%"}] : []),
        autoWidth: false,
        searching: false,
        paging: false,
        info: false,
        ordering: false,
        fnDrawCallback: function () {
        },
      })
      reorderData(data, childTable)
    },
    error: function (error) {
      hideDataLoading()
      raiseBackendServiceError(error)
    },
  })
}

function destroyChildTable(row) {
  var table = $("table", row.child())
  table.detach()
  table.DataTable().destroy()
  row.child.hide()
}

function initEpisodeTable(tableIdString, params) {
  if (tableIdString === undefined) return

  var $dataTable = $("#" + tableIdString).DataTable({
    dom: '<"toolbar">rtip',
    destroy: true,
    paging: false,
    info: false,
    ordering: false,
    scrollY: true,
    columnDefs: [
      {className: "impact-datatable-label-column", targets: [0]},
      {orderable: false, targets: [-1]},
      {type: "date-euro", targets: [3]},
      {type: "custom", targets: [1, 2]},
      {
        targets: -1,
        data: null,
        render: function (data) {
          return (
            '<td> <a data-position="top right" ' +
            ' href="#" data-url="/episodes/review_episode?episode_id=' +
            data.id +
            "&indicator_category_id=" +
            window.gon.indicator_category_id +
            "&degrees=" +
            "HAC" +
            '" ' +
            ' class="review-episode" onclick="requestReview(event)"><span class="ui basic red button">Review</span></a> </td>'
          )
        },
      },
    ],
    columns: [
      {data: "hac", width: "20%"},
      {data: "mrn"},
      {data: "ueid"},
      {data: "dos"},
      {data: "role"},
      {data: "name"},
      {data: "status"},
      {data: "date", width: "2%"},
      {data: "body", width: "20%"},
      {data: null},
    ],
    createdRow: function (row, data, dataIndex, cells) {
      if (data.id === "") {
        cells[9].querySelector("a").style = "display: none;"
      } else {
        if (dataIndex === 0) return;
        row.classList.add('impact-row-border-top')
      }
    },
    ajax: function (data, callback) {
      showDataLoading()

      if (checkUserRoleIs("standard_summary_only") || checkUserRoleIs("sales"))
        return false

      if (ACTIVE_ELEM !== undefined) {
        $(ACTIVE_ELEM).removeClass("active")
      }
      ACTIVE_ELEM = $("[data-row-id=" + params.id + "]").parent()
      ACTIVE_ELEM.addClass("active")

      $("#impactEpisodesModal")
        .modal({
          onHidden: function () {
            $("body").removeClass("dimmable scrolling")
          },
        })
        .modal("show")

      $.ajax({
        url: "/reports/list_episodes_for_hac.json",
        type: "GET",
        data: {
          ...current_params,
          organisation_id: params.organisation_id,
          hospital: params.hospital,
          hac_category: params.hac_category,
          indicator_identifier: params.indicator_identifier,
          column_sort: params.column_sort,
        },
        success: function (data) {
          callback({
            data: data.episodes,
          })
        },
        error: function (error) {
          hideDataLoading()
          raiseBackendServiceError(error)
        },
      })
    },
  })

  $dataTable.on("draw", function () {
    hideDataLoading()
  })

  return $dataTable
}

function getTableParams(elem) {
  // receives table row elem being clicked on
  // returns org_id, hac_category, indicator_identifier as available
  let table_params = {'organisation': null, 'site': null, 'hac_category': null, 'indicator': null}
  
  let row = elem
  
  while (table_params['organisation'] ===  null) {
    let table_type = row.parents('table')[0].getAttribute('table_type')
    let current_table = row.parents('table').DataTable()
    let row_value = current_table.row(row).data()[0]

    table_params[table_type] = row_value
    row = row.parents("tr").prev()
  }
  
  return table_params
}

function generateEpisodeTableHeader(elem, column_sort) {
  let table_params = {'organisation': null, 'site': null, 'hac_category': null, 'indicator': null}

  let row = elem
  
  while (table_params['organisation'] ===  null) {
    let table_type = row.parents('table')[0].getAttribute('table_type')
    let current_table = row.parents('table').DataTable()
    let row_value = current_table.row(row).data()[1]

    table_params[table_type] = row_value
    row = row.parents("tr").prev()
  }

  let label_string = ""

  if (table_params['site'] !== null) {
    label_string = table_params['site'] + " - " + column_sort + " - "
  } else {
    label_string = table_params['organisation'] + " - " + column_sort + " - "
  }

  if (table_params['indicator'] !== null) {
    label_string = label_string + table_params['indicator']
  } else if (table_params['hac_category'] !== null) {
    label_string = label_string + "HAC " + table_params['hac_category']
  }
  label_string = label_string + " Episodes"

  document.getElementById("impactEpisodeTableHeader").innerText = label_string
}

function showDataLoading() {
  var requestReportButton = document.getElementById("requestReportButton")
  var abortReportButton = document.getElementById("abortReportButton")

  requestReportButton.style.display = "none"
  abortReportButton.style.display = "block"
}

function hideDataLoading() {
  var requestReportButton = document.getElementById("requestReportButton")
  var abortReportButton = document.getElementById("abortReportButton")
  requestReportButton.style.display = "block"
  abortReportButton.style.display = "none"
}
