import React, { useState } from "react";
import Container from "./container";
import Form from "./form";

const UserMapping = ({ sourceColumns, fieldMaps, picqFieldsforSelect, uploadedMapCheck }) => (
  <Container sourceColumns>
    <Form sourceColumns={sourceColumns} fieldMaps={fieldMaps} picqFieldsforSelect={picqFieldsforSelect} uploadedMapCheck={uploadedMapCheck} />
  </Container>
);

export default UserMapping;
