import React from "react"
import ReactDOM from "react-dom"

import CsvFile from "./common/csv_file"
import MappingForm from "./components/user-mapping/form"
import {initBtnDown} from "./common/scroll_to_bottom_component"
import {initDataTable, gon} from "./common/helpers"
import {raiseBackendServiceError} from "./common/helpers"
import {
  showFullScreenSpinner,
  hideFullScreenSpinner,
} from "./reports/components/loading_cirlce_component"

$(".organisations.index").ready(function () {
  initDataTable({
    tableId: "#organisations_table",
    orderVal: 1,
    targetsVal: [0, 5, 7],
    sortingDirection: "asc",
  })

  $(".ui.modal.mapping").on("click", "#cancel_copy_mapping", function (event) {
    event.preventDefault()
  })

  $(".ui.modal.mapping").on(
    "submit",
    "#copy_mapping_to_children_form",
    function (event) {
      event.preventDefault()
      var form = $(this)
      var actionUrl = form.attr("action")
      var parent_id = $('input[name="parent_id"]').val()
      var child_ids = $('input[name="child_ids[]"]:checked')
        .map(function (idx, ele) {
          return $(ele).val()
        })
        .get()

      if (child_ids.length == 0) {
        $(".copy_mapping_error_msg").show()
      } else {
        $.ajax({
          type: "POST",
          contentType: "application/json",
          url: actionUrl,
          async: true,
          data: JSON.stringify({parent_id: parent_id, child_ids: child_ids}),
          beforeSend: () => {
            $(".copy_mapping_error_msg").hide()
            $("#submit_copy_mappings_button").hide()
            $("#loading_button").show()
          },
          complete: () => {
            $("#submit_copy_mappings_button").show()
            $("#loading_button").hide()
            $(".ui.modal.mapping").modal("hide")
          },
          success: () => {
            toastr.success(
              "Mapping has successfully been copied from parent to child organisations",
            )
          },
          error: error => {
            raiseBackendServiceError(error)
          },
        })
      }
    },
  )
})

$(".organisations.edit").ready(function () {
  $("#organisation_organisation_type").dropdown()
  $("#organisation_parent_id").dropdown()

  if (
    $("#organisation_organisation_type").val() !=
    gon.organisation_types.state_health
  ) {
    $("#parent_organisation").show()
  }

  if (
    $("#organisation_organisation_type").val() ==
    gon.organisation_types.hospital_group
  ) {
    $("#codeOrganisation").hide()
  }

  $("#organisation_organisation_type").change(function () {
    if (
      $("#organisation_organisation_type").val() ==
      gon.organisation_types.state_health
    ) {
      $("#parent_organisation").hide()
    } else {
      $("#parent_organisation").show()
      getParentOrganisations($("#organisation_organisation_type").val())
    }
    if (
      $("#organisation_organisation_type").val() ==
      gon.organisation_types.hospital_group
    ) {
      $("#codeOrganisation").hide()
      $("#organisation_code").val("")
    } else {
      $("#codeOrganisation").show()
    }
  })

  var updateParentOrganisations = function (organisations) {
    var options = $("#organisation_parent_id")
    options.empty()
    $.each(organisations, function (i, e) {
      if (i == 0) {
        options.append($("<option />").val("").text(""))
      }
      options.append($("<option />").val(e.id).text(e.name))
    })
  }

  var getParentOrganisationsPath = function () {
    return "/organisations/parent_organisations.json"
  }

  var getParentOrganisations = function (orgType) {
    $.ajax({
      type: "GET",
      contentType: "application/json",
      url: getParentOrganisationsPath(),
      data: {org_type: orgType},
      async: false,
      success: function (result) {
        updateParentOrganisations(result)
        $("#organisation_parent_id").dropdown()
      },
      failure: function (error) {
        console.log("Failed to get indicators by episode report")
      },
    })
  }
})

$(".organisations.mapping").ready(function () {
  if ($(".organisations.mapping").length === 0) {
    return
  }

  showFullScreenSpinner()
  $.ajax({
    url: `${window.location}.json`,
    method: "GET",
    success: ({mapping}) => {
      const mappingSubmitFunction = () => {
        $.ajax({
          url: `/mappings/${mapping.id}.json`,
          method: "PUT",
          data: $("#mapping_form").serialize(),
          success: () => {
            toastr.success("Mapping has been successfully updated")
          },
          error: error => {
            raiseBackendServiceError(error)
          },
        })
      }
      const fieldMapCreateFunction = () => {
        return $.ajax({
          url: `/field_maps/create.json`,
          method: "POST",
          data: {mapping_id: mapping.id},
          success: () => {
            toastr.success("New field map created")
          },
          error: error => {
            raiseBackendServiceError(error)
          },
        })
      }
      const fieldMapUpdateFunction = (
        field_map_id,
        picq_field_id,
        source_column_name,
        kind,
      ) => {
        return $.ajax({
          url: `/field_maps/update.json`,
          method: "PUT",
          data: {
            field_map_id: field_map_id,
            picq_field_id: picq_field_id,
            source_column_name: source_column_name,
            kind: kind,
          },
          success: () => {
            toastr.success("Field map has been successfully updated")
          },
          error: error => {
            raiseBackendServiceError(error)
          },
        })
      }
      const fieldMapDestroyFunction = field_map_id => {
        return $.ajax({
          url: `/field_maps/destroy.json`,
          method: "DELETE",
          data: {field_map_id: field_map_id},
          success: () => {
            toastr.success("Field map deleted")
          },
          error: error => {
            raiseBackendServiceError(error)
          },
        })
      }
      const mappingValidateFunction = () => {
        return $.ajax({
          url: `/mappings/${mapping.id}/validate.json`,
          method: "POST",
          success: () => {
            toastr.success("Mapping is valid")
          },
          error: error => {
            raiseBackendServiceError(error)
          },
        })
      }

      const renderMappingReact = mapping => {
        ReactDOM.render(
          <MappingForm
            sourceColumns={mapping.field_maps.map(
              fieldMap => fieldMap.source_column_name,
            )}
            fieldMaps={mapping.field_maps}
            picqFieldsforSelect={mapping.picq_fields_for_select}
            mappingId={mapping.id}
            submitFunction={mappingSubmitFunction}
            createFunction={fieldMapCreateFunction}
            updateFunction={fieldMapUpdateFunction}
            destroyFunction={fieldMapDestroyFunction}
            validateFunction={mappingValidateFunction}
          />,
          document.getElementById("mapping_panel"),
          // callback function
          () => {
            hideFullScreenSpinner()
            initBtnDown()
          },
        )
      }

      $("#csv_confirm_button").on("click", async function (e) {
        var fileElement = $("#upload_csv_file")[0]
        var _csvFile
        if (fileElement.files.length > 0) {
          _csvFile = new CsvFile({
            inputFileElement: fileElement,
          })
          $.ajax({
            url: `/mappings/${mapping.id}/set_csv.json`,
            method: "POST",
            data: {
              mapping: {source_columns: await _csvFile.getColumnHeaders()},
            },
            success: res => {
              toastr.success("Mapping has been successfully updated")
              renderMappingReact(res.mapping)
            },
            error: error => {
              raiseBackendServiceError(error)
            },
          })
        }
      })

      renderMappingReact(mapping)
    },
    error: error => {
      hideFullScreenSpinner()
      raiseBackendServiceError(error)
    },
  })
  const handleFileSelected = function (evt) {
    var files = evt.target.files
    var is_valid = false
    var file_selected = files.length > 0

    if (file_selected) {
      // Add additional extension:
      // Example: RegExp("(.*?)\.(csv|doc|pdf|xml|bmp|ppt|xls)$");
      var regex = new RegExp("(.*?).(csv)$")
      $.each(files, function (i, file) {
        return (is_valid = regex.test(file.name.toLowerCase()))
      })

      // Show alert message if one of selected files with wrong extension
      if (file_selected && !is_valid) {
        toastr.error("Please select only CSV files", "File uploader")
        // Cleanup selected files
        $("#upload_csv_file").val("")
      }
    }
    $("#csv_confirm_button").prop("disabled", !is_valid)
  }
  $("#upload_csv").click(function () {
    $("#uploadCSVModalElement").modal({autofocus: false}).modal("show")
  })
  $("#upload_csv_file").on("change", function (e) {
    handleFileSelected(e)
  })
})
