var setZeroProgress = function() {
  $("#progressBlock").attr("data-percent", 0);
  $("#progressBlock .text-counter").text(0);
  $(".bar").css("stroke-dashoffset", 0);
};

var Validations = function() {
  var _mapping;
  var _csvFile;
  var _validationErrors = [];
  var _lineCount = 0;
  var _mrnMapping;
  var _ueidMapping;
  var _totalBytes = 0;
  var _currentBytes = 0;
  var _validationComplete;
  var _isCsvFileBroken = false;

  var $progressBlock = $("#progressBlock");
  var $progressBlockText = $("#progressBlock .text-counter");

  var getColumnNameFor = function(picqFieldName) {
    var field_maps = _mapping.field_maps;
    for (var i = 0; i < field_maps.length; ++i) {
      var picq_field = field_maps[i].picq_field;

      if (picq_field && picq_field.field_name === picqFieldName) {
        return field_maps[i].source_column_name.toLowerCase();
      }
    }
    return null;
  };

  var getPicqFieldNameFor = function(sourceColumnName) {
    var field_maps = _mapping.field_maps;
    for (var i = 0; i < field_maps.length; ++i) {
      if (field_maps[i].source_column_name.toLowerCase() === sourceColumnName) {
        var picq_field = field_maps[i].picq_field;
        if (picq_field) {
          return picq_field.field_name;
        }
      }
    }
    return null;
  };

  var validateFile = function() {
    _totalBytes = _csvFile.getFileSize();

    setZeroProgress();

    updateProgressBar();

    _csvFile.readFile(_mapping.field_maps, {
      header: true,
      transformHeader: header => header.toLowerCase(),
      onReadLines: validateLines,
      onComplete: onValidationComplete,
      onError: function(error) {
        _validationErrors.push({
          lineNum: error.row,
          columnNum: "N/A",
          columnName: "N/A",
          errorMsg: "Parsing error: " + error.code + ": " + error.message,
          picqFieldName: "N/A",
          ueid: "N/A",
          mrn: "N/A"
        });
      }
    });
  };

  var validateLines = function(lines) {
    return new Promise(function(onSuccess, onFailure) {
      setTimeout(function() {
        for (var i = 0; i < lines.length; ++i) {
          var line = lines[i];
          validateLine(line);
          if (_validationErrors.length > 500) {
            onFailure();
          }
          if (lines.length <= 1) {
            _isCsvFileBroken = true;
            onFailure();
          }
        }
      }, 0);
    });
  };

  var validateLine = function(line) {
    ++_lineCount;

    var mappedFields = _mapping.field_maps;
    for (var i = 0; i < mappedFields.length; ++i) {
      if (!mappedFields[i].picq_field) continue;

      var sourceColumnName = mappedFields[i].source_column_name.toLowerCase();
      var fieldContents = line[sourceColumnName].trim();
      var picqField = mappedFields[i].picq_field;
      var regExpValid = picqField.reg_exp_valid;
      if (regExpValid != null) {
        var r = new RegExp(regExpValid);
        if (!r.test(fieldContents)) {
          _validationErrors.push({
            lineNum: _lineCount + 1,
            columnNum: sourceColumnIndex,
            columnName: mappedFields[i].source_column_name,
            errorMsg:
              'Value "' + fieldContents + '" is not valid for this field',
            picqFieldName: picqField.field_name,
            ueid: line[_ueidMapping],
            mrn: line[_mrnMapping]
          });
        }
      }
    }

    validateDates(line);
    validateGaps(line);

    _currentBytes += getLineSize(line);

    updateProgressBar();
  };

  var validateDates = function(line) {
    var dos = line[getColumnNameFor("DOS")];
    var doa = line[getColumnNameFor("DOA")];
    var dob = line[getColumnNameFor("DOB")];
    var dosDate = parseDate(dos);
    var doaDate = parseDate(doa);
    var dobDate = parseDate(dob);

    if (dosDate && doaDate) {
      if (dosDate < doaDate) {
        _validationErrors.push({
          lineNum: _lineCount + 1,
          columnNum: "N/A",
          columnName: "N/A",
          errorMsg: "DOS " + dos + " is before DOA " + doa,
          picqFieldName: "DOS/DOA",
          ueid: line[_ueidMapping],
          mrn: line[_mrnMapping]
        });
      }
    }
    if (dobDate && doaDate) {
      if (dobDate > doaDate) {
        _validationErrors.push({
          lineNum: _lineCount + 1,
          columnNum: "N/A",
          columnName: "N/A",
          errorMsg: "DOB " + dob + " is after DOA " + doa,
          picqFieldName: "DOB/DOA",
          ueid: line[_ueidMapping],
          mrn: line[_mrnMapping]
        });
      }
    }
  };

  var parseDate = function(dateString) {
    try {
      var d = dateString.split("/");
      return new Date(parseInt(d[2]), parseInt(d[1]) - 1, parseInt(d[0]));
    } catch (exc) {
      return null;
    }
  };

  var validateGaps = function(line) {
    var dCodesFinished = false;
    var pCodesFinished = false;
    for (let [index, [key, value]] of Object.entries(line).entries()) {
      var picqFieldName = getPicqFieldNameFor(key);
      var fieldContents = value.trim();

      if (picqFieldName === "D01" || picqFieldName === "D??") {
        if (dCodesFinished && fieldContents.length > 0) {
          _validationErrors.push({
            lineNum: _lineCount + 1,
            columnNum: index - 1,
            columnName: key,
            errorMsg: "Gap in D?? codes",
            picqFieldName: "D??",
            ueid: line[_ueidMapping],
            mrn: line[_mrnMapping]
          });
          dCodesFinished = false;
        }
        if (fieldContents.length === 0) {
          dCodesFinished = true;
        }
      } else if (picqFieldName === "P??") {
        if (pCodesFinished && fieldContents.length > 0) {
          _validationErrors.push({
            lineNum: _lineCount + 1,
            columnNum: index - 1,
            columnName: key,
            errorMsg: "Gap in P?? codes",
            picqFieldName: "P??",
            ueid: line[_ueidMapping],
            mrn: line[_mrnMapping]
          });
          pCodesFinished = false;
        }
        if (fieldContents.length === 0) {
          pCodesFinished = true;
        }
      }
    }
  };

  var getLineSize = function(line) {
    var lineAsString = Object.values(line).join(",");
    return lineAsString.length;
  };

  var updateProgressBar = function() {
    // quick fix to improve upload UI on 8.5, remove later
    var fileSize = document.getElementById("upload_csv_file").files[0].size;
    var oneToTenMegabyteLength = 7;
    var IS_PROGRESS_COUNT_DISABLED;
    if (fileSize.toString().length < oneToTenMegabyteLength) {
      IS_PROGRESS_COUNT_DISABLED = true;
      $("#upload_progress_panel").addClass("hide-progress-block");
      $("#upload_progress_panel").removeClass("show-progress-block");
    } else {
      IS_PROGRESS_COUNT_DISABLED = false;
      $("#upload_progress_panel").addClass("show-progress-block");
      $("#upload_progress_panel").removeClass("hide-progress-block");
    }
    if (!IS_PROGRESS_COUNT_DISABLED) {
      if (_totalBytes > 0) {
        var percentage = Math.round(
          (_currentBytes * 100) / _totalBytes
        ).toFixed(0);
        $progressBlock.attr("data-percent", percentage);
        $progressBlockText.text(percentage);
      }
      var totalProgress = $(".progress-circle").attr("stroke-dasharray");
      var progress = $("#progressBlock").attr("data-percent");
      $(".bar").css(
        "stroke-dashoffset",
        (parseInt(totalProgress) * parseInt(progress)) / 100
      );
    }
  };

  var onValidationComplete = function() {
    $("#validation_panel").hide();
    $("#upload_progress_panel").hide();
    if (_isCsvFileBroken) {
      toastr.error("CSV file is broken");
      document.location.reload();
      return false;
    }

    if (_validationErrors.length > 0) {
      displayValidationErrors();
    } else {
      if (_validationComplete) {
        _validationComplete();
      }
    }
  };

  var displayValidationErrors = function() {
    if (_validationErrors.length > 500) {
      $("#validation_too_many_errors").show();
    } else {
      $("#validation_ignore_continue").show();
    }
    $("#validation_error_count").text(
      (_validationErrors.length > 500
        ? "More than 500 "
        : "" + _validationErrors.length + " ") +
        (_validationErrors.length > 1 ? "errors were " : "error was ") +
        "found when validating file:"
    );
    $("#validation_file_information").text(
      _csvFile.getFileName() + " (" + _csvFile.getFileSizeHuman() + ")"
    );
    var rowTemplate = $("table.picq-field-table tbody tr").first();
    $("table.picq-field-table tbody").empty();
    for (var i = 0; i < _validationErrors.length; ++i) {
      var columns = rowTemplate.children();
      $(columns[0]).text(_validationErrors[i].lineNum);
      $(columns[1]).text(_validationErrors[i].columnNum);
      $(columns[2]).text(_validationErrors[i].mrn);
      $(columns[3]).text(_validationErrors[i].ueid);
      $(columns[4]).text(_validationErrors[i].columnName);
      $(columns[5]).text(_validationErrors[i].picqFieldName);
      $(columns[6]).text(_validationErrors[i].errorMsg);
      $("table.picq-field-table tbody").append(rowTemplate.clone());
    }
    $("#validation_errors_panel").show();
  };

  var onRevalidateClicked = function(evt) {
    evt.preventDefault();
    document.location.reload();
  };

  this.init = function(opts) {
    _mapping = _mapping || opts.mapping;
    _mrnMapping = _mrnMapping || getColumnNameFor("MRN");
    _ueidMapping = _ueidMapping || getColumnNameFor("UEID");

    _validationComplete = _validationComplete || opts.onValidationComplete;
    _csvFile = _csvFile || opts.csvFile;
    $("#validation_revalidate")
      .off()
      .on("click", onRevalidateClicked);
    $("#mapping_panel").hide();
    $("#scrollToBottom").hide();
    $("#validation_panel").show();
    $("#upload_progress_panel").show();
    $("html, body").scrollTop(0);
    validateFile();
  };

  this.getInvalidLines = function() {
    return $.map(_validationErrors, function(v) {
      return v.lineNum;
    });
  };

  this.getRowCount = function() {
    return _lineCount;
  };
};

export default Validations;
