import { initDataTable } from "./common/helpers";
import { dateRangeComponent } from "./reports/components/date_range_component";

$(".indicator_sets.index").ready(function() {
  initDataTable({
    tableId: "#indicator_sets_table",
    orderVal: 1,
    targetsVal: [0, 4, 5]
  });
});

// Initialize and update datepicker min/max dates
// NOTE:
// default_from should be less than default_to
// default_to should be greater than default_from
$(".indicator_sets.edit, .indicator_sets.new").ready(function() {
  dateRangeComponent.initSimpleDatePicker();

  var updateDatepicker = function() {
    $("#indicator_set_default_from").datepicker(
      "option",
      "maxDate",
      $("#indicator_set_default_to").val()
    );
    $("#indicator_set_default_to").datepicker(
      "option",
      "minDate",
      $("#indicator_set_default_from").val()
    );
  };

  updateDatepicker();

  $(".date-picker").on("change", function(e) {
    updateDatepicker();
  });
});
