import React, { useState, useEffect } from "react";
import { Select } from "semantic-ui-react";
import Kind from "./kind";

const FieldMap = ({
  fieldMapId,
  picqFieldId,
  sourceColumnName,
  selectOptions,
  userPicqField,
  wardPicqField,
  fundPicqField,
  specialtyPicqField,
  deleteFunction,
  updateFunction,
  uploadedMapCheck,
  fieldMapKind
}) => {
  const [state, setState] = useState(picqFieldId);
  const isKindBlockHidden = (fieldMapId) => !(userPicqField && state === userPicqField.id || wardPicqField && state === wardPicqField.id || fundPicqField && state === fundPicqField.id || specialtyPicqField && state === specialtyPicqField.id);

  const sourceNameRef = React.createRef(null);

  const checkDelete = (e) => {
    e.preventDefault();
    if (picqFieldId !== null && selectOptions[picqFieldId-1].text.includes('??')) {
      $("#mapping_confirm_button").off();
      $("#mapping_confirm_button").on('click', () => {
        deleteFunction(fieldMapId);
      });
      $("#mappingConfirmText").text("You have selected to delete a compound field. The order of compound data must be preserved with no gaps.");
      $("#mappingConfirmModalElement").modal({autofocus: false}).modal("show");
    } else {
      deleteFunction(fieldMapId);
    }
  };

  const checkApply = (e) => {
    e.preventDefault();

    let kind = null;
    if (document.getElementsByName(`field_map[${fieldMapId}][kind]`)[0].checked) {
      kind = document.getElementsByName(`field_map[${fieldMapId}][kind]`)[0].value
    } else if (document.getElementsByName(`field_map[${fieldMapId}][kind]`)[1].checked) {
      kind = document.getElementsByName(`field_map[${fieldMapId}][kind]`)[1].value
    }
    if (state === picqFieldId && sourceNameRef.current.value === sourceColumnName && kind == fieldMapKind) {
      toastr.error('No changes to be applied');
      return;
    }

    if (picqFieldId !== null && selectOptions[picqFieldId-1].text.includes('??')) {
      $("#mapping_confirm_button").off();
      $("#mapping_confirm_button").on('click', () => {
        updateFunction(fieldMapId, state, sourceNameRef.current.value, kind);
      });
      $("#mappingConfirmText").text("You have selected to modify a compound field. The order of compound data must be preserved with no gaps.");
      $("#mappingConfirmModalElement").modal({autofocus: false}).modal("show");
    } else {
      updateFunction(fieldMapId, state, sourceNameRef.current.value, kind);
    }
  };

  const checkUpdateChanges = (e) => {
    if (uploadedMapCheck === true) return;
    let kind = null;
    if (document.getElementsByName(`field_map[${fieldMapId}][kind]`)[0].checked) {
      kind = document.getElementsByName(`field_map[${fieldMapId}][kind]`)[0].value
    } else if (document.getElementsByName(`field_map[${fieldMapId}][kind]`)[1].checked) {
      kind = document.getElementsByName(`field_map[${fieldMapId}][kind]`)[1].value
    }

    if (state === picqFieldId && sourceNameRef.current.value === sourceColumnName && kind == fieldMapKind) {
      document.getElementById(`apply_field_${fieldMapId}`).disabled = true;
    } else {
      document.getElementById(`apply_field_${fieldMapId}`).disabled = false;
    }
  }

  useEffect(() => {
    setState(picqFieldId);
  }, [picqFieldId]);

  useEffect(() => {
    checkUpdateChanges();
  });

  return (
    <>
      <div className="field-map-block">
        { uploadedMapCheck === true ?
          <div className="field-map source-column">
            {sourceColumnName}
          </div>
          :
          <input className="field-map source-column"
            defaultValue={sourceColumnName}
            ref={sourceNameRef}
            onChange={checkUpdateChanges}
          />}
        <div className="destination-column">
          <Select
            placeholder=""
            options={selectOptions}
            className="field-map-select"
            value={state}
            onChange={(event, select) => {
              setState(select.value);
            }}
          />
          <input
            value={state || ""}
            type="hidden"
            name={`field_map[${fieldMapId}][picq_field_id]`}
          />
        </div>
        { uploadedMapCheck !== true ?
        <div
          className={`field-map-kind-block inline fields transition`}
        >
          <div className="submit">
            <input
              onClick={checkDelete}
              value="Delete Field"
              type="button"
              role="button"
              id="delete_field"
              className="ui red button"
            />
          </div>
        </div>
        : null}
        {uploadedMapCheck !== true ? 
        <div className='field-map-kind-block inline fields transition'>
          <div className="submit">
            <input
              onClick={checkApply}
              value="Update"
              type="button"
              role="button"
              id={`apply_field_${fieldMapId}`}
              className="ui green button"
            />
          </div>
        </div>
        : null}
        <div
          className={`field-map-kind-block inline fields transition ${
            isKindBlockHidden(fieldMapId) ? "hidden" : ""
          }`}
        >
          <Kind
            fieldMapId={fieldMapId}
            value="default"
            name="Default"
            disabled={isKindBlockHidden(fieldMapId)}
            checked={fieldMapKind === 'default'}
            checkUpdateChanges={checkUpdateChanges}
          />
          <Kind
            fieldMapId={fieldMapId}
            value="use_in_filters"
            name="Use in filters"
            disabled={isKindBlockHidden(fieldMapId)}
            checked={fieldMapKind === 'use_in_filters'}
            checkUpdateChanges={checkUpdateChanges}
          />
        </div>
      </div>
      <div className="clear-float" />
      <br />
    </>
  );
};

export default FieldMap;
