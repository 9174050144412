import {
  restyleCheckboxes,
  raiseBackendServiceError,
  handleUncheckInputByName
} from "./common/helpers";
import { dateRangeComponent } from "./reports/components/date_range_component";

$(".ecl_editions.index").ready(function() {
  initEclEditionsDataTable();
});

$(".ecl_editions.edit, .ecl_editions.new").ready(function() {
  dateRangeComponent.initSimpleDatePicker();

  var updateDatepicker = function() {
    $("#indicator_set_default_from").datepicker(
      "option",
      "maxDate",
      $("#indicator_set_default_to").val()
    );
    $("#indicator_set_default_to").datepicker(
      "option",
      "minDate",
      $("#indicator_set_default_from").val()
    );
  };

  updateDatepicker();

  $(".date-picker").on("change", function(e) {
    updateDatepicker();
  });
});

export function handleDeleteEclEditions(e) {
  e.preventDefault();
  e.stopPropagation();

  var myForm = document.forms[0];
  var checkedIds = [];

  for (var i = 0; i < myForm.elements.length; ++i) {
    if (
      myForm.elements[i].type == "checkbox" &&
      myForm.elements[i].name != "select_all"
    ) {
      if (myForm.elements[i].checked) {
        checkedIds.push(myForm.elements[i].dataset["id"]);
      }
    }
  }

  if (checkedIds.length > 0) {
    if (
      confirm(
        "Are you sure you want to delete the EclEdition(s), it will delete all ECLs as well"
      )
    ) {
      $.ajax({
        url: window.location.pathname + "/bulk_destroy.json",
        type: "DELETE",
        data: {
          ids: checkedIds
        },
        success: function() {
          window.location.reload();
        },
        error: function(error) {
          raiseBackendServiceError(error);
        }
      });
    }
  } else {
    alert(
      "Please check the checkbox next to the EclEdition(s) you want to delete, then click Delete"
    );
  }
}

function initEclEditionsDataTable() {
  var _drawCounterEcho;

  var $eclEditionsTable = $("#ecl_editions_table").DataTable({
    dom: '<"toolbar">flrtip',
    order: [[2, "desc"]],
    language: {
      searchPlaceholder: "Name"
    },
    paging: true,
    page: 1,
    bAutoWidth: false,
    processing: true,
    serverSide: true,
    lengthMenu: [
      [10, 25, 50, 100],
      [10, 25, 50, 100]
    ],
    columnDefs: [{ orderable: false, targets: [0, 5, 6] }],
    searchDelay: 1500,
    ajax: function(d, callback) {
      var data = d;
      _drawCounterEcho = data.draw;
      data.page =
        $("#ecl_editions_table")
          .DataTable()
          .page() + 1;
      data.query = data.search.value;
      data.direction = data.order[0]["dir"];

      if (window.$eclEditionsTable !== undefined) {
        data.sort = window.$eclEditionsTable.columns().header()[
          data.order[0]["column"]
        ].dataset.sortValue;
      }

      delete data.draw;
      delete data.columns;
      delete data.order;
      delete data.search;
      delete data.start;

      $.ajax({
        url: "/ecl_editions.json",
        type: "GET",
        data: data,
        success: function(data) {
          handleUncheckInputByName("select_all");
          callback({
            draw: _drawCounterEcho,
            data: data.data,
            recordsTotal: data.recordsTotal,
            recordsFiltered: data.recordsTotal
          });
        }
      });
    },
    columns: [
      {
        data: null,
        render: function(data) {
          return (
            "<input type='checkbox'data-id='" + data["id"] + "'><label></label>"
          );
        }
      },
      { data: "name" },
      { data: "version" },
      { data: "start_date" },
      { data: "end_date" },
      { data: "count" },
      {
        data: null,
        render: function(data) {
          return (
            '<a class="icon-action" title="Edit" href="' +
            data.edit_ecl_edition_path +
            '">' +
            '<i class="edit icon"></i>' +
            "</a>" +
            '<a class="icon-action" title="Codes" href="' +
            data.ecl_edition_ecls_path +
            '">' +
            '<i class="list icon"></i>' +
            "</a>" +
            '<a href="' +
            data.download_path +
            '" class="icon-action" title="Download">' +
            '<i class="download icon"></i>' +
            "</a>" +
            '<a href="' +
            data.upload_path +
            '" class="icon-action" title="Upload">' +
            '<i class="upload icon"></i>' +
            "</a>"
          );
        }
      }
    ],
    drawCallback: function() {
      restyleCheckboxes();
    }
  });

  if ($("#table_actions") !== undefined) {
    $("div.toolbar").html($("#table_actions"));
  }

  window.$eclEditionsTable = $eclEditionsTable;
}
