import { dateRangeComponent } from "./components/date_range_component";
import {
  showDataLoading,
  hideDataLoading
} from "./components/loading_cirlce_component";
import { sendRequestToGenerateExcel } from "./services/reports-api.service";
import { dateFiledTypeSelectComponent } from "./components/date_filed_type_select_component";
import { degreeSelectComponent } from "./components/degrees_select_component";
import { paramsCompilerService } from "./services/params-compiler.service";
import { raiseBackendServiceError } from "../common/helpers";

const selectedBenchmarkValues = () => {
  const value = $("#" + $("#benchmark_type_select").val() + "_select").val()

  return (value === 'All' || !value) ? ['All'] : value
}

export var getBenchmarkParams = function() {
  var defaultSelects = {
    benchmark_type: $("#benchmark_type_select").val(),
    benchmark_values: selectedBenchmarkValues(),
    degree: $("#indicator_degrees_select").val(),
    graph_type: $("#graph_type_select").val(),
    filter: $("#filter_select").val(),
    tag: $("#tag_select").val()
  };

  return paramsCompilerService.compileParams(
    defaultSelects,
    $("#date_filed_type_select").val()
  );
};

var displayBenchmarkBarChart = function() {
  // clean all on updating state
  $("#benchmarkContainer").show();
  d3.select("#benchmark_graph")
    .selectAll("*")
    .remove();
  d3.select("#benchmarkYLabel")
    .selectAll("*")
    .remove();
  // end of clean

  var params = getBenchmarkParams();
  if (params === false) {
    return;
  }

  getBenchmarkReport(params);
};

var displayBenchmarkTabularChart = function() {
  // clean all on updating state
  $("#tabular_benchmark_graph").empty();
  $("#benchmarkContainer").hide();
  // end of clean

  var defaultSelects = {
    benchmark_type: $("#benchmark_type_select").val(),
    benchmark_values: selectedBenchmarkValues(),
    degree: $("#indicator_degrees_select").val(),
    graph_type: $("#graph_type_select").val(),
    filter: $("#filter_select").val(),
    tag: $("#tag_select").val(),
    benchmark_period: $("#benchmark_period_select").val()
  };

  var benchmarkTabularParams = paramsCompilerService.compileParams(
    defaultSelects,
    $("#date_filed_type_select").val()
  );

  if (benchmarkTabularParams === false) {
    return;
  }

  getTabularBenchmarkReport(benchmarkTabularParams);
};

document.addEventListener("DOMContentLoaded", function() {
  if (!document.querySelector(".reports.benchmark_report")) return;
  dateRangeComponent.initDatepicker();
  $("#indicator_time_period_select option[value='All Time']").remove();
  $("#indicator_time_period_select option")
    .eq(4)
    .before(
      $("<option></option>")
        .val(120)
        .html("120 Days")
    );
  $("#coder_select").dropdown();
  $("#hospital_select").dropdown();
  $("#hospital_group_select").dropdown();
  $("#indicator_degrees_select").dropdown();
  $("#risq_indicator_degrees_select").dropdown();
  $("#indicator_time_period_select").dropdown();
  $("#graph_type_select").dropdown();
  $("#benchmark_type_select").dropdown();
  $("#filter_select").dropdown();
  $("#clinician_select").dropdown();
  $("#chart_select").dropdown();
  $("#benchmark_period_select").dropdown();
  $("#tag_select").dropdown();
  $("#clinician_select").dropdown();

  dateFiledTypeSelectComponent.initDropdown();
  dateFiledTypeSelectComponent.initEventHandler();

  degreeSelectComponent.initDropdown();

  $("#mdc_select").dropdown({
    useLabels: false,
    onChange: function(value, text, $choice) {
      document.getElementById('mdc_select').parentElement.querySelector('i.icon').classList.remove('display-none')

      if (text === 'All' || !value) {
        $("#mdc_select").dropdown('set value', 'All')
      }
    },
    defaultValue: 'All'
  })

  let mdc_icon = document.getElementById('mdc_select').parentElement.querySelector('i.icon')
  mdc_icon.classList.remove('dropdown')
  mdc_icon.classList.add('delete')
  mdc_icon.addEventListener('click', function(e) {
    $("#mdc_select").dropdown('clear')
  })


  $("#benchmark_type_select").change(function() {
    showHideDependentSelects();
  });

  $("#filter_select").change(function(e) {
    // TODO: on load and on change
    $("#graphLabelsWrapper div").hide();
    $("#graphLabelsWrapper")
      .find("div[data-" + e.target.value + "]")
      .show();
  });
  $("#indicator_time_period_select").change(function() {
    dateRangeComponent.showHideSelectsByTimePeriod();
  });

  $("#chart_options_select").click(function() {
    displayBenchmarkChart();
  });

  $("a#benchmark_report_download_excel_link").click(function(e) {
    e.preventDefault();

    // TODO: Vlad - check and compare this params with backend
    var defaultSelects = {
      filter: $("#filter_select").val(),
      benchmark_type: $("#benchmark_type_select").val(),
      benchmark_values: selectedBenchmarkValues(),
      degree: $("#indicator_degrees_select").val(),
      tag: $("#tag_select").val()
    };

    var compiledParams = paramsCompilerService.compileParams(
      defaultSelects,
      $("#report_type_select").val(),
      $("#date_filed_type_select").val()
    );

    if (compiledParams === false) {
      return;
    }

    sendRequestToGenerateExcel($(this).attr("href"), compiledParams);
  });

  getBenchmarkReport(getBenchmarkParams());
});

var getBenchmarkReport = function(params) {
  var svg = d3.select("#benchmark_graph");
  svg.selectAll("*").remove();
  showDataLoading();
  var request = $.ajax({
    type: "GET",
    url: "/reports/load_benchmark_report.json",
    data: params,
    success: function(result) {
      displayStackedGraph(
        result.benchmark_data,
        result.average_before_data,
        result.average_after_data,
        params.filter,
        params.graph_type,
        result.degrees
      );
      hideDataLoading();
    },
    error: function(error) {
      hideDataLoading();
      raiseBackendServiceError(error);
    }
  });
  window.pendingAjaxRequests = [request];
};

var displayStackedGraph = function(
  data,
  average_before_data,
  average_after_data,
  filter,
  graph_type,
  degrees
) {
  showHideLabelsByData();

  function showHideLabelsByData() {
    if (data.length === 0) {
      $("#graphLabelsWrapper div").hide();
    } else {
      $("#graphLabelsWrapper")
        .find("div[data-" + $("#filter_select").val() + "]")
        .show();
    }
  }

  var margin = { top: 20, right: 24, bottom: 30, left: 44 };
  var minimalWidth =
    $("#benchmarkContainer").width() - margin.left - margin.right;
  var dataRelatedWidth = data.length * 100;
  var width = minimalWidth > dataRelatedWidth ? minimalWidth : dataRelatedWidth;
  var height = 500 - margin.top - margin.bottom;

  var x0 = d3
    .scaleBand()
    .rangeRound([0, width], 0.1)
    .paddingInner(0.1);

  var x1 = d3.scaleBand().padding(0.05);

  var y = d3.scaleLinear().range([height, 0]);

  var xAxis = d3.axisBottom().scale(x0);

  var format = ".2s";

  if (graph_type == "QR") {
    format = ".2r";
  }

  var yAxis = d3
    .axisLeft()
    .scale(y)
    .tickFormat(d3.format(format));

  var divTooltip = d3
    .select("body")
    .append("div")
    .attr("class", "toolTip");

  var columnHeaders = degrees
    .map(function(degree) {
      return degree + "(B)";
    })
    .concat(
      degrees.map(function(degree) {
        return degree + "(A)";
      })
    );

  function resolveLegendColors() {
    var beforeColors = {
      F: "#D64423",
      W1: "#ED6C1F",
      W2: "#FED530",
      R: "#BCCAD3"
    };

    var afterColors = {
      F: "#7B6888",
      W1: "#8A89A6",
      W2: "#98ABC5",
      R: "#DCEAF3"
    };

    var beforeLegendColors = degrees.map(function(degree) {
      return beforeColors[degree];
    });
    var afterLegendColors = degrees.map(function(degree) {
      return afterColors[degree];
    });

    switch (filter) {
      case "before":
        return beforeLegendColors;
      case "after":
        return afterLegendColors;
      case "before_after":
        return beforeLegendColors.concat(afterLegendColors);
    }
  }

  var yAxisLegend = d3
    .select("#benchmarkYLabel")
    .append("svg")
    .attr("width", 40)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  var svg = d3
    .select("#benchmark_graph")
    .append("svg")
    .attr("width", width)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(0," + margin.top + ")");

  var yBegin;

  var innerColumns = {
    Before: ["F(B)", "W1(B)", "W2(B)", "R(B)"],
    After: ["F(A)", "W1(A)", "W2(A)", "R(A)"]
  };

  var color = d3
    .scaleOrdinal()
    .range(resolveLegendColors())
    .domain(columnHeaders);

  data.forEach(function(d) {
    var yColumn = new Array();

    d.columnDetails = columnHeaders.map(function(name) {
      for (var ic in innerColumns) {
        if ($.inArray(name, innerColumns[ic]) >= 0) {
          if (!yColumn[ic]) {
            yColumn[ic] = 0;
          }
          yBegin = yColumn[ic];
          yColumn[ic] += +d[name];
          return {
            name: name,
            column: ic,
            yBegin: yBegin,
            yEnd: +d[name] + yBegin
          };
        }
      }
    });
    d.total = d3.max(d.columnDetails, function(d) {
      return d.yEnd;
    });
  });

  x0.domain(
    data.map(function(d) {
      return d.label;
    })
  );
  x1.domain(d3.keys(innerColumns)).rangeRound([0, x0.bandwidth()]);

  y.domain([
    0,
    d3.max(data, function(d) {
      return d.total;
    })
  ]);

  svg
    .append("g")
    .attr("class", "x axis")
    .attr("transform", "translate(0," + height + ")")
    .call(xAxis)
    .selectAll(".tick text")
    .call(wrap, x0.bandwidth());

  yAxisLegend
    .append("g")
    .attr("class", "y axis")
    .call(yAxis)
    .append("text")
    .attr("transform", "rotate(-90)")
    .attr("y", 6)
    .attr("dy", ".7em")
    .style("text-anchor", "end")
    .text("");

  var project_stackedbar = svg
    .selectAll(".project_stackedbar")
    .data(data)
    .enter()
    .append("g")
    .attr("class", "g")
    .attr("transform", function(d) {
      return "translate(" + x0(d.label) + ",0)";
    });

  project_stackedbar
    .selectAll("rect")
    .data(function(d) {
      return d.columnDetails;
    })
    .enter()
    .append("rect");

  project_stackedbar
    .selectAll("rect")
    .data(function(d) {
      return d.columnDetails;
    })
    .attr("width", x1.bandwidth())
    .attr("x", function(d) {
      return x1(d.column);
    })
    .attr("y", function(d) {
      return y(d.yEnd);
    })
    .attr("height", function(d) {
      return y(d.yBegin) - y(d.yEnd);
    })
    .style("fill", function(d) {
      return color(d.name);
    });

  project_stackedbar
    .selectAll("rect")
    .data(function(d) {
      return d.columnDetails;
    })
    .exit()
    .remove();

  project_stackedbar.on("mousemove", function(d) {
    divTooltip.style("left", d3.event.pageX + 10 + "px");
    divTooltip.style("top", d3.event.pageY - 25 + "px");
    divTooltip.style("display", "inline-block");
    var elements = document.querySelectorAll(":hover");
    var l = elements.length;
    l = l - 1;
    var elementData = elements[l].__data__;
    divTooltip.html(
      d.label +
        "<br>" +
        elementData.name.split("(")[0] +
        "(" +
        elementData.column +
        " Correction) <br> " +
        d[elements[l].__data__.name]
    );
  });

  project_stackedbar.on("mouseout", function(d) {
    divTooltip.style("display", "none");
  });

  createGraphLabels();

  function createGraphLabels() {
    var beforeLabels = columnHeaders.filter(function(elem) {
      if (elem.indexOf("(B)") !== -1) {
        return elem;
      }
    });
    var afterLabels = columnHeaders.filter(function(elem) {
      if (elem.indexOf("(A)") !== -1) {
        return elem;
      }
    });

    d3.select("#beforeLabels")
      .selectAll("p")
      .data(beforeLabels)
      .enter(function(d) {
        return d;
      })
      .append("p");

    d3.select("#beforeLabels")
      .selectAll("p")
      .data(beforeLabels)
      .text(function(d) {
        return d;
      })
      .style("background", color)
      .attr("class", "five wide column");

    d3.select("#beforeLabels")
      .selectAll("p")
      .data(beforeLabels)
      .exit()
      .remove();

    d3.select("#afterLabels")
      .selectAll("p")
      .data(afterLabels)
      .enter(function(d) {
        return d;
      })
      .append("p");

    d3.select("#afterLabels")
      .selectAll("p")
      .data(afterLabels)
      .text(function(d) {
        return d;
      })
      .style("background", color)
      .attr("class", "five wide column");

    d3.select("#afterLabels")
      .selectAll("p")
      .data(afterLabels)
      .exit()
      .remove();
  }

  var valueline = d3
    .line()
    .x(function(d) {
      return x0(d.label) + x0.bandwidth() / 2;
    })
    .y(function(d) {
      return y(d.value);
    });

  if (filter == "before" || filter == "before_after") {
    var line1 = svg
      .append("path")
      .data(average_before_data)
      .attr("d", valueline(average_before_data))
      .style("stroke-width", "2px")
      .style("fill", "none")
      .style("stroke", "#d40606");

    line1.on("mousemove", function(d) {
      getToolTipValue(d, "Before");
    });

    project_stackedbar.on("mouseout", function(d) {
      divTooltip.style("display", "none");
    });

    $(".y.axis .tick").each(function(i, e) {
      if (i === 0) {
        var line = $(this).find("line");
        $(line)
          .append("<g></g>")
          .append("<line></line>")
          .attr("x2", width)
          .css("stroke", "#bccad2")
          .css("stroke-width", "2px");
        return;
      }
    });
  }

  if (filter == "after" || filter == "before_after") {
    var line2 = svg
      .append("path")
      .data(average_after_data)
      .attr("d", valueline(average_after_data))
      .style("stroke-width", "2px")
      .style("fill", "none")
      .style("stroke", "FED567");

    line2.on("mousemove", function(d) {
      getToolTipValue(d, "After");
    });

    line2.on("mouseout", function(d) {
      divTooltip.style("display", "none");
    });
  }

  var getToolTipValue = function(d, filter) {
    divTooltip.style("left", d3.event.pageX + 10 + "px");
    divTooltip.style("top", d3.event.pageY - 25 + "px");
    divTooltip.style("display", "inline-block");
    divTooltip.html(filter + " Correction Average: <br>" + d.value.toFixed(3));
  };

  showHideDependentSelects();
};

var wrap = function(text, width) {
  text.each(function() {
    var text = d3.select(this),
      words = text
        .text()
        .split(/\s+/)
        .reverse(),
      word,
      line = [],
      lineNumber = 0,
      lineHeight = 1.1, // ems
      y = text.attr("y"),
      dy = parseFloat(text.attr("dy")),
      tspan = text
        .text(null)
        .append("tspan")
        .attr("x", 0)
        .attr("y", y)
        .attr("dy", dy + "em");
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(" "));
      if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = text
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          .attr("dy", ++lineNumber * lineHeight + dy + "em")
          .text(word);
      }
    }
  });
};

export var displayBenchmarkChart = function() {
  if ($.trim($("#selected_chart").text()) == "Table") {
    $(".benchmark_period").show();
    $("#benchmark_graph").hide();
    $("#tabular_benchmark_graph").show();
    $("#graphLabelsWrapper").hide();
    displayBenchmarkTabularChart();
  } else {
    $(".benchmark_period").hide();
    $("#tabular_benchmark_graph").hide();
    $("#benchmark_graph").show();
    $("#graphLabelsWrapper").show();
    displayBenchmarkBarChart();
  }
};

var displayTabularGraph = function(data, graph_type, filter) {
  if (
    data.benchmark_report === undefined ||
    data.benchmark_report.length === 0
  ) {
    return false;
  }

  var width = "24%";

  if (data.benchmark_report.length < 3) {
    width = (100 / (data.benchmark_report.length + 1)).toFixed() - 5 + "%";
  }

  var colspan = 3;

  if (filter != "before_after") {
    colspan = 2;
  }

  var graph_name = "Count";

  if (graph_type == "QR") {
    graph_name = "Quality Ratio";
  }

  $.each(data.benchmark_report, function(i, ele) {
    var content =
      "<table id='benchmark_table' class='benchmark-table' style='width:" +
      width +
      "'><thead><tr><th colspan=" +
      colspan +
      ">" +
      ele.label +
      "</th></tr></thead><tbody><tr class='sub-heading'><td colspan=" +
      colspan +
      ">" +
      graph_name +
      "</td></tr>";

    content =
      content +
      "<tr><td style='font-weight: bold'>" +
      data.benchmark_type +
      "</td>";

    if (filter == "before_after" || filter == "before") {
      content = content + "<td style='font-weight: bold'>Before</td>";
    }

    if (filter == "before_after" || filter == "after") {
      content = content + "<td style='font-weight: bold'>After</td>";
    }

    content = content + "</tr>";

    $.each(ele.benchmark_data, function(j, ben_data) {
      content = content + "<tr><td>" + ben_data["label"] + "</td>";

      if (graph_type == "C") {
        if (filter == "before_after" || filter == "before") {
          content = content + "<td>" + ben_data["C(B)"] + "</td>";
        }

        if (filter == "before_after" || filter == "after") {
          content = content + "<td>" + ben_data["C(A)"] + "</td>";
        }
      } else {
        if (filter == "before_after" || filter == "before") {
          content = content + "<td>" + ben_data["QR(B)"] + "</td>";
        }

        if (filter == "before_after" || filter == "after") {
          content = content + "<td>" + ben_data["QR(A)"] + "</td>";
        }
      }
      content = content + "</tr>";
    });

    var average_text = "";

    if (i == 0) {
      average_text = "Average";
    }

    content =
      content + "<tr><td style='font-weight: bold'>" + average_text + "</td>";

    if (filter == "before_after" || filter == "before") {
      content = content + "<td>" + ele.before_filter_ratio.toFixed(3) + "</td>";
    }

    if (filter == "before_after" || filter == "after") {
      content = content + "<td>" + ele.after_filter_ratio.toFixed(3) + "</td>";
    }

    content = content + "</tr></tbody></table>";

    $("#tabular_benchmark_graph").append(content);
  });

  var row_length = data.benchmark_report[0].benchmark_data.length;
  var content =
    "<table id='benchmark_table' class='benchmark-table' style='width:" +
    width +
    "'><thead><tr><th colspan=" +
    (colspan - 1) +
    ">Average</th></tr></thead><tbody><tr class='sub-heading'><td colspan=" +
    (colspan - 1) +
    ">" +
    graph_name +
    "</td></tr>";

  content = content + "<tr>";

  if (filter == "before_after" || filter == "before") {
    content = content + "<td style='font-weight: bold'>Before</td>";
  }

  if (filter == "before_after" || filter == "after") {
    content = content + "<td style='font-weight: bold'>After</td>";
  }

  content = content + "</tr>";

  for (var i = 0; i < row_length; i++) {
    if (filter == "before_after") {
      content = content + "<tr><td></td><td></td></tr>";
    } else {
      content = content + "<tr><td></td></tr>";
    }
  }

  if (filter == "before_after" || filter == "before") {
    content =
      content + "<td>" + data.average_before_filter_ratio.toFixed(3) + "</td>";
  }

  if (filter == "before_after" || filter == "after") {
    content =
      content + "<td>" + data.average_after_filter_ratio.toFixed(3) + "</td>";
  }

  content = content + "</tr></tbody></table>";

  $("#tabular_benchmark_graph").append(content);

  showHideDependentSelects();
};

var showHideDependentSelects = function() {
  $("#benchmark_type_value_select")
    .children()
    .hide();
  $(".hospital_group, .benchmark_type_value, .coder, .hospital").hide();
  $("." + $("#benchmark_type_select").val()).show();
};

var getTabularBenchmarkReport = function(params) {
  showDataLoading();
  var request = $.ajax({
    type: "GET",
    url: "/reports/load_tabular_benchmark_report.json",
    data: params,
    success: function(result) {
      displayTabularGraph(result, params.graph_type, params.filter);
      hideDataLoading();
    },
    error: function(error) {
      hideDataLoading();
      raiseBackendServiceError(error);
    }
  });

  window.pendingAjaxRequests = [request];
};
