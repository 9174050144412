import {
  toggleEpisodesView,
  getFilter,
  requestReportDataByReportName,
  checkUserRoleIs,
  raiseBackendServiceError,
} from "../common/helpers"
import {timePeriodSelectComponent} from "./components/indicator_time_period_select_component"
import {dateRangeComponent} from "./components/date_range_component"
import {
  showDataLoading,
  hideDataLoading,
} from "./components/loading_cirlce_component"
import {sendRequestToGenerateExcel} from "./services/reports-api.service"
import {dateFiledTypeSelectComponent} from "./components/date_filed_type_select_component"
import {reportsSelectsComponent} from "./components/reports_selects_component"
import {paramsCompilerService} from "./services/params-compiler.service"
import {degreeSelectComponent} from "./components/degrees_select_component"
import {selectsStorageService} from "./services/selects-storage.service"

var ACTIVE_ELEM
// TODO: Vlad -  init empty child table before the click!!!

export var compileIndicatorsParams = function () {
  var filter = selectsStorageService.pullSelectFromStorage("before_filter")
  selectsStorageService.removeSelectFromStorage("before_filter")

  if (filter === "before") {
    $("#before_filter_button").removeClass("basic")
    $("#after_filter_button").addClass("basic")
  } else {
    filter = getFilter()
  }
  var defaultSelects = {
    filter: filter,
    tag: $("#tag_select").val(),
    degree: $("#indicator_degrees_select").val(),
    icd_dis_chap: $("#disease_chapters_select").val(),
    achi_chap: $("#procedure_chapters_select").val(),
  }

  return paramsCompilerService.compileParams(
    defaultSelects,
    $("#report_type_select").val(),
    $("#date_filed_type_select").val(),
  )
}

export var getSummaryReport = function () {
  window.$SUMMARY_REPORT_TABLE.ajax.reload()
}

document.addEventListener("DOMContentLoaded", function () {
  if (!document.querySelector(".reports.episodes_by_indicator")) return

  timePeriodSelectComponent.initDropdown()
  timePeriodSelectComponent.initEventHandler()
  dateRangeComponent.initDatepicker()
  dateFiledTypeSelectComponent.initDropdown()
  dateFiledTypeSelectComponent.initEventHandler()
  reportsSelectsComponent.initReportsDependentDropdowns()
  degreeSelectComponent.initDropdown()

  var separateTable = $("#separateTableWrapper")
  $(".reports.episodes_by_indicator").append(separateTable)

  $("a#summary_report_download_excel_link").click(function (e) {
    e.preventDefault()
    var defaultSelects = {
      filter: getFilter(),
      degree: $("#indicator_degrees_select").val(),
      coder: $("#coder_select").val(),
      hospital: $("#hospital_select").val(),
      tag: $("#tag_select").val(),
    }

    var compiledParams = paramsCompilerService.compileParams(
      defaultSelects,
      $("#report_type_select").val(),
      $("#date_filed_type_select").val(),
    )

    if (compiledParams === false) {
      return
    }

    sendRequestToGenerateExcel($(this).attr("href"), compiledParams)
  })

  $("#before_filter_button").click(function () {
    $("#before_filter_button").removeClass("basic")
    $("#after_filter_button").addClass("basic")
    getSummaryReport()
  })

  $("#after_filter_button").click(function () {
    $("#after_filter_button").removeClass("basic")
    $("#before_filter_button").addClass("basic")
    getSummaryReport()
  })

  initSummaryReportTable("episodes_by_indicator")
  getSummaryReport()
})

var initSummaryReportTable = function (tableIdString) {
  var _drawCounterEcho

  if (tableIdString === undefined) return

  var $dataTable = $("#" + tableIdString).DataTable({
    dom: '<"toolbar">flrtip',
    order: [[0, "desc"]],
    columnDefs: [{orderable: false, targets: [0, 8]}],
    language: {
      searchPlaceholder: "Identifier, Degree, Description...",
    },
    paging: true,
    page: 1,
    bAutoWidth: false,
    serverSide: true,
    lengthMenu: [
      [10, 25, 50, 100],
      [10, 25, 50, 100],
    ],
    drawCallback: function () {
      showDataLoading()
    },
    deferLoading: 0,
    ajax: function (d, callback) {
      showDataLoading()
      var data = d
      _drawCounterEcho = data.draw
      data.page =
        $("#" + tableIdString)
          .DataTable()
          .page() + 1
      data.query = data.search.value
      data.direction = data.order[0]["dir"]
      data.limit = data.length

      if (window.$SUMMARY_REPORT_TABLE !== undefined) {
        data.sort = window.$SUMMARY_REPORT_TABLE.columns().header()[
          data.order[0]["column"]
        ].dataset.sortValue
      }

      delete data.draw
      delete data.columns
      delete data.order
      delete data.search
      delete data.start
      delete data.length

      data = Object.assign(data, compileIndicatorsParams())

      toggleEpisodesView("down")

      var request = $.ajax({
        url: "/reports/load_indicators.json",
        type: "GET",
        data: data,
        success: function (data) {
          hideDataLoading()
          callback({
            draw: _drawCounterEcho,
            data: data.data.indicators,
            recordsTotal: data.recordsTotal,
            recordsFiltered: data.recordsTotal,
          })

          $("#episodes_by_indicator").find("tfoot").remove()
          if (
            data.data.total.numerator === 0 &&
            data.data.total.denominator === 0
          )
            return
          $("#episodes_by_indicator").append(
            "<tfoot> " +
              "<tr class='subtotals'>" +
              "<td class='subtotals' </td>" +
              "<td class='subtotals' </td>" +
              "<td class='subtotals' </td>" +
              "<td class='subtotals' </td>" +
              "<td class='subtotals' </td>" +
              "<td class='subtotals font-weight-600' >" +
              data.data.total.numerator +
              "</td>" +
              "<td class='subtotals font-weight-600' >" +
              data.data.total.denominator +
              "</td>" +
              "<td class='subtotals font-weight-600' >" +
              data.data.total.ratio +
              "</td>" +
              "<td class='subtotals'></td>" +
              "</tr>" +
              "</tfoot>",
          )
        },
        error: function (error) {
          hideDataLoading()
          raiseBackendServiceError(error)
        },
      })

      window.pendingAjaxRequests = [request]

      return request
    },
    columns: [
      {data: "identifier"},
      {data: "identifier"},
      {data: "degree"},
      {data: "icd_dis_chap"},
      {data: "description"},
      {data: "num_count"},
      {data: "den_count"},
      {data: "ratio"},
      {
        data: null,
        defaultContent:
          "<button class='ui basic red button hidden-for-user-role-7 hidden-for-user-role-8'>Show episodes</button>",
      },
    ],
    createdRow: function (row, data) {
      $("td", row)
        .eq(0)
        .attr("data-row-id", data["identifier"])
        .css("color", "transparent")
        .attr("data-error-type", data["degree"])
      $("td", row)
        .eq(4)
        .attr("data-row-id", data["identifier"])
        .addClass("table-description")
    },
  })

  $dataTable.on("draw", function () {
    hideDataLoading()
  })

  if ($("#table_actions") !== undefined) {
    $("div.toolbar").html($("#table_actions"))
  }

  $("#" + tableIdString + " tbody").on("click", "button", function (e) {
    var data = $dataTable.row($(this).parents("tr"))

    var paramsForRequest = {
      filter: getFilter(),
      event: e,
      tableSelector: "table#episodes_by_indicator",
      id: $(data.selector.rows[0]).find("[data-row-id]").data("rowId"),
      degree: $(data.selector.rows[0])
        .find("[data-error-type]")
        .data("errorType"),
    }

    window.$SUMMARY_REPORT_TABLE.childTable = initSummaryChildTable(
      "episodesList",
      paramsForRequest,
    )
  })

  window.$SUMMARY_REPORT_TABLE = $dataTable
}

function initSummaryChildTable(tableIdString, params) {
  if (tableIdString === undefined) return

  var degreesToReview

  var $dataTable = $("#" + tableIdString).DataTable({
    dom: '<"toolbar">rtip',
    destroy: true,
    paging: false,
    info: false,
    columnDefs: [
      {orderable: false, targets: [-1]},
      {type: "date-euro", targets: [3]},
      {type: "custom", targets: [1, 2]},
      {
        targets: -1,
        data: null,
        render: function (data) {
          return (
            '<td> <a data-position="top right" ' +
            ' href="#" data-url="/episodes/review_episode?episode_id=' +
            data.id +
            "&indicator_category_id=" +
            window.gon.indicator_category_id +
            "&degrees=" +
            degreesToReview +
            '" ' +
            ' class="review-episode" onclick="requestReview(event)"><span class="ui basic red button">Review</span></a> </td>'
          )
        },
      },
    ],
    columns: [
      {data: "episode_details.hospital"},
      {data: "pii_data.mrn"},
      {data: "pii_data.ueid"},
      {data: "pii_data.dos"},
      {data: "episode_details.sex"},
      {data: "episode_details.drg"},
      {data: "episode_details.d01"},
      {data: "episode_details.p01"},
      {data: "episode_details.coder_id"},
      {data: null},
    ],
    ajax: function (data, callback) {
      showDataLoading()

      if (checkUserRoleIs("standard_summary_only") || checkUserRoleIs("sales"))
        return false

      if ($(event.target)[0].localName === "button") {
        var targetElementParentTable =
          event.target.parentElement.parentElement.parentElement
      }

      if (ACTIVE_ELEM !== undefined) {
        $(ACTIVE_ELEM).removeClass("active")
      }
      ACTIVE_ELEM = $("[data-row-id=" + params.id + "]").parent()
      ACTIVE_ELEM.addClass("active")
      toggleEpisodesView("up")

      var defaultSelects = {
        coder: $("#coder_select").val(),
        hospital: $("#hospital_select").val(),
        degree: $("#indicator_degrees_select").val(),
        tag: $("#tag_select").val(),
      }

      if (params.filter !== undefined) {
        defaultSelects.filter = params.filter
      }

      var compiledParams = paramsCompilerService.compileParams(
        defaultSelects,
        $("#report_type_select").val(),
        $("#date_filed_type_select").val(),
      )

      if (compiledParams === false) {
        return
      }

      compiledParams = Object.assign(compiledParams, data)
      compiledParams.indicator_identifier = params.id
      compiledParams.indicator_degree = params.degree

      $.ajax({
        url: "/reports/list_episodes_for_indicator.json",
        type: "GET",
        data: compiledParams,
        success: function (data) {
          degreesToReview =
            data.data.indicator.degree == "R" ? ["R"] : ["F", "W1", "W2"]
          callback({
            data: data.data.episodes,
          })
        },
        error: function (error) {
          hideDataLoading()
          raiseBackendServiceError(error)
        },
      })
    },
  })

  $dataTable.on("draw", function () {
    hideDataLoading()
  })

  return $dataTable
}
