import React, {useState, useEffect} from "react";
import { Select } from "semantic-ui-react";
import FieldMap from "./field-map";

const Form = ({
  sourceColumns,
  fieldMaps,
  picqFieldsforSelect,
  uploadedMapCheck,
  submitFunction = () => {},
  createFunction = async () => {},
  updateFunction = async () => {},
  destroyFunction = async () => {},
  validateFunction = async() => {}
}) => {
  const selectOptions = picqFieldsforSelect.map(picqField => ({
    key: picqField.id,
    value: picqField.id,
    text: `${picqField.field_name} (${picqField.data_type}${
      picqField.mandatory ? " , Mandatory" : ""
    })`
  }));

  const userPicqField = picqFieldsforSelect.find(
    picqField => picqField.field_name === "USER"
  );

  const wardPicqField = picqFieldsforSelect.find(
    picqField => picqField.field_name === "WARD"
  );

  const fundPicqField = picqFieldsforSelect.find(
    picqField => picqField.field_name === "FUND"
  );

  const specialtyPicqField = picqFieldsforSelect.find(
    picqField => picqField.field_name === "SPECIALTY"
  );
  const [state, setState] = useState(fieldMaps);

  useEffect(() => {
    setState(fieldMaps);
  }, [fieldMaps]);

  const appendFunction = async () => {
    const res = await createFunction();
    fieldMaps.push({
      id: res.message.id,
      picq_field_id: res.message.picq_field_id,
      source_column_name: res.message.source_column_name
    });
    setState([...fieldMaps]);
  };

  const deleteFieldMap = async (id) => {
    const res = await destroyFunction(id);
    if (res.message !== "Success") return;
    const fieldIndex = fieldMaps.findIndex(f => f.id == id);
    fieldMaps.splice(fieldIndex, 1);
    setState([...fieldMaps]);
  }

  const updateFieldMap = async (fieldMapId, picq_field_id, sourceColumnName, kind) => {
    const res = await updateFunction(fieldMapId, picq_field_id, sourceColumnName, kind);
    if (res.message !== "Success") return false;
    const fieldIndex = fieldMaps.findIndex(f => f.id == fieldMapId);
    fieldMaps[fieldIndex].picq_field_id = picq_field_id;
    fieldMaps[fieldIndex].source_column_name = sourceColumnName;
    fieldMaps[fieldIndex].kind = kind;
    setState([...fieldMaps]);
    return true;
  }

  const renderSubmitButton = () => {
    if (uploadedMapCheck === true) {
      return <input
                onClick={submitFunction}
                value="Update Mapping"
                type="button"
                role="button"
                id="mapping_submit"
                className="ui green button"
              />;
    }
    return <input
            onClick={appendFunction}
            value="Add New Field"
            type="button"
            role="button"
            id="mapping_append"
            className="ui green button"
          />;
  }

  const renderValidateButton = () => {
    if (uploadedMapCheck === true) return
    return <input
            onClick={validateFunction}
            value="Validate Mapping"
            type="button"
            role="button"
            id="validate_check"
            className="ui green button"
          />
  }

  return (
    <form className="ui form" id="mapping_form" method="post">
      {sourceColumns.map(sourceColumn => (
        <input
          key={sourceColumn}
          multiple="multiple"
          value={sourceColumn}
          type="hidden"
          name="mapping[source_columns][]"
        />
      ))}
      <div className="field-maps" id="field_maps">
        {state.map(fieldMap => (
          <FieldMap
            fieldMapId={fieldMap.id}
            picqFieldId={fieldMap.picq_field_id}
            key={fieldMap.id}
            selectOptions={selectOptions}
            sourceColumnName={fieldMap.source_column_name}
            userPicqField={userPicqField}
            wardPicqField={wardPicqField}
            fundPicqField={fundPicqField}
            specialtyPicqField={specialtyPicqField}
            deleteFunction={deleteFieldMap}
            updateFunction={updateFieldMap}
            uploadedMapCheck={uploadedMapCheck}
            fieldMapKind={fieldMap.kind}
          />
        ))}
      </div>
      <div className="submit">
          {renderValidateButton()}
      </div>
      <div className="submit">
        {renderSubmitButton()}
      </div>
    </form>
  );
};

export default Form;
