import React from "react";

const Container = ({ children }) => (
  <div className="mapping-show">
    <h1 className="header">
      <span className="main-path">Uploads//</span>
      <span className="sub-path">Mapping</span>
    </h1>
    <div className="ui divider" />
    <div className="mapping-howto" id="mapping_howto">
      <p>
        To map a field in your source file to the correct PICQ field, select
        from the drop-down menu next to each field. For fields which you do not
        want to map, leave the selection blank.
      </p>
      <p>
        Once you have set up a mapping for this file, any other files you upload
        which have the same column names in the same order can use this mapping.
      </p>
      <br />
      <div className="actions">
        <div className="submit">
          <a href="#" className="cancel-mapping ui negative button">
            Cancel
          </a>
          &nbsp;&nbsp;
          <input
            type="button"
            value="Auto-Detect"
            id="auto_detect"
            className="ui positive button"
          />
          &nbsp;&nbsp;
        </div>
        <br />
      </div>
    </div>
    {children}
    <div className="clear-float" />
  </div>
);

export default Container;
