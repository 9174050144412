// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Default Rails stuff
require("channels")
require("@rails/ujs").start()
require("@rails/activestorage").start()
// require("turbolinks").start()

// Our stuff
require("jquery-ui")
require("datatables.net-se")
require("datatables.net-fixedcolumns-se")
require("es6-object-assign").polyfill()
require("legacy")

global.$ = require("jquery")
global.toastr = require("toastr")
global.Papa = require("papaparse")
global.d3 = require("d3")

import "core-js/stable"
import "regenerator-runtime/runtime"
import {
  toggleAll,
  clearSelectAll,
  handleDelete,
  requestReview,
  openAcsDescriptionTab,
  requestReportDataByReportName,
  abortPendingAjaxRequests,
} from "../legacy/common/helpers"
import {scrollToBottom} from "../legacy/common/scroll_to_bottom_component"
import authenticationService from "../legacy/common/services/authentication.service"
import {requestGuideDownload} from "../legacy/download-guide-file"
import {handleDeleteUploads} from "../legacy/uploads"
import {toggleEditProfileModal, toggleProfileModal} from "../legacy/profiles"
import {resetPasswordForAllUsersOfOrganisation} from "../legacy/users"
import {handleDeleteEclEditions} from "../legacy/ecl_editions"
import {handleDeleteEcl} from "../legacy/ecls"

window.toggleAll = toggleAll
window.clearSelectAll = clearSelectAll
window.scrollToBottom = scrollToBottom
window.authenticationService = authenticationService
window.requestGuideDownload = requestGuideDownload
window.handleDelete = handleDelete
window.handleDeleteUploads = handleDeleteUploads
window.toggleEditProfileModal = toggleEditProfileModal
window.toggleProfileModal = toggleProfileModal
window.resetPasswordForAllUsersOfOrganisation =
  resetPasswordForAllUsersOfOrganisation
window.handleDeleteEclEditions = handleDeleteEclEditions
window.handleDeleteEcl = handleDeleteEcl
window.requestReview = requestReview
window.openAcsDescriptionTab = openAcsDescriptionTab
window.requestReportDataByReportName = requestReportDataByReportName
window.abortPendingAjaxRequests = abortPendingAjaxRequests
