import authenticationService from "../../common/services/authentication.service"
import {selectsStorageService} from "./selects-storage.service"

export var optionsManagerService = (function () {
  var selectsStateStorage = {
    report_type_select: {
      oldValue: "",
      newValue: "",
      optionIsChanged: false,
    },
    coder_select: {
      oldValue: "",
      newValue: "",
      optionIsChanged: false,
    },
    indicator_degrees_select: {
      oldValue: [],
      newValue: [],
      optionIsChanged: false,
    },
    risq_indicator_degrees_select: {
      oldValue: [],
      newValue: [],
      optionIsChanged: false,
    },
    risq_hac_categories_select: {
      oldValue: [],
      newValue: [],
      optionIsChanged: false,
    },
    risq_hac_indicators_select: {
      oldValue: [],
      newValue: [],
      optionIsChanged: false,
    },
    clinician_select: {
      oldValue: "",
      newValue: "",
      optionIsChanged: false,
    },
    hospital_select: {
      oldValue: "",
      newValue: "",
      optionIsChanged: false,
    },
    hospital_group_select: {
      oldValue: "",
      newValue: "",
      optionIsChanged: false,
    },
    hospitals_select: {
      oldValue: "",
      newValue: "",
      optionIsChanged: false,
    },
    hospital_groups_select: {
      oldValue: "",
      newValue: "",
      optionIsChanged: false,
    },
    tag_select: {
      oldValue: "",
      newValue: "",
      optionIsChanged: false,
    },
    disease_chapters_select: {
      oldValue: "",
      newValue: "",
      optionIsChanged: false,
    },
    procedure_chapters_select: {
      oldValue: "",
      newValue: "",
      optionIsChanged: false,
    },
    datepickerSelectFrom: {
      oldValue: "",
      newValue: "",
      optionIsChanged: false,
    },
    datepickerSelectTo: {
      oldValue: "",
      newValue: "",
      optionIsChanged: false,
    },
    date_filed_type_select: {
      oldValue: "",
      newValue: "",
      optionIsChanged: false,
    },
    indicator_time_period_select: {
      oldValue: "",
      newValue: "",
      optionIsChanged: false,
    },
    fund_select: {
      oldValue: "",
      newValue: "",
      optionIsChanged: false,
    },
    care_type_select: {
      oldValue: "",
      newValue: "",
      optionIsChanged: false,
    },
    los_select: {
      oldValue: "",
      newValue: "",
      optionIsChanged: false,
    },
    min_max_type_select: {
      oldValue: "",
      newValue: "",
      optionIsChanged: false,
    },
    indicator_set_select: {
      oldValue: "",
      newValue: "",
      optionIsChanged: false,
    },
    indicator_select: {
      oldValue: [],
      newValue: [],
      optionIsChanged: false,
    },
  }

  var processSavingChangedOption = function (
    optionNameString,
    optionValueString,
  ) {
    if (authenticationService.checkIsUserImpersonated()) return false

    var item = {
      key: optionNameString,
      value: optionValueString,
    }

    selectsStorageService.pushSelectToStorage(item)
  }

  var setActiveSavedOption = function (
    selectmenuIdString,
    optionNameString,
    defaultOptionValString,
  ) {
    if (authenticationService.checkIsUserImpersonated()) return false

    var savedItemValue =
      selectsStorageService.pullSelectFromStorage(optionNameString)

    if (
      savedItemValue !== null &&
      savedItemValue !== undefined &&
      savedItemValue !== false
    ) {
      // NOTE: degree select accepts only array of strings but not a string with values splited by comma
      var arrayOfValues = savedItemValue.split(",")

      $("#" + selectmenuIdString).val(arrayOfValues)
      $("#" + selectmenuIdString).dropdown("set selected", arrayOfValues)
    } else {
      $("#" + selectmenuIdString).val(defaultOptionValString)
      $("#" + selectmenuIdString).dropdown(
        "set selected",
        defaultOptionValString,
      )
    }
  }

  var checkOptionIsChanged = function (targetId) {
    return selectsStateStorage[targetId].optionIsChanged
  }

  return {
    selectsStateStorage: selectsStateStorage,
    setActiveSavedOption: setActiveSavedOption,
    checkOptionIsChanged: checkOptionIsChanged,
    processSavingChangedOption: processSavingChangedOption,
  }
})()
