import authenticationService from "../../common/services/authentication.service";
import { optionsManagerService } from "../services/options-manager.service";
import { getURLParameter } from "../../common/helpers";
import { updateRisqIndicatorList } from "../helpers"

export var degreeSelectComponent = (function() {
  var initDropdown = function() {
    var selectId = "indicator_degrees_select";
    var indicatorDegreesSelect = $("#" + selectId);
    var valuesFromUrl = getURLParameter("degrees_as_string");
    var defaultArrayOfValues = ["F", "W1"];

    if (authenticationService.checkIsUserImpersonated() && !valuesFromUrl) {
      indicatorDegreesSelect.val(defaultArrayOfValues);
      indicatorDegreesSelect.dropdown("set selected", defaultArrayOfValues);
    }

    if (valuesFromUrl) {
      var arrayOfValuesFromUrl = valuesFromUrl.split(",");
      indicatorDegreesSelect.val(arrayOfValuesFromUrl);
      indicatorDegreesSelect.dropdown("set selected", arrayOfValuesFromUrl);
      optionsManagerService.processSavingChangedOption(
        selectId,
        arrayOfValuesFromUrl
      );
    }else {
      optionsManagerService.setActiveSavedOption(
        selectId,
        selectId,
        defaultArrayOfValues
      );
    }

    indicatorDegreesSelect.on("focusin", function(e) {
      var targetId = e.currentTarget.id;
      optionsManagerService.selectsStateStorage[targetId].oldValue =
        e.currentTarget.value;
    });

    indicatorDegreesSelect.on("change", function(e) {
      var targetId = e.currentTarget.id;
      var selectedDegrees = [];
      if (
        optionsManagerService.selectsStateStorage[targetId].oldValue !==
        selectedDegrees
      ) {
        for (var option of e.currentTarget.options) {
          if (option.selected) {
            selectedDegrees.push(option.value);
          }
        }

        optionsManagerService.selectsStateStorage[
          targetId
        ].newValue = selectedDegrees;
        optionsManagerService.selectsStateStorage[
          targetId
        ].optionIsChanged = true;
      }

      if (optionsManagerService.checkOptionIsChanged(targetId)) {
        optionsManagerService.processSavingChangedOption(
          targetId,
          optionsManagerService.selectsStateStorage[targetId].newValue
        );
      }
    });
  };

  var initRisqDropdown = function() {
    var selectId = "risq_indicator_degrees_select";
    var indicatorDegreesSelect = $("#" + selectId);
    var valuesFromUrl = getURLParameter("degrees_as_string");
    var defaultArrayOfValues = ["HAC"];

    if (authenticationService.checkIsUserImpersonated() && !valuesFromUrl) {
      indicatorDegreesSelect.val(defaultArrayOfValues);
      indicatorDegreesSelect.dropdown("set selected", defaultArrayOfValues);
    }

    if (valuesFromUrl) {
      var arrayOfValuesFromUrl = valuesFromUrl.split(",");
      indicatorDegreesSelect.val(arrayOfValuesFromUrl);
      indicatorDegreesSelect.dropdown("set selected", arrayOfValuesFromUrl);
      optionsManagerService.processSavingChangedOption(
        selectId,
        arrayOfValuesFromUrl
      );
    }else {
      optionsManagerService.setActiveSavedOption(
        selectId,
        selectId,
        defaultArrayOfValues
      );
    }

    indicatorDegreesSelect.on("focusin", function(e) {
      var targetId = e.currentTarget.id;
      optionsManagerService.selectsStateStorage[targetId].oldValue =
        e.currentTarget.value;
    });

    indicatorDegreesSelect.on("change", function(e) {
      var targetId = e.currentTarget.id;
      var selectedDegrees = [];
      if (
        optionsManagerService.selectsStateStorage[targetId].oldValue !==
        selectedDegrees
      ) {
        for (var option of e.currentTarget.options) {
          if (option.selected) {
            selectedDegrees.push(option.value);
          }
        }
        updateRisqIndicatorList()
        optionsManagerService.selectsStateStorage[
          targetId
        ].newValue = selectedDegrees;
        optionsManagerService.selectsStateStorage[
          targetId
        ].optionIsChanged = true;
      }

      if (optionsManagerService.checkOptionIsChanged(targetId)) {
        optionsManagerService.processSavingChangedOption(
          targetId,
          optionsManagerService.selectsStateStorage[targetId].newValue
        );
      }
    });
  };

  return {
    initDropdown: initDropdown,
    initRisqDropdown: initRisqDropdown
  };
})();
