import {initDataTable, getURLParameter} from "../common/helpers"
import {timePeriodSelectComponent} from "./components/indicator_time_period_select_component"
import {dateRangeComponent} from "./components/date_range_component"
import {dateFiledTypeSelectComponent} from "./components/date_filed_type_select_component"
import {reportsSelectsComponent} from "./components/reports_selects_component"
import {selectsStorageService} from "./services/selects-storage.service"
import {
  initDashboardDropdowns,
  initDashboardSelectsHandlers,
  getDashboardReports,
} from "./helpers"

document.addEventListener("DOMContentLoaded", function () {
  if (
    !(
      document.querySelector(".reports.index") ||
      document.querySelector(".reports.risq_dashboard")
    )
  )
    return
  timePeriodSelectComponent.initDropdown()
  timePeriodSelectComponent.initEventHandler()
  dateRangeComponent.initDatepicker()
  dateFiledTypeSelectComponent.initDropdown()
  dateFiledTypeSelectComponent.initEventHandler()
  reportsSelectsComponent.initReportsDependentDropdowns()

  initDashboardDropdowns()
  initDashboardSelectsHandlers()

  if (getURLParameter("tag_id")) {
    $("#indicator_time_period_select").dropdown("set selected", "By Extract")
    $("#tag_select").dropdown(
      "set selected",
      parseInt(getURLParameter("tag_id")),
    )
    $("#tag_select_dropdown").show()
  }

  if (getURLParameter("period")) {
    $("#indicator_time_period_select").dropdown(
      "set selected",
      parseInt(getURLParameter("period")),
    )
  }

  getDashboardReports("after", "display")

  $('div[data-component="dashboard-action"]').on("click", function (e) {
    e.preventDefault()

    var storageKey = document.querySelector(".reports.risq_dashboard")
      ? "risq_indicator_degrees_select"
      : "indicator_degrees_select"
    var degreeValue = $(this).children(".chart-name").text()

    var item = {
      key: storageKey,
      value: degreeValue,
    }

    selectsStorageService.pushSelectToStorage(item)
    var hideReviewed = $("#before_filter_button").hasClass("basic")

    window.location.href = document.querySelector(".reports.risq_dashboard")
      ? `/reports/risq_indicators_by_episode?hide_reviewed=${hideReviewed}`
      : `/reports/indicators_by_episode?hide_reviewed=${hideReviewed}`
  })
})
