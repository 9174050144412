import { initDataTable } from "./common/helpers";

var reverseCheckboxes = function() {
  $('[data-class="notification_recipients_checkbox"]').change(function(event) {
    $(
      '[data-destroy-id="' + $(event.currentTarget).attr("data-id") + '"]'
    ).prop("value", !$(event.currentTarget).prop("checked"));
  });
};

var fetchRecipients = function(organisationId) {
  $.ajax(
    "/notification_recipient_lists/new.js?organisation_id=" + organisationId,
    {
      success: function() {
        reverseCheckboxes();
      }
    }
  );
};

var toggleDegreesCheckBoxes = function() {
  if (
    $("#notification_recipient_list_notification_type").val() === "per_extract"
  ) {
    $("#perExtractDegreesCheckboxes").show();
    $("[data-checkbox=per_extract]").attr("disabled", null);
  } else {
    $("#perExtractDegreesCheckboxes").hide();
    $("[data-checkbox=per_extract]").attr("disabled", "disabled");
  }
};

$(".notification_recipient_lists.index").ready(function() {
  initDataTable({
    tableId: "#recipient_lists_table",
    orderVal: 3,
    targetsVal: [0, 4]
  });
  $("input.action_button").each(function(ele) {
    $(this).click(function(evt) {
      document.forms[0].action =
        "/automation/" + $(this).attr("data") + "/actions";
      document.forms[0].submit();
    });
  });
});

$(
  ".notification_recipient_lists.edit, .notification_recipient_lists.update"
).ready(function() {
  $("#notification_recipient_list_organisation_id").dropdown();
  $("#notification_recipient_list_notification_type").dropdown();
  $("#notification_recipient_list_organisation_id").change(function(event) {
    fetchRecipients($(event.currentTarget).val());
  });
  reverseCheckboxes();

  var organisationId = $("#notification_recipient_list_organisation_id").val();

  if (organisationId !== undefined) {
    fetchRecipients(organisationId);
  }

  toggleDegreesCheckBoxes();
  $("#notification_recipient_list_notification_type").change(function() {
    toggleDegreesCheckBoxes();
  });
});
