var SemanticUiFixes = function() {
  function initMultipleSelectHandler() {
    var selectsCollection = $(".selection.multiple");

    if (selectsCollection.length === 0) return;

    $.each(selectsCollection, function(index, element) {
      var select = $(element).find("select")[0];
      var options = select && select.selectedOptions;
      var closeIcons = $($(element).find("i"));

      if (options && options.length === 1) {
        $(closeIcons).addClass("display-none");
      }
    });

    $(".selection.multiple select").change(function(e) {
      if (e.target.selectedOptions.length === 1) {
        $(e.target)
          .parent()
          .find("i")
          .addClass("display-none");
      }

      if (e.target.selectedOptions.length > 1) {
        $(e.target)
          .parent()
          .find("i")
          .removeClass("display-none");
      }
    });
  }

  return {
    initMultipleSelectHandler: initMultipleSelectHandler
  };
};

export default SemanticUiFixes;
