import React from "react"
import ReactDOM from "react-dom"
import UserMapping from "./components/user-mapping"
import {initBtnDown} from "./common/scroll_to_bottom_component"
import {raiseBackendServiceError} from "./common/helpers"

const Mappings = function () {
  const _scope = this
  let _mappingId
  let _onMappingComplete // A callback function that passed here to be triggered when mapping completed
  let _sourceColumns // List of columns from the supplied CSV file
  let _picqFieldsForSelect // List of PICQ fields that supposed to be shown to the user

  this.init = options => {
    _sourceColumns = _sourceColumns || options.sourceColumns
    _onMappingComplete = _onMappingComplete || options.onMappingComplete
    $(".cancel-mapping").off().on("click", _scope.cancelClick)
    $("#auto_detect").off().on("click", _scope.autodetectClick)
    $("#mapping_submit").off().on("click", _scope.acceptMappingClick)
  }

  this.cancelClick = event => {
    event.preventDefault()
    window.location.href = "/uploads/new"
  }

  this.autodetectClick = event => {
    event.preventDefault()
    $("#auto_detect").val("Please wait...").prop("disabled", true)
    $.ajax({
      url: `/mappings/${_mappingId}/auto_detect`,
      method: "POST",
      data: {mapping: {source_columns: _sourceColumns}},
      success: ({mapping}) => {
        _scope.renderFieldMaps(
          _sourceColumns,
          mapping.field_maps,
          _picqFieldsForSelect,
          // Callback function after renderFieldMaps completed
          () => {
            _scope.init()
            $("#auto_detect").prop("disabled", false)
          },
        )
      },
      error: error => {
        raiseBackendServiceError(error)
        $("#auto_detect").val("Auto-Detect").prop("disabled", false)
      },
    })
  }

  this.acceptMappingClick = event => {
    event.preventDefault()
    $("#mapping_submit").val("Please wait...").prop("disabled", true)
    $.ajax({
      url: `/mappings/${_mappingId}.json`,
      method: "PUT",
      data: $("#mapping_form").serialize(),
      success: ({mapping}) => {
        _onMappingComplete(mapping)
      },
      error: error => {
        raiseBackendServiceError(error)

        $("#mapping_submit").val("Accept Mapping").prop("disabled", false)
      },
    })
  }

  this.renderFieldMaps = (
    sourceColumns,
    fieldMaps,
    picqFieldsforSelect,
    callback,
  ) => {
    ReactDOM.render(
      <UserMapping
        sourceColumns={sourceColumns}
        fieldMaps={fieldMaps}
        picqFieldsforSelect={picqFieldsforSelect}
        uploadedMapCheck={true}
      />,
      document.getElementById("mapping_panel"),
      callback,
    )
  }

  this.getMapping = (csvFile, displayFunction, updateFunction) => {
    csvFile.getColumnHeaders().then(
      columnHeaders => {
        _sourceColumns = columnHeaders

        $.ajax({
          url: "/mappings",
          method: "POST",
          data: {mapping: {source_columns: columnHeaders}},
          success: ({mapping}) => {
            // Store mapping id, so it can be used for Auto-Detect or Update requests
            _mappingId = mapping.id

            // Store PICQ Fields for select, so it does not change after Auto-Detect click
            _picqFieldsForSelect = mapping.picq_fields_for_select

            const fieldMaps = mapping.field_maps

            _scope.renderFieldMaps(
              _sourceColumns,
              fieldMaps,
              _picqFieldsForSelect,
              // Callback function after renderFieldMaps completed
              () => {
                $("#select_file_panel").hide()
                $("#mapping_panel").show()
                _scope.init({onMappingComplete: updateFunction})
                initBtnDown()
              },
            )
          },
          error: error => {
            raiseBackendServiceError(error)
            $("#csv_file_submit").val("Map Fields").prop("disabled", false)
          },
        })
      },
      error => {
        console.log("Error retrieving column headers:", error)
      },
    )
  }
}
export default Mappings
