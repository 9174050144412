export var showDataLoading = function() {
  var requestReportButton = document.getElementById("requestReportButton");
  var abortReportButton = document.getElementById("abortReportButton");
  var spinnerBlock = document.getElementById("spinnerBlock");
  var infoBlock = document.getElementById("infoBlock");

  requestReportButton.style.display = "none";
  abortReportButton.style.display = "block";
};

export var hideDataLoading = function() {
  var requestReportButton = document.getElementById("requestReportButton");
  var abortReportButton = document.getElementById("abortReportButton");
  var spinnerBlock = document.getElementById("spinnerBlock");
  var infoBlock = document.getElementById("infoBlock");

  requestReportButton.style.display = "block";
  abortReportButton.style.display = "none";
};

export var showFullScreenSpinner = function() {
  $(".pusher").css("pointer-events", "none");
  $("[data-loading]").show();
  $("[data-preparing]").hide();
  $("#progressBlockWrapper").show();
};

export var hideFullScreenSpinner = function() {
  $("#progressBlockWrapper").hide();
  $("[data-loading]").hide();
  $("[data-preparing]").hide();
  $(".pusher").css("pointer-events", "auto");
};

export var calculateAndSetSizeElementById = function(elementIdString) {
  if (elementIdString == undefined) {
    return false;
  }

  var $element = $("#" + elementIdString);

  if ($element.length > 0) {
    $element.height($element.outerHeight() - $element.offset().top);
    $element.width($element.outerWidth() - $element.offset().left);
  }
};
