import {
  compileEpisodeIndicatorsParams,
  getFilterByCheckbox,
  raiseBackendServiceError,
  restyleCheckboxes,
} from "../common/helpers"
import {timePeriodSelectComponent} from "./components/indicator_time_period_select_component"
import {dateRangeComponent} from "./components/date_range_component"
import {
  showDataLoading,
  hideDataLoading,
} from "./components/loading_cirlce_component"
import {sendRequestToGenerateExcel} from "./services/reports-api.service"
import {dateFiledTypeSelectComponent} from "./components/date_filed_type_select_component"
import {reportsSelectsComponent} from "./components/reports_selects_component"
import {paramsCompilerService} from "./services/params-compiler.service"
import {degreeSelectComponent} from "./components/degrees_select_component"
import {
  addReviewEpisodeListeners,
  getIndicatorDegreesSelectValue,
  removeReviewEpisodeListeners,
  updateRisqIndicatorList,
} from "./helpers"

var $dataTable
var selected_org = null

export var getNumeratorReport = function () {
  showDataLoading()
  var request = $.ajax({
    type: "GET",
    contentType: "application/json",
    url: "/reports/load_episode_indicators.json",
    data: compileEpisodeIndicatorsParams(),
    success: function (result) {
      updateIndicatorsByEpisode(result)
    },
    error: function (error) {
      hideDataLoading()
      raiseBackendServiceError(error)
    },
  })
  window.pendingAjaxRequests = [request]

  return request
}

var indicatorCategoryId = function () {
  try {
    return parseInt(window.gon.indicator_category_id)
  } catch (e) {}
  return -1
}

var initNumeratorReportTable = function (tableId) {
  var num_user_filters = window.gon.user_defined_filters.length || 0
  var default_number_columns = 13
  var non_fixed_columns = default_number_columns + num_user_filters
  var no_order_columns = [0, 1, 2, non_fixed_columns, non_fixed_columns + 1]
  var columnsParams = new Array(default_number_columns + num_user_filters)
  columnsParams.push(
    {
      data: null,
      defaultContent:
        "<div>" +
        '<button id="showIndicators" class="ui basic red button hidden-for-user-role-7">' +
        "Indicators" +
        "</button>" +
        '<button id="hideIndicators" onclick="hideIndicatorsList(event)" style="display:none;" class="ui basic red button">' +
        "Indicators" +
        "</button>" +
        "</div>",
    },
    {
      data: null,
      defaultContent:
        '<a href="#" data-class="reviewEpisodeLink">' +
        '<button id="reviewEpisode" class="ui basic red button hidden-for-user-role-7">' +
        "Review" +
        "</button>" +
        "</a>",
    },
  )

  var $dataTable = $("#" + tableId).DataTable({
    scrollX: true,
    fixedColumns: {
      left: 0,
      right: 2,
    },
    order: [[4, "asc"]],
    columns: columnsParams,
    columnDefs: [
      {orderable: false, targets: no_order_columns},
      {width: "0em", targets: [0]},
      {type: "date-euro", targets: [6, 12]},
      {visible: false, targets: [2]},
      {render: null, targets: [1]}
    ],
    createdRow: function (row, data) {
      $("td", row)
        .eq(0)
        .attr("data-row-id", data[0])
        .css("color", "transparent")
        .text("")
      $("td", row).last().find("a").attr("data-episode-id", data[0])
      //.attr("href", "/episodes/review_episode?indicator_category_id=" + indicatorCategoryId() + "&episode_id=" + data[0] + " "); //&degrees=" + degrees_to_review + " "
      // Hide CODERID for role coder that is 11th in a row
      $("td", row).eq(1).addClass("hidden-for-user-role-5")
      $("td", row).eq(10).addClass("hidden-for-user-role-5")
      if (document.querySelector(".reports.risq_indicators_by_episode"))
        $("td", row).eq(9).addClass("hidden-for-user-role-5")
    },
    dom: '<"toolbar">flrtip',
    language: {
      search: "",
      searchPlaceholder: "Search...",
      sSearch: '<i class="search icon"></i>',
    },
    drawCallback: function () {
      restyleCheckboxes()
      showDataLoading()
      removeReviewEpisodeListeners()
    },
  })

  $dataTable.on("draw", function () {
    hideDataLoading()
    addReviewEpisodeListeners()
    resetCheckboxes()
    document.getElementById("reassign_coder_base").style.display = "none"
  })

  $("#" + tableId + " tbody").on("click", "button", function (e) {
    var data = $dataTable.row($(this).parents("tr"))
    var id = $(data.selector.rows[0]).find("[data-row-id]").data("rowId")
    if (e.currentTarget.id === "showIndicators") {
      $(e.target).addClass("loading")
      $.ajax({
        url: "/reports/list_indicators_for_episode.json",
        type: "GET",
        data: paramsCompilerService.compileParams(
          {
            coder: $("#coder_select").val(),
            hospital: $("#hospital_select").val(),
            degree: getIndicatorDegreesSelectValue(),
            tag: $("#tag_select").val(),
            episode: id,
            indicator_category_id: window.gon.indicator_category_id,
          },
          $("#report_type_select").val(),
          $("#date_filed_type_select").val(),
        ),
        success: function (response) {
          $(e.target).removeClass("loading")
          updateIndicatorsList(response.data.hits, e)
        },
        error: function (error) {
          $(e.target).removeClass("loading")
          raiseBackendServiceError(error)
        },
      })
    }
  })

  return $dataTable
}

document.addEventListener("DOMContentLoaded", function () {
  if (
    !(
      document.querySelector(".reports.indicators_by_episode") ||
      document.querySelector(".reports.risq_indicators_by_episode")
    )
  )
    return
  window.hideIndicatorsList = hideIndicatorsList

  timePeriodSelectComponent.initDropdown()
  timePeriodSelectComponent.initEventHandler()
  dateRangeComponent.initDatepicker()
  dateFiledTypeSelectComponent.initDropdown()
  dateFiledTypeSelectComponent.initEventHandler()
  reportsSelectsComponent.initReportsDependentDropdowns()

  if (document.querySelector(".reports.risq_indicators_by_episode")) {
    degreeSelectComponent.initRisqDropdown()

    $("#risq_hac_categories_select").dropdown({
      useLabels: false,

      onChange: function (value, text, $choice) {
        document
          .getElementById("risq_hac_categories_select")
          .parentElement.querySelector("i.icon")
          .classList.remove("display-none")

        if (value.length == 1) {
          document
            .getElementById("risq_hac_categories_select")
            .parentElement.querySelectorAll(".ui .label")
            .forEach(e =>
              e.querySelector(".delete").classList.remove("display-none"),
            )
        }
        updateRisqIndicatorList()
      },
      defaultValue: "All",
    })

    $("#risq_hac_indicators_select").dropdown({
      useLabels: false,
      onChange: function (value, text, $choice) {
        document
          .getElementById("risq_hac_indicators_select")
          .parentElement.querySelector("i.icon")
          .classList.remove("display-none")
      },
    })

    let category_icon = document
      .getElementById("risq_hac_categories_select")
      .parentElement.querySelector("i.icon")
    category_icon.classList.remove("dropdown")
    category_icon.classList.add("delete")
    category_icon.addEventListener("click", function (e) {
      $("#risq_hac_categories_select").dropdown("clear")
    })

    let indicator_icon = document
      .getElementById("risq_hac_indicators_select")
      .parentElement.querySelector("i.icon")
    indicator_icon.classList.remove("dropdown")
    indicator_icon.classList.add("delete")
    indicator_icon.addEventListener("click", function (e) {
      $("#risq_hac_indicators_select").dropdown("clear")
    })
    updateRisqIndicatorList()
  } else {
    degreeSelectComponent.initDropdown()
  }

  var tableOffset = $("#episode_indicators").offset().top
  var header = $("#episode_indicators > thead").clone()
  var fixedHeader = $("#episode_header_fixed").append(header)

  $("#hideReviewed").change(function () {
    getNumeratorReport()
  })

  timePeriodSelectComponent.initEventHandler()

  $("a#numerator_report_download_excel_link").click(function (e) {
    e.preventDefault()

    var defaultSelects = {
      filter: getFilterByCheckbox(),
      degree: getIndicatorDegreesSelectValue(),
      tag: $("#tag_select").val(),
      indicator_category_id: indicatorCategoryId(),
    }

    if (document.querySelector(".reports.risq_indicators_by_episode")) {
      var risqSelects = {
        clinician_review: window.gon.clinician_review,
        hac_category:
          $("#risq_hac_categories_select").val() === null ||
          ($("#risq_hac_categories_select").val().length === 1 &&
            $("#risq_hac_categories_select").val()[0] == "")
            ? []
            : $("#risq_hac_categories_select").val(),
        hac_indicators:
          $("#risq_hac_indicators_select").val() === null ||
          ($("#risq_hac_indicators_select").val().length === 1 &&
            $("#risq_hac_indicators_select").val()[0] == "")
            ? []
            : $("#risq_hac_indicators_select").val(),
      }
      defaultSelects = {...defaultSelects, ...risqSelects}
    }

    var compiledParams = paramsCompilerService.compileParams(
      defaultSelects,
      $("#report_type_select").val(),
      $("#date_filed_type_select").val(),
    )

    if (compiledParams === false) {
      return
    }

    sendRequestToGenerateExcel($(this).attr("href"), compiledParams)
  })

  $("#select_all_assign").on("click", function (e) {
    // e.preventDefault();
    bulkToggle(e.target.checked)
  })

  document.getElementById("reassign_button").addEventListener("click", () => {
    toggleReassignModal()
  })

  document
    .getElementById("reassign_confirm_button")
    .addEventListener("click", () => {
      let ep_list = []
      for (
        let i = $dataTable.page.info().page * $dataTable.page.len();
        i < ($dataTable.page.info().page + 1) * $dataTable.page.len() &&
        i < $dataTable.page.info().recordsTotal;
        i++
      ) {
        if ($dataTable.column(1).nodes().to$()[i].firstChild.firstChild.checked)
          ep_list.push(
            $dataTable
              .column(1)
              .nodes()
              .to$()
              [i].firstChild.firstChild.name.replace("selector-", ""),
          )
      }
      const coder_id = document.getElementById("reassign_coder").value
      $.ajax({
        type: "POST",
        contentType: "application/json",
        url: "/episodes/reassign_episode",
        data: JSON.stringify({
          episode_ids: ep_list,
          coder_id: coder_id,
        }),
        async: false,
        success: function (result) {
          bulkToggle(false)
          for (const key in $dataTable.rows().nodes().to$()) {
            if (isNaN(key)) continue
            if (
              ep_list.includes(
                $dataTable
                  .rows()
                  .nodes()
                  .to$()
                  [key].childNodes[0].getAttribute("data-row-id"),
              )
            ) {
              $dataTable.rows().nodes().to$()[key].childNodes[10].innerText =
                coder_id
            }
          }
          toastr.success("Episodes successfully reassigned")
        },
        failure: function (error) {
          raiseBackendServiceError(error)
        },
        error: function (error) {
          raiseBackendServiceError(error)
        },
      })
    })

  // $(".reports.indicators_by_episode").ready(function() {
  //     _numeratorReportReady();
  // });

  // $(".reports.risq_indicators_by_episode").ready(function() {
  //     _numeratorReportReady();
  // });

  window.addEventListener("resize", function () {
    "use strict"
    //need to redraw the table when the page is resized else fixed columns with be the wrong width to enable fixed columns & responsive table to work together.
    $dataTable.draw()
    resizeIndicatorsListTables()
  })

  getNumeratorReport()
  $dataTable = initNumeratorReportTable("episode_indicators")
})

function formatResultTable(data) {
  var formatStatus = function (status) {
    if (status === "Review") {
      return "Review Pending"
    }
    return status
  }

  return (
    '<div class="noscroll">' +
    '<table data-type="indicators" class="ui table">' +
    "<thead>" +
    "<tr>" +
    "<th></th>" +
    "<th>Identifier</th>" +
    "<th>Degree</th>" +
    "<th>Description</th>" +
    "<th>DIS. CHAP.</th>" +
    "<th>Status</th>" +
    "</tr>" +
    "</thead>" +
    data
      .map(function (element) {
        return (
          "<tr>" +
          '<td data-error-type="' +
          element.degree +
          '"></td>' +
          "<td>" +
          element.indicator_identifier +
          "</td>" +
          "<td>" +
          element.degree +
          "</td>" +
          '<td class="table-description">' +
          element.indicator_description +
          "</td>" +
          "<td>" +
          element.icd_dis_chap +
          "</td>" +
          "<td>" +
          formatStatus(element.status) +
          "</td>" +
          "</tr>"
        )
      })
      .join("") +
    "</table></div>"
  )
}

// result table
function updateIndicatorsList(data, event) {
  var button = $(event.target)
  var tr = button.closest("tr")
  var row = $dataTable.row(tr)

  row.child(formatResultTable(data)).show()
  tr.addClass("shown")
  var child_row = tr.next()
  button.hide()
  button.next("button#hideIndicators").show()
  resizeIndicatorsListTables()
}

function resizeIndicatorsListTables() {
  var table_width = $("td.dtfc-fixed-right").first().position().left
  var left_offset = $("#indicators_by_episode_table").position().left
  $(".noscroll").each(function () {
    var table_height = $(this).height() + 20
    $(this).css("left", left_offset + "px")
    $(this).css("width", table_width + "px")
    $(this).css("transform", "translateY(-50%)")
    $(this)
      .parents("tr")
      .first()
      .css("height", table_height + "px")
  })
}

function hideIndicatorsList(event) {
  var button = $(event.target)
  var tr = button.closest("tr")
  var row = $dataTable.row(tr)

  row.child.hide()
  tr.removeClass("shown")
  button.hide()
  button.prev("button#showIndicators").show()
}

// main table
var updateIndicatorsByEpisode = function (data) {
  if ($dataTable !== undefined) {
    document.getElementById("select_all_assign").checked = false
    $dataTable.clear()

    var user_defined_filters = window.gon.user_defined_filters

    $.each(data, function (index, elem) {
      var default_values = []
      if (document.querySelector(".reports.risq_indicators_by_episode")) {
        default_values = [
          elem.id,
          '<div class="ui checkbox">' +
            `<input type="checkbox" name="selector-${elem.id}" onchange="reassignCheckboxChange()">` +
            "<label>" +
            "</label>" +
            "</div",
          elem.details.organisation,
          elem.details.hospital,
          elem.pii_data.mrn,
          elem.pii_data.ueid,
          elem.pii_data.dos,
          elem.details.drg,
          elem.details.d01,
          elem.details.p01,
          elem.details.coder_id,
          elem.details.clinician_id,
          elem.details.coding_date,
        ]
      } else {
        default_values = [
          elem.id,
          '<div class="ui checkbox">' +
            `<input type="checkbox" name="selector-${elem.id}" onchange="reassignCheckboxChange()">` +
            "<label>" +
            "</label>" +
            "</div",
          elem.details.organisation,
          elem.details.hospital,
          elem.pii_data.mrn,
          elem.pii_data.ueid,
          elem.pii_data.dos,
          elem.details.sex,
          elem.details.drg,
          elem.details.d01,
          elem.details.p01,
          elem.details.coder_id,
          elem.details.coding_date,
        ]
      }

      var user_defined_values = []
      user_defined_filters.forEach(function (value) {
        user_defined_values.push(elem.details[value])
      })

      var end_row_empty_cols = ["", ""]
      $dataTable.row.add(
        default_values.concat(user_defined_values).concat(end_row_empty_cols),
      )
    })

    $dataTable.draw()
  }
}

function bulkToggle(state) {
  document.getElementById("select_all_assign").checked = state
  let selected_count = 0
  for (
    let i = $dataTable.page.info().page * $dataTable.page.len();
    i < ($dataTable.page.info().page + 1) * $dataTable.page.len() &&
    i < $dataTable.page.info().recordsTotal;
    i++, selected_count++
  ) {
    $dataTable.column(1).nodes().to$()[i].firstChild.firstChild.checked = state
    if (state === false)
      $dataTable.column(1).nodes().to$()[i].firstChild.firstChild.disabled =
        state
  }
  if (state) {
    document.getElementById(
      "selected_count",
    ).innerText = `${selected_count} selected`
    $("#table_actions").show()
    selected_org = $dataTable.column(2).data()[
      $dataTable.page.info().page * $dataTable.page.len()
    ]
  } else {
    $("#table_actions").hide()
    selected_org = null
  }
}

function toggleReassignModal() {
  while (document.getElementById("reassign_coder").hasChildNodes()) {
    document
      .getElementById("reassign_coder")
      .remove(document.getElementById("reassign_coder").firstChild)
  }
  let coder_options = $(
    `optgroup[label='${selected_org.replace("'", "\\'")}']`,
  )[0].children
  for (let i = 0; i < coder_options.length; i++) {
    document
      .getElementById("reassign_coder")
      .appendChild(coder_options[i].cloneNode(true))
  }
  $("#reassign_coder").dropdown()
  $("#reassignModalElement").modal({autofocus: false}).modal("show")
}

function reassignCheckboxChange() {
  let any_checked = false
  let all_checked = true
  let selected_count = 0
  for (
    let i = $dataTable.page.info().page * $dataTable.page.len();
    i < ($dataTable.page.info().page + 1) * $dataTable.page.len() &&
    i < $dataTable.page.info().recordsTotal;
    i++
  ) {
    if ($dataTable.column(1).nodes().to$()[i].firstChild.firstChild.checked) {
      selected_org = $dataTable.column(2).data()[i]
      any_checked = true
      selected_count++
    } else {
      all_checked = false
      // if (any_checked) break
    }
  }
  if (any_checked) {
    document.getElementById(
      "selected_count",
    ).innerText = `${selected_count} selected`
    $("#table_actions").show()
    for (
      let i = $dataTable.page.info().page * $dataTable.page.len();
      i < ($dataTable.page.info().page + 1) * $dataTable.page.len() &&
      i < $dataTable.page.info().recordsTotal;
      i++
    ) {
      if ($dataTable.column(2).data()[i] !== selected_org) {
        $dataTable.column(1).nodes().to$()[
          i
        ].firstChild.firstChild.disabled = true
      }
    }
  } else {
    resetCheckboxes()
  }
  if (all_checked) document.getElementById("select_all_assign").checked = true
}

function resetCheckboxes() {
  for (const key in $dataTable.column(1).nodes().to$()) {
    if (isNaN(key)) continue
    $dataTable.column(1).nodes().to$()[
      key
    ].firstChild.firstChild.disabled = false
    $dataTable.column(1).nodes().to$()[
      key
    ].firstChild.firstChild.checked = false
  }
  let old_select_all = document.getElementById("select_all_outer")
  let new_select_all = old_select_all.cloneNode(true)
  old_select_all.parentNode.replaceChild(new_select_all, old_select_all)
  $("#select_all_assign").on("click", function (e) {
    bulkToggle(e.target.checked)
  })
  document
    .getElementById("select_all_assign")
    .style.removeProperty("pointer-events")

  document.getElementById("select_all_assign").disabled = false
  let first_org = null
  for (
    let i = $dataTable.page.info().page * $dataTable.page.len();
    i < ($dataTable.page.info().page + 1) * $dataTable.page.len() &&
    i < $dataTable.page.info().recordsTotal;
    i++
  ) {
    if (first_org === null) first_org = $dataTable.column(2).data()[i]
    if ($dataTable.column(2).data()[i] !== first_org) {
      document.getElementById("select_all_assign").disabled = true
      document.getElementById("select_all_assign").style.pointerEvents = "none"
      document
        .getElementById("select_all_outer")
        .addEventListener("click", () => {
          toastr.error(
            "Episodes belonging to different hospitals cannot be reassigned. Please select episodes for one hospital.",
          )
        })
      break
    }
  }

  document.getElementById("select_all_assign").checked = false
  selected_org = null
  $("#table_actions").hide()
}

window.reassignCheckboxChange = reassignCheckboxChange
