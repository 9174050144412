import { modalComponent } from "./components/modal.component";

var ieHelpers = function() {
  var _ieVersion = function() {
    var userAgent = window.navigator.userAgent;

    if (userAgent.indexOf("MSIE ") > 0) return 10; // or older
    if (userAgent.indexOf("Trident/") > 0) return 11;

    return null;
  };

  var _showUnsupportedBrowserMessage = function() {
    if (_ieVersion() === 10) {
      modalComponent.showModal({
        selectorId: "modalElement",
        header: "Unsupported browser detected",
        text:
          "Unfortunately you are using a browser that is not supported by PICQ. Please upgrade or install one of the supported browsers. - IE11+, Chrome 63+, Firefox 60+, Safari 12+",
        ok: "Ok",
        cancel: "Don't show again",
        onApproveFunction: ""
      });
    }
  };

  var checkLocationShowModal = function() {
    if (window.location.pathname == "/user_session/new") {
      _showUnsupportedBrowserMessage();
    }
  };

  var isIe = function() {
    return !!_ieVersion();
  };

  return {
    checkLocationShowModal: checkLocationShowModal,
    isIe: isIe
  };
};

export default ieHelpers;
