import { debounce } from "../helpers";

function validatePasswordComplexity(form) {
  var serverValidationErrors = [];
  var confirmationValidationErrors = [];
  var passwordFieldValue,
    passwordConfirmationFieldValue,
    passwordScore,
    validationPassed;

  var errorsDivElemet = document.createElement("div");
  errorsDivElemet.setAttribute("class", "password-validation-errors");
  errorsDivElemet.setAttribute("id", "passwordValidationErrors");

  form.append(errorsDivElemet);

  var submitButton = document.getElementById("submitFormButton");
  var passwordComplexityBar = $("#passwordComplexityBar");
  var passwordConfirmationComplexityBar = $(
    "#passwordConfirmationComplexityBar"
  );

  function validationErrors() {
    return serverValidationErrors.concat(confirmationValidationErrors);
  }

  function displayErrors() {
    errorsDivElemet.innerHTML = validationErrors().join("<br/>");
  }

  function toggleSubmitButton() {
    if (
      passwordFieldValue !== undefined &&
      passwordConfirmationFieldValue !== undefined &&
      validationErrors().length === 0 &&
      validationPassed == true
    ) {
      submitButton.disabled = false;
    } else {
      submitButton.disabled = true;
    }
  }

  function render() {
    passwordComplexityBar.progress({ value: passwordScore, total: 4 });
    toggleSubmitButton();
    displayErrors();
  }

  function validatePasswordComplexityUrl() {
    return "/users/validate_password_complexity.json";
  }

  function validatePasswordComplexityAjax() {
    passwordFieldValue = document.getElementById("user_password").value;
    $.ajax({
      type: "POST",
      contentType: "application/json",
      url: validatePasswordComplexityUrl(),
      data: JSON.stringify({ user: { password: passwordFieldValue } }),
      success: function(response) {
        serverValidationErrors = response.data.errors;
        passwordScore =
          serverValidationErrors.length === 0 ? response.data.score : 1;
        validationPassed = response.data.validation_passed;
        validatePasswordConfirmation();
        render();
      },
      error: function(response) {
        console.error("validatePasswordComplexityAjax FAILED");
      }
    });
  }

  function validatePasswordConfirmation() {
    passwordConfirmationFieldValue = document.getElementById(
      "user_password_confirmation"
    ).value;

    if (
      passwordConfirmationFieldValue.length > 0 &&
      passwordFieldValue !== passwordConfirmationFieldValue
    ) {
      confirmationValidationErrors = [
        "Password confirmation doesn't match the password"
      ];
      passwordConfirmationComplexityBar.progress("set error");
    } else if (passwordConfirmationFieldValue.length === 0) {
      confirmationValidationErrors = ["Password confirmation can't be blank"];
    } else {
      confirmationValidationErrors = [];
      passwordConfirmationComplexityBar.progress("set success");
    }
  }

  $("#user_password, #user_password_confirmation").keyup(
    debounce(function() {
      validatePasswordComplexityAjax();
    }, 350)
  );
}

export default validatePasswordComplexity;
