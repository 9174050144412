import { restyleCheckboxes } from "./common/helpers";
import {
  showFullScreenSpinner,
  hideFullScreenSpinner
} from "./reports/components/loading_cirlce_component";

$(".indicators.index").ready(function() {
  $("#indicators_table").DataTable({
    dom: '<"toolbar">flrtip',
    order: [[4, "desc"]],
    language: {
      search: "",
      searchPlaceholder: "Search...",
      sSearch: '<i class="search icon"></i>'
    },
    paging: true,
    page: 1,
    lengthMenu: [
      [10, 25, 50, 100],
      [10, 25, 50, 100]
    ],
    columnDefs: [{ orderable: false, targets: [0] }],
    ajax: function(data, callback) {
      showFullScreenSpinner();

      function getIndicatorsUrl() {
        var dataIndicatorSetId = document.querySelector("#indicators_table")
          .dataset.indicatorSetId;
        var errors = document.querySelector("#indicators_table").dataset.errors;

        if (dataIndicatorSetId !== null) {
          return (
            "indicators.json?indicator_sets_id=" +
            dataIndicatorSetId +
            "&errors=" +
            errors
          );
        } else {
          return "indicators.json?errors=" + errors;
        }
      }

      $.ajax({
        url: getIndicatorsUrl(),
        type: "GET",
        success: function(data) {
          hideFullScreenSpinner();
          callback({
            data: data.data.indicators
          });
        }
      });
    },

    columns: [
      {
        data: null,
        render: function(data) {
          return (
            "<input type='checkbox' name='delete[]' value='" +
            data["id"] +
            "'>" +
            "<label></label>"
          );
        }
      },
      { data: "identifier" },
      { data: "description" },
      { data: "threshold" },
      { data: "updated_at" },
      {
        data: null,
        render: function(data) {
          return (
            "<a class='icon-action' title='Edit' href='" +
            data["edit_indicator_path"] +
            "'>" +
            "<i class='edit icon'></i>" +
            "</a>" +
            "<a class='icon-action' title='Duplicate' rel='nofolow' href='" +
            data["duplicate_indicator_path"] +
            "'>" +
            "<i class='copy outline icon'></i>" +
            "</a>"
          );
        }
      }
    ],
    drawCallback: function() {
      restyleCheckboxes();
    },
    createdRow: function(row, data) {
      if (data.enabled !== true) {
        $("td", row).attr("class", "disabled-indicator");
        $("td", row)
          .find("input")
          .attr("disabled", "disabled");
        $("td", row)
          .eq(0)
          .parent()
          .attr("data-delete-prohibited", data["delete_prohibited"]);
        $("td", row)
          .eq(0)
          .attr("data-tooltip", data["delete_prohibition_message"]);
        $("td", row)
          .eq(0)
          .attr("data-position", "right center");
      }
      if (data.has_syntax_error) {
        $("td", row)
          .eq(0)
          .parent()
          .attr("class", "syntax-error");
      }
    }
  });

  $("#indicators_table").on("preDraw.dt", function() { 
    clearSelectAll('#indicators_table .select-all');
  })

  $("#indicators_table").css("width", "100%");

  if ($("#table_actions") !== undefined) {
    $("div.toolbar").html($("#table_actions"));
  }
});

$(".indicators").ready(function() {
  $("#indicator_indicator_set_id").dropdown();
});
