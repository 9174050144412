import { optionsManagerService } from "../services/options-manager.service";
import { selectsStorageService } from "../services/selects-storage.service";
import { objectValuesPolyfill } from "../../common/helpers";
// TODO: send this component to Ui only after auth.
// TODO: write unit-tests.

export var dateFiledTypeSelectComponent = (function() {
  var _savedOptionsNameMapping = {
    "0": {
      dos: [{ "Date of separation": "dos" }]
    },
    "1": {
      coding_date: [{ "Coding date": "coding_date" }]
    }
  };

  var _defaultOptions = [
    { "Date of separation": "dos" },
    { "Coding date": "coding_date" }
  ];

  var _getDateFieldTypeOptionsByConfig = function(itemValue) {
    return _savedOptionsNameMapping[itemValue];
  };

  var _setActiveSavedOption = function() {
    var savedItemValue = selectsStorageService.pullSelectFromStorage(
      "savedOption"
    );
    var mappedItemValue;

    if (savedItemValue === undefined) {
      return;
    }

    var dateFieldTypeOptions = _getDateFieldTypeOptionsByConfig(savedItemValue);

    for (var elem in dateFieldTypeOptions) {
      mappedItemValue = objectValuesPolyfill(
        dateFieldTypeOptions[elem][0]
      ).toString();
    }

    $("#date_filed_type_select").val(mappedItemValue);
  };

  var _fillDateFieldTypeSelect = function() {
    for (var elem in _defaultOptions) {
      $("#date_filed_type_select").append(
        '<option value="' +
          objectValuesPolyfill(_defaultOptions[elem])[0] +
          '">' +
          Object.keys(_defaultOptions[elem])[0] +
          "</option>"
      );
    }

    var defaultOptionValue = "dos";
    optionsManagerService.setActiveSavedOption(
      "date_filed_type_select",
      "date_filed_type_select",
      defaultOptionValue
    );

    $("#date_filed_type_select").dropdown();
  };

  var initEventHandler = function() {
    var $date_filed_type_select = $("#date_filed_type_select");

    $date_filed_type_select.on("focusin", function(e) {
      var targetId = e.currentTarget.id;
      optionsManagerService.selectsStateStorage[targetId].oldValue =
        e.currentTarget.value;
    });

    $date_filed_type_select.on("change", function(e) {
      var targetId = e.currentTarget.id;
      if (
        optionsManagerService.selectsStateStorage[targetId].oldValue !==
        e.currentTarget.value
      ) {
        optionsManagerService.selectsStateStorage[targetId].newValue =
          e.currentTarget.value;
        optionsManagerService.selectsStateStorage[
          targetId
        ].optionIsChanged = true;
      }

      if (optionsManagerService.checkOptionIsChanged(targetId)) {
        optionsManagerService.processSavingChangedOption(
          targetId,
          optionsManagerService.selectsStateStorage[targetId].newValue
        );
      }
    });
  };

  var initDropdown = function() {
    _fillDateFieldTypeSelect();
  };

  var _returnMappedChangedOption = function() {
    var value = $("#date_filed_type_select").val();

    for (var item in _savedOptionsNameMapping) {
      if (Object.keys(_savedOptionsNameMapping[item]).toString() === value) {
        return item;
      }
    }
  };

  return {
    initDropdown: initDropdown,
    initEventHandler: initEventHandler
  };
})();
