import {
  showDataLoading,
  hideDataLoading
} from "../components/loading_cirlce_component";
import { raiseBackendServiceError } from "../../common/helpers";
import {
  displayBarChart,
  displayDonutCharts,
  displayEpisodeIndicators,
  displaySpecificityDonutChart
} from "../helpers";

export var sendRequestToGenerateExcel = function(url, params) {
  window.location = url + "?" + $.param(params);
};

export var displayUniqueEpisodesCount = function(count, countSource) {
  $("#unique_episodes_count").text(count);
  $("#unique_episodes_tooltip").attr("data-tooltip", countSource);
};

export var getDashboardBenchmarkReport = function(params) {
  var svg = d3.select("#benchmark_graph");
  svg.selectAll("*").remove();

  var request = $.ajax({
    type: "GET",
    url: "/reports/dashboard_benchmark_report.json",
    data: params,
    success: function(result) {
      displayBarChart(result, params.width, params.height);
    },
    error: function(error) {
      hideDataLoading();
      raiseBackendServiceError(error);
    }
  });

  return request;
};

export var getErrorHitsReport = function(params) {
  var request = $.ajax({
    type: "GET",
    url: "/reports/error_hits_report.json",
    data: params,
    success: function(result) {
      displayDonutCharts(result.error_hits, params.mode);
      displayEpisodeIndicators(result.error_hits.numerator_data, params.mode);
      displayUniqueEpisodesCount(
        result.error_hits.unique_episodes_count,
        result.error_hits.count_source
      );
    },
    error: function(error) {
      raiseBackendServiceError(error);
    }
  });
  return request;
};

export var getSpecificityHitsReport = function(params) {
  var request = $.ajax({
    type: "GET",
    url: "/reports/specificity_hits_report.json",
    data: params,
    success: function(result) {
      displaySpecificityDonutChart(result.error_hits, params.mode);
      displayUniqueEpisodesCount(result.unique_episodes);
    },
    error: function(error) {
      raiseBackendServiceError(error);
    }
  });

  return request;
};
