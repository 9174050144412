import {
  compileEpisodeIndicatorsParams,
  raiseBackendServiceError,
} from "./common/helpers"

$(".episodes.review_episode").ready(function () {
  initReiviewEpisode()
})

export function initReiviewEpisode() {
  removeAdditionalJustificationModals()
  convertAllDatesToLocalTime()
  var _sentData = false //used to only allow the save button to be clicked once in justification/comment modal.
  var _numeratorHitId

  $(".indicator-details").click(function () {
    var indicatorId = getElementId($(this).attr("id"))
    $("#rationale_" + indicatorId)
      .modal({
        allowMultiple: true,
      })
      .modal("show")
  })

  initVersionButtons()

  $(document).ready(function () {
    function setIndicatorId(selector) {
      $(selector).each(function () {
        const indicatorId = $(this).closest('#indicator-row').data('indicator-id')
        $(this).data('indicator-id', indicatorId)
      })
    }

    const selectors = [
      '.indicator-category-picq .new-indicator',
      '.indicator-category-risq .new-indicator',
      '.indicator-category-picq .fix-indicator',
      '.indicator-category-risq .fix-indicator',
      '.indicator-category-picq .justify-indicator',
      '.indicator-category-risq .justify-indicator',
      '.indicator-category-risq .review-indicator',
      '.indicator-category-risq .specialist-review-indicator'
    ]

    selectors.forEach(setIndicatorId);
  })

  $(".indicator-category-picq .new-indicator").click(function () {
    _numeratorHitId = getElementId($(this).attr("id"))
    const indicatorId = $(this).data('indicator-id')

    highlightNumeratorHitStatus("New")
    updateNumeratorHitStatus(
      "New",
      "",
      updateNumeratorHitStatusPath(_numeratorHitId),
      indicatorId
    )
  })

  $(".indicator-category-picq .fix-indicator, .indicator-category-risq .fix-indicator").click(function () {
    var description = $(this).data('description')
    var headerElement = $('#justification_modal .header')
    const indicatorId = $(this).data('indicator-id')

    headerElement.find('.smaller-text').text(description)

    _numeratorHitId = getElementId($(this).attr("id"))
    highlightNumeratorHitStatus("Fixed")
    updateFormAndShowCommentBox("Fixed", _numeratorHitId, indicatorId)
  })

  $(".indicator-category-risq .review-indicator").click(function () {
    var description = $(this).data('description')
    var headerElement = $('#justification_modal .header')
    const indicatorId = $(this).data('indicator-id')

    headerElement.find('.smaller-text').text(description)

    _numeratorHitId = getElementId($(this).attr("id"))
    highlightNumeratorHitStatus("Review")
    updateFormAndShowCommentBox("Review", _numeratorHitId, indicatorId)
  })

  $(".justify-indicator").on("click", function () {
    var description = $(this).data('description');
    var headerElement = $('#justification_modal .header')
    const indicatorId = $(this).data('indicator-id')

    headerElement.find('.smaller-text').text(description)

    _numeratorHitId = getElementId($(this).attr("id"))
    highlightNumeratorHitStatus("Justified")
    updateFormAndShowCommentBox("Justified", _numeratorHitId, indicatorId)
  })

  $(".indicator-category-risq .specialist-review-indicator").on("click", function () {
    var description = $(this).data('description');
    var headerElement = $('#justification_modal .header')
    const indicatorId = $(this).data('indicator-id');

    headerElement.find('.smaller-text').text(description)

    _numeratorHitId = $(this).attr("id").split("_")[3]
    highlightNumeratorHitStatus("Specialist Review Pending")
    updateFormAndShowCommentBox("Specialist_Review_Pending", _numeratorHitId, indicatorId)
  })

  $(".indicator-category-risq button.indicator-status").click(function () {
    var description = $(this).data('description')
    var ele = $(this)
    var headerElement = $('#justification_modal .header')
    const indicatorId = $(this).data('indicator-id')

    headerElement.find('.smaller-text').text(description)

    _numeratorHitId = getElementId(ele.attr("id"))
    var status = ele.data("status")
    highlightNumeratorHitStatus(status)
    updateFormAndShowCommentBox(status, _numeratorHitId, indicatorId)
  })

  $("#cancel_justification").click(function (event) {
    event.preventDefault()
  })

  $("#update_numerator_hit_form").append('<input type="hidden" id="indicatorId" name="indicatorId" />')

  var updateFormAndShowCommentBox = function (status, numeratorHitId, indicatorId) {
    _sentData = false
    $("#update_numerator_hit_form").attr(
      "action",
      updateNumeratorHitStatusPath(numeratorHitId),
    )
    $("#update_numerator_hit_form #status").val(status)
    $("#update_numerator_hit_form #indicatorId").val(indicatorId)
    //getNumeratorHitDetails(_numeratorHitId);
    clearCommentBox()
    $(".ui.modal.justification")
      .modal({
        closable: false,
        allowMultiple: true,
        onShow: function() {
          $('#reviewEpiosdeModal').parent().css('pointer-events', 'none')
          $('#reviewEpiosdeModal').css('pointer-events', 'none')
          $('.ui.modal.justification').css('pointer-events', 'auto')
        },
        onHide: function () {
          $('#reviewEpiosdeModal').css('pointer-events', 'auto')
          $('#reviewEpiosdeModal').parent().css('pointer-events', 'auto')
          showToggleAndCommentsSection(indicatorId)
          $(".justification_error_msg").hide()
          $(".specialist_error_msg").hide()
          highlightNumeratorHitStatus(
            $("#numerator_hit_status_" + numeratorHitId).attr("value"),
          )
        },
      })
      .modal("show")
  }

  $("#update_numerator_hit_form").submit(function (event) {
    event.preventDefault()
    if (_sentData == true) return
    var justification = $("#justification").val()
    var indicator_category = $("#indicator_category").val()
    var status = $("#update_numerator_hit_form #status").val()
    const indicatorId = $("#update_numerator_hit_form #indicatorId").val()

    if (
      (status == "Justified" || (status == "New" && indicator_category == "risq")) &&
      justification !== null &&
      justification.trim().length === 0
    ) { $(".justification_error_msg").show()}
    else if(
      indicator_category === "risq" &&
      status == "Specialist_Review_Pending" &&
      justification.trim().length === 0
    ) { $(".specialist_error_msg").show() }
    else {
      _sentData = true
      updateNumeratorHitStatus(
        $("#status").val(),
        $("#justification").val(),
        $(this).attr("action"),
        indicatorId
      )
    }
  })

  var clearCommentBox = function () {
    $("#justification").val("")
  }

  var getElementId = function (id) {
    return id.split("_")[2]
  }

  var getNumeratorHitDetailsPath = function (numeratorHitId) {
    return "/numerator_hits/" + numeratorHitId + "/get_details"
  }

  var updateNumeratorHitStatusPath = function (numeratorHitId) {
    return "/numerator_hits/" + numeratorHitId + "/update_status"
  }

  var getNumeratorHitDetails = function (numeratorHitId) {
    //this function is making a request to get the existing justification for the numerator hit.
    //in the justification modal it shows this value.
    //is no longer needed

    $.ajax({
      type: "GET",
      contentType: "application/json",
      url: getNumeratorHitDetailsPath(numeratorHitId),
      async: false,
      success: function (result) {
        if (result.message == "Found") {
          $("#justification").val(result.numerator_hit.justification)
        }
      },
      failure: function (error) {
        console.log("Failed to get numerator hit details")
      },
    })
  }

  var updateNumeratorHitStatus = function (status, justification, url, indicatorId) {
    $.ajax({
      type: "POST",
      contentType: "application/json",
      url: url,
      data: JSON.stringify({
        status: status.toLowerCase(),
        justification: justification,
      }),
      async: false,
      success: function (result) {
        if (result.message == "Status updated") {
          $("#numerator_hit_status_" + _numeratorHitId).attr("value", status)
          updateAuditTrail(jQuery.makeArray(result.audit_trail))

          // Create a comment section with the status even if the user doesn't add a comment
          const comment = result.episode_comment || {}
          comment.status = status;

          insertComment(comment, indicatorId)
          showToggleAndCommentsSection(indicatorId);
        } else {
          highlightNumeratorHitStatus(
            $("#numerator_hit_status_" + _numeratorHitId).attr("value"),
          )
        }
        hideEpisodeFromReport(_numeratorHitId)
      },
      failure: function (error) {
        highlightNumeratorHitStatus(
          $("#numerator_hit_status_" + _numeratorHitId).attr("value"),
        )
      },
      error: function (error) {
        highlightNumeratorHitStatus(
          $("#numerator_hit_status_" + _numeratorHitId).attr("value"),
        )
        raiseBackendServiceError(error)
      },
    })
    $(".ui.modal.justification").modal("hide")
  }

  const highlightNumeratorHitStatus = (status) => {
    const statusTypes = {
      "New": "new",
      "Fixed": "fix",
      "Justified": "justify",
      "Review": "review",
      "Specialist Review Pending": "specialist_review"
    }

    Object.keys(statusTypes).forEach(key => {
      const element = $(`#${statusTypes[key]}_indicator_${_numeratorHitId}`)

      if (key === status.split("_").join(" ")) {
        element.removeClass("basic")
      } else {
        element.addClass("basic")
      }
    })
  }

  function convertAllDatesToLocalTime() {
    $("#episode_audit_trail tr td:first-child").each(function () {
      var converted_to_localtime = new Date($(this).text()).formatDDMMYYYYHHSS()
      $(this).text(converted_to_localtime)
    })

    $("#commentsTable tr td:nth-child(3)").each(function () {
      var converted_to_localtime = new Date($(this).text()).formatDDMMYYYYHHSS()
      $(this).text(converted_to_localtime)
    })
  }

  function removeAdditionalJustificationModals() {
    //remove justification modals created by previous episode review modals
    if ($(".ui.modal.justification").length > 1) {
      $(".ui.modal.justification:not(:first)").remove()
    }
  }

  var updateAuditTrail = function (audit_trails) {
    $.each(audit_trails, function (i, audit_trail) {
      var converted_to_localtime = new Date(
        audit_trail.updated_at,
      ).formatDDMMYYYYHHSS()
      $("#episode_audit_trail").prepend(
        "<tr><td>" +
          converted_to_localtime +
          "</td><td>" +
          audit_trail.created_by +
          "</td><td>" +
          audit_trail.action +
          "</td><td>" +
          audit_trail.details +
          "</td></tr>",
      )
    })
  }

  var insertComment = function (comment, indicatorId) {
    var clinician_html =
      (comment.role == "clinician" || comment.role == "clinician_manager")
        ? '<div class="ui blue right ribbon label">Clinician</div>'
        : ''
    var converted_to_localtime = new Date(comment.updated_at).formatDDMMYYYYHHSS()

    if (!comment.body) {
      comment.body = '';
    }

    const reviewStatus = comment.status === "Review"
    const commentStatus = reviewStatus ? "Review Pending" : comment.status

    var html = "<tr>" +
        "  <td>" +
        comment.who +
        "</td>" +
        "  <td>" +
        commentStatus.split("_").join(" ") +
        "</td>" +
        "  <td>" +
        converted_to_localtime +
        "</td>" +
        '  <td class="comment-body">' +
        comment.body +
        "</td>";
        html += "<td>" + clinician_html + "</td>";
    html += "</tr>";

    $("#comments-" + indicatorId + " tbody").prepend(html);
  }

  function initVersionButtons() {
    $(".version_buttons").click(function () {
      var _versionNum = getElementId($(this).attr("id"))
      var indicator_category = $("#indicator_category").val() === "picq" ? 1 : 2
      var episode_id = $(this).attr("episode_id")
      $.ajax({
        url: "/episodes/version_episode",
        type: "GET",
        data: {
          episode_id: episode_id,
          indicator_category_id: indicator_category,
          episode_version: _versionNum,
        },
        success: function (response) {
          // initReiviewEpisode()
          convertAllDatesToLocalTime()
          initVersionButtons()
        },
      })
    })
  }

  function hideEpisodeFromReport(numeratorHitId) {
    if (
      document.querySelector(".reports.risq_indicators_by_episode") ||
      document.querySelector(".reports.indicators_by_episode")
    ) {
      // check backend against current filters to see if it should still appear
      $.ajax({
        url: "/numerator_hits/" + numeratorHitId + "/report_removal",
        type: "GET",
        data: {
          ...compileEpisodeIndicatorsParams(),
        },
        success: function (response) {
          // remove from datatables depending on backend response
          if (response["status"] === true) {
            const table = $("#episode_indicators").DataTable()
            const ep_row = $('[data-row-id="' + response["episode_id"] + '"]')
            table.rows(ep_row).remove().draw()
          }
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.error(`Request failed: ${textStatus}, Error Thrown: ${errorThrown}`)
        },
      })
    }
  }

  function showToggleAndCommentsSection(indicatorId) {
    const toggleButton = $('.toggle-button-' + indicatorId)
    const commentsSection = $('#comments-' + indicatorId)

    toggleButton.show()
    commentsSection.show()

    const chevronIcon = toggleButton.find('i')
    const isCommentsVisible = commentsSection.is(":visible")

    // Toggle the icon class based on the visibility of the comments section
    chevronIcon.toggleClass('chevron circle right icon', !isCommentsVisible)
               .toggleClass('chevron circle down icon', isCommentsVisible)
  }

  $('button.toggle-comments').click(function() {
    const indicatorId = $(this).data('indicator-id')
    const commentElement = $('#comments-' + indicatorId).toggle()
    const iconElement = $(this).find('i')
    const isShown = commentElement.is(":visible")

    if (isShown) {
        iconElement.removeClass('chevron circle right icon').addClass("chevron circle down icon")
    } else {
        iconElement.removeClass('chevron circle down icon').addClass('chevron circle right icon')
    }
  })
}

Date.prototype.formatDDMMYYYYHHSS = function () {
  return (
    this.getDate().toString().padStart(2, "0") +
    "/" +
    (this.getMonth() + 1).toString().padStart(2, "0") +
    "/" +
    this.getFullYear().toString().padStart(2, "0") +
    " " +
    this.getHours().toString().padStart(2, "0") +
    ":" +
    this.getMinutes().toString().padStart(2, "0") +
    ":" +
    this.getSeconds().toString().padStart(2, "0")
  )
}
