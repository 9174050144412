import {dateFiledTypeSelectComponent} from "./reports/components/date_filed_type_select_component"
import {dateRangeComponent} from "./reports/components/date_range_component"
import {paramsCompilerService} from "./reports/services/params-compiler.service"
import {raiseBackendServiceError} from "./common/helpers"
import {reportsSelectsComponent} from "./reports/components/reports_selects_component"
import {sendRequestToGenerateExcel} from "./reports/services/reports-api.service"
import {
  showDataLoading,
  hideDataLoading,
} from "./reports/components/loading_cirlce_component"
import {timePeriodSelectComponent} from "./reports/components/indicator_time_period_select_component"

var $dataTable

$(document).ready(function () {
  if (!document.querySelector(".indicator_management.index")) return

  const setupDropdownSelected = (elementId, allText, defaultValue) => {
    $(`#${elementId}`).dropdown({
      useLabels: false,
      onChange: function (value, text, $choice) {
        document
          .getElementById(elementId)
          .parentElement.querySelector("i.icon")
          .classList.remove("display-none")

        if (text === allText || !value) {
          $(`#${elementId}`).dropdown("set value", defaultValue)
        }
      },
      defaultValue: defaultValue,
    })
  }

  const setupDropdownIcon = elementId => {
    const element = document.getElementById(elementId)
    if (!element) return

    const icon = element.parentElement.querySelector("i.icon")
    if (!icon) return

    icon.classList.remove("dropdown")
    icon.classList.add("delete")

    icon.addEventListener("click", function (e) {
      $(`#${elementId}`).dropdown("clear")
    })
  }

  setupDropdownSelected("hospital_groups_select", "All", "All")
  setupDropdownSelected("hospitals_select", "All Hospitals", "All")
  setupDropdownSelected("indicator_select", "All Indicators", "All")

  setupDropdownIcon("hospitals_select")
  setupDropdownIcon("hospital_groups_select")
  setupDropdownIcon("indicator_select")

  dateFiledTypeSelectComponent.initDropdown()
  dateFiledTypeSelectComponent.initEventHandler()
  dateRangeComponent.initDatepicker()
  reportsSelectsComponent.initReportsDependentDropdowns()
  timePeriodSelectComponent.initDropdown()
  timePeriodSelectComponent.initEventHandler()

  $dataTable = initIndicatorManagementReportTable("indicator-management-table")

  $("#date_filed_type_select").dropdown("set selected", "dos")
  $("#datepickerSelectInput").show()
  $("#hospital_groups_select").dropdown()
  $("#hospitals_select").dropdown()
  $("#indicator_select").dropdown()
  $("#indicator_set_select").dropdown()
  $("#indicator_time_period_select").dropdown("set selected", "Date range")
  $("#number_of_episode_select").dropdown()
  $("#status_select").dropdown()

  $("#indicator_set_select").on("change", function () {
    const indicatorSetId = $(this).val()

    let url
    if (indicatorSetId) {
      url = `/indicators?indicator_set_id=${indicatorSetId}`
    } else {
      url = "/indicators"
    }

    $.ajax({
      url: url,
      dataType: "json",
      success: function (response) {
        const indicators = response.data.indicators
        const $indicatorSelect = $("#indicator_select")
        $indicatorSelect.empty()

        $.each(indicators, function (index, indicator) {
          $indicatorSelect.append(
            $("<option>", {
              value: indicator.id,
              text: indicator.identifier,
            }),
          )
        })

        if (indicators.length > 0) {
          $("#indicator-container").show()
          $indicatorSelect.dropdown("refresh")
        } else {
          $("#indicator-container").hide()
          $indicatorSelect.empty().dropdown("refresh")
        }
      },
    })
  })

  $("a#denominator_report_download_excel_link").click(function (e) {
    e.preventDefault()

    const reportTypeSelect = $("#report_type_select").val()
    const dateRangeFrom = $("#datepickerSelectFrom").val()
    const dateRangeTo = $("#datepickerSelectTo").val()
    const emptyDateRange = !dateRangeFrom || !dateRangeTo
    var compiledParams

    var defaultSelects = {
      hospitals: ["All"],
      hospital_groups: ["All"],
      date_range_from: dateRangeFrom,
      date_range_to: dateRangeTo,
      empty_date_range: emptyDateRange,
      indicator_set: $("#indicator_set_select").val()
        ? $("#indicator_set_select").val()
        : "All",
      indicators: $("#indicator_select").val()
        ? $("#indicator_select").val()
        : ["All"],
    }

    if (reportTypeSelect === "hospital") {
      defaultSelects.hospitals = $("#hospitals_select").val() || ["All"]
    } else if (reportTypeSelect === "hospitalGroup") {
      defaultSelects.hospital_groups = $("#hospital_groups_select").val() || [
        "All",
      ]
    }

    compiledParams = paramsCompilerService.compileParams(
      defaultSelects,
      $("#report_type_select").val(),
      $("#date_filed_type_select").val(),
    )

    toastr.info("Your file download has started.")
    sendRequestToGenerateExcel($(this).attr("href"), compiledParams)
  })

  $(document).ready(function () {
    $(".delete-numerator-btn").on("click", function (e) {
      e.preventDefault()
      var selectedEpisodes = gatherSelectedEpisodes()
      var selectedIndicators = gatherSelectedIndicators()

      if (selectedEpisodes.length > 0) {
        if (
          confirm(
            "Are you sure you want to delete the selected numerator hits?",
          )
        ) {
          $.ajax({
            url: "/indicators/bulk_delete_numerator",
            type: "DELETE",
            data: {
              episode_ids: selectedEpisodes,
              indicator_ids: selectedIndicators,
            },
            success: function (response) {
              toastr.success("Selected numerator hits deleted successfully")
              removeStatusFromTable(selectedEpisodes, selectedIndicators)
              $dataTable.draw()
            },
            error: function (xhr, status, error) {
              toastr.error("Failed to delete numerator hits: " + error)
            },
          })
        }
      } else {
        alert("Please select at least one episode to delete.")
      }
    })

    $(".delete-both-btn").on("click", function (e) {
      e.preventDefault()
      var selectedEpisodes = gatherSelectedEpisodes()
      var selectedIndicators = gatherSelectedIndicators()

      if (selectedEpisodes.length > 0) {
        if (
          confirm(
            "Are you sure you want to delete the selected numerator and denominator hits?",
          )
        ) {
          $.ajax({
            url: "/indicators/bulk_delete_both",
            type: "DELETE",
            data: {
              episode_ids: selectedEpisodes,
              indicator_ids: selectedIndicators,
            },
            success: function (response) {
              toastr.success(
                "Selected numerator and denominator hits deleted successfully",
              )
              removeDeletedEpisodesFromTable(
                selectedEpisodes,
                selectedIndicators,
              )
              $dataTable.draw()
            },
            error: function (xhr, status, error) {
              toastr.error(
                "Failed to delete numerator and denominator hits: " + error,
              )
            },
          })
        }
      } else {
        alert("Please select at least one episode to delete.")
      }
    })

    function gatherSelectedEpisodes() {
      var selectedEpisodes = []
      $(".select-row-checkbox:checked").each(function () {
        selectedEpisodes.push($(this).closest("tr").data("episode-id"))
      })
      return selectedEpisodes
    }

    function gatherSelectedIndicators() {
      var selectedIndicators = []
      $(".select-row-checkbox:checked").each(function () {
        selectedIndicators.push($(this).closest("tr").data("indicator-id"))
      })
      return selectedIndicators
    }

    function removeStatusFromTable(deletedEpisodes, deletedIndicators) {
      deletedEpisodes.forEach(function (episodeId, index) {
        $dataTable
          .rows(function (idx, data, node) {
            return (
              data.id == episodeId &&
              data.indicator_id == deletedIndicators[index]
            )
          })
          .every(function (rowIdx, tableLoop, rowLoop) {
            this.data().status = ""
            this.invalidate()
          })
      })
    }

    function removeDeletedEpisodesFromTable(
      deletedEpisodes,
      deletedIndicators,
    ) {
      deletedEpisodes.forEach(function (episodeId, index) {
        $dataTable
          .rows(function (idx, data, node) {
            return (
              data.id == episodeId &&
              data.indicator_id == deletedIndicators[index]
            )
          })
          .remove()
          .draw()
      })
    }
  })

  window.addEventListener("resize", function () {
    "use strict"
    $dataTable.draw()
  })

  hideDataLoading()
})

export const getIndicatorManagementReport = () => {
  showDataLoading()

  const request = $.ajax({
    type: "GET",
    contentType: "application/json",
    url: "/indicator_management/episodes_list.json",
    data: getIndicatorParams(),
    success: function (result) {
      episodesTable(result)
    },
    error: function (error) {
      hideDataLoading()
      raiseBackendServiceError(error)
    },
  })
  window.pendingAjaxRequests = [request]

  return request
}

export const getIndicatorParams = () => {
  const getValueOrDefault = (selector, defaultValue = ["All"]) =>
    $(selector).val() || defaultValue

  const dateRangeFrom = $("#datepickerSelectFrom").val()
  const dateRangeTo = $("#datepickerSelectTo").val()

  const emptyDateRange = !dateRangeFrom || !dateRangeTo

  const defaultSelects = {
    hospitals: ["All"],
    hospital_groups: ["All"],
    date_range_from: dateRangeFrom,
    date_range_to: dateRangeTo,
    empty_date_range: emptyDateRange,
    indicator_set: $("#indicator_set_select").val()
      ? $("#indicator_set_select").val()
      : "All",
    indicators: $("#indicator_select").val()
      ? $("#indicator_select").val()
      : ["All"],
  }

  const reportTypeSelect = $("#report_type_select").val()

  if (reportTypeSelect === "hospital") {
    defaultSelects.hospitals = getValueOrDefault("#hospitals_select")
  } else if (reportTypeSelect === "hospitalGroup") {
    defaultSelects.hospital_groups = getValueOrDefault(
      "#hospital_groups_select",
    )
  }

  return paramsCompilerService.compileParams(
    defaultSelects,
    reportTypeSelect,
    $("#date_filed_type_select").val(),
  )
}

var initIndicatorManagementReportTable = function (tableId) {
  var default_number_columns = 11
  var columnsParams = new Array(default_number_columns)

  columnsParams = [
    {data: "id", visible: false},
    {data: "indicator_id", visible: false},
    {data: "numerator_hit_id", visible: false},
    {data: "denominator_hit_id", visible: false},
    {data: "hospital"},
    {data: "mrn"},
    {data: "ueid"},
    {data: "drg"},
    {data: "indicator_identifier"},
    {data: "coder_id"},
    {data: "coding_date"},
    {data: "status"},
    {
      data: null,
      defaultContent:
        '<div class="ui checkbox">' +
        '<input type="checkbox" class="select-row-checkbox">' +
        "<label></label>" +
        "</div>",
    },
    {
      data: null,
      defaultContent:
        '<a href="#" data-class="reviewEpisodeLink">' +
        '<button id="reviewEpisode">' +
        "Review" +
        "</button>" +
        "</a>",
    },
  ]

  var $dataTable = $("#" + tableId).DataTable({
    columns: columnsParams,
    columnDefs: [
      {orderable: false, targets: [-1, -2, 1]},
      {width: "0px", targets: [0, 1]},
      {width: "120px", targets: [-2]},
      {
        targets: -1,
        data: null,
        render: function (data) {
          const episodeId = data.id
          return (
            '<td><a href="#" ' +
            'data-url="/episodes/review_episode?episode_id=' +
            episodeId +
            '" ' +
            'class="review-episode" ' +
            'data-episode-id="' +
            episodeId +
            '" ' +
            'onclick="requestReview(event)"><span class="ui basic red button">Review</span></a></td>'
          )
        },
      },
    ],
    createdRow: function (row, data) {
      const episodeId = data.id
      const indicatorId = data.indicator_id
      const numeratorHitId = data.numerator_hit_id
      const denominatorHitId = data.denominator_hit_id

      $(row).attr("data-episode-id", episodeId)
      $(row).attr("data-indicator-id", indicatorId)
      $(row).attr("data-numerator-hit-id", numeratorHitId)
      $(row).attr("data-denominator-hit-id", denominatorHitId)
    },
    dom: '<"toolbar">flrtip',
    language: {
      search: "",
      searchPlaceholder: "Search...",
      sSearch: '<i class="search icon"></i>',
    },
    drawCallback: function () {
      showDataLoading()
    },
  })

  $dataTable.on("draw", function () {
    hideDataLoading()
  })

  return $dataTable
}

// main table
var episodesTable = function (data) {
  if ($dataTable !== undefined) {
    $dataTable.clear()

    var user_defined_filters = window.gon.user_defined_filters

    data.forEach(function (elem) {
      var default_values = {
        id: elem.id,
        indicator_id: elem.indicator_id,
        numerator_hit_id: elem.numerator_hit_id,
        denominator_hit_id: elem.denominator_hit_id,
        hospital: elem.hospital,
        mrn: elem.mrn,
        ueid: elem.ueid,
        drg: elem.drg,
        indicator_identifier: elem.indicator_identifier,
        coder_id: elem.coder_id,
        coding_date: elem.coding_date,
        status: elem.status,
      }

      var user_defined_values = {}
      if (user_defined_filters) {
        user_defined_filters.forEach(function (value) {
          user_defined_values[value] = elem.details ? elem.details[value] : ""
        })
      }

      var end_row_empty_cols = [{}, {}]
      $dataTable.row.add(
        Object.assign(
          {},
          default_values,
          user_defined_values,
          end_row_empty_cols,
        ),
      )
    })

    $dataTable.draw()
  }
}
