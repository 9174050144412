import {
  raiseBackendServiceError,
  getFilter,
  toggleEpisodesView,
  getURLParameter,
  requestReportDataByReportName,
  checkUserRoleIs,
} from "../common/helpers"
import {timePeriodSelectComponent} from "./components/indicator_time_period_select_component"
import {dateRangeComponent} from "./components/date_range_component"
import {
  showDataLoading,
  hideDataLoading,
} from "./components/loading_cirlce_component"
import {dateFiledTypeSelectComponent} from "./components/date_filed_type_select_component"
import {reportsSelectsComponent} from "./components/reports_selects_component"
import {paramsCompilerService} from "./services/params-compiler.service"
import {getSpecificityHitsReport} from "./services/reports-api.service"
import {displayDonutChart} from "./helpers"

var ACTIVE_ELEM

export var compileSpecificityIndicatorsParams = function (params) {
  var mode

  mode = params !== undefined && params.mode ? params.mode : "update"

  var defaultSelects = {
    filter: getFilter(),
    tag: $("#tag_select").val(),
    mode: mode,
    degree: ["R"],
    icd_dis_chap: $("#disease_chapters_select").val(),
  }

  return paramsCompilerService.compileParams(
    defaultSelects,
    $("#report_type_select").val(),
    $("#date_filed_type_select").val(),
  )
}

export var getSpecificityReport = function () {
  var compiledParams = compileSpecificityIndicatorsParams()

  if (compiledParams === false) return

  var specificityHitsRequest = getSpecificityHitsReport(compiledParams)
  var specifictyTableDraw = window.$SPECIFICITY_REPORT_TABLE.ajax.reload()
  var specifictyTableRequest =
    window.$SPECIFICITY_REPORT_TABLE.settings()[0].jqXHR

  window.pendingAjaxRequests = [specificityHitsRequest, specifictyTableRequest]

  showDataLoading()
  $.when(specificityHitsRequest, specifictyTableRequest).then(
    hideDataLoading,
    hideDataLoading,
  )
}

document.addEventListener("DOMContentLoaded", function () {
  if (!document.querySelector(".reports.specificity_report")) return
  timePeriodSelectComponent.initDropdown()
  timePeriodSelectComponent.initEventHandler()
  dateRangeComponent.initDatepicker()
  dateFiledTypeSelectComponent.initDropdown()
  dateFiledTypeSelectComponent.initEventHandler()
  reportsSelectsComponent.initReportsDependentDropdowns()

  // TODO: Vlad - check is this legacy functionality ???
  if (getURLParameter("tag_id")) {
    $("#indicator_time_period_select").dropdown("set selected", "By Extract")
    $("#tag_select").dropdown(
      "set selected",
      parseInt(getURLParameter("tag_id")),
    )
    $("#tag_select_dropdown").show()
  }
  if (getURLParameter("period")) {
    $("#indicator_time_period_select").dropdown(
      "set selected",
      parseInt(getURLParameter("period")),
    )
  }

  $("#before_filter_button").click(function () {
    $("#before_filter_button").removeClass("basic")
    $("#after_filter_button").addClass("basic")
    getSpecificityReport()
  })
  $("#after_filter_button").click(function () {
    $("#after_filter_button").removeClass("basic")
    $("#before_filter_button").addClass("basic")
    getSpecificityReport()
  })

  var separateTable = $("#separateTableWrapper")
  $(".reports.specificity_report").append(separateTable)

  initSpecificityReportTable("episodes_by_indicator")
  getSpecificityReport()
})

var initSpecificityReportTable = function (tableIdString) {
  var _drawCounterEcho

  if (tableIdString === undefined) return

  var $dataTable = $("#" + tableIdString).DataTable({
    dom: '<"toolbar">flrtip',
    order: [[0, "desc"]],
    columnDefs: [{orderable: false, targets: [0, 8]}],
    language: {
      searchPlaceholder: "Identifier, Degree, Description...",
    },
    paging: true,
    page: 1,
    bAutoWidth: false,
    serverSide: true,
    lengthMenu: [
      [10, 25, 50, 100],
      [10, 25, 50, 100],
    ],
    deferLoading: 0,
    ajax: function (d, callback) {
      var data = d
      _drawCounterEcho = data.draw
      data.page =
        $("#" + tableIdString)
          .DataTable()
          .page() + 1
      data.query = data.search.value
      data.direction = data.order[0]["dir"]
      data.limit = data.length

      if (window.$SPECIFICITY_REPORT_TABLE !== undefined) {
        data.sort = window.$SPECIFICITY_REPORT_TABLE.columns().header()[
          data.order[0]["column"]
        ].dataset.sortValue
      }

      delete data.draw
      delete data.columns
      delete data.order
      delete data.search
      delete data.start
      delete data.length

      data = Object.assign(data, compileSpecificityIndicatorsParams())

      toggleEpisodesView("down")

      var request = $.ajax({
        url: "/reports/load_indicators.json",
        type: "GET",
        data: data,
        success: function (data) {
          callback({
            draw: _drawCounterEcho,
            data: data.data.indicators,
            recordsTotal: data.recordsTotal,
            recordsFiltered: data.recordsTotal,
          })

          $("#episodes_by_indicator").find("tfoot").remove()

          if (
            data.data.total.numerator === 0 &&
            data.data.total.denominator === 0
          )
            return

          $("#episodes_by_indicator").append(
            "<tfoot> " +
              "<tr class='subtotals'>" +
              "<td class='subtotals' </td>" +
              "<td class='subtotals' </td>" +
              "<td class='subtotals' </td>" +
              "<td class='subtotals' </td>" +
              "<td class='subtotals' </td>" +
              "<td class='subtotals font-weight-600' >" +
              data.data.total.numerator +
              "</td>" +
              "<td class='subtotals font-weight-600' >" +
              data.data.total.denominator +
              "</td>" +
              "<td class='subtotals font-weight-600' >" +
              data.data.total.ratio +
              "</td>" +
              "<td class='subtotals'></td>" +
              "</tr>" +
              "</tfoot>",
          )
        },
        error: function (error) {
          hideDataLoading()
          raiseBackendServiceError(error)
        },
      })
      return request
    },
    columns: [
      {data: "identifier"},
      {data: "identifier"},
      {data: "degree"},
      {data: "icd_dis_chap"},
      {data: "description"},
      {data: "num_count"},
      {data: "den_count"},
      {data: "ratio"},
      {
        data: null,
        defaultContent:
          "<button class='ui basic red button hidden-for-user-role-7 hidden-for-user-role-8'>Show episodes</button>",
      },
    ],
    createdRow: function (row, data) {
      $("td", row)
        .eq(0)
        .attr("data-row-id", data["identifier"])
        .css("color", "transparent")
        .attr("data-error-type", data["degree"])
      $("td", row)
        .eq(4)
        .attr("data-row-id", data["identifier"])
        .addClass("table-description")
    },
  })

  if ($("#table_actions") !== undefined) {
    $("div.toolbar").html($("#table_actions"))
  }

  $("#" + tableIdString + " tbody").on("click", "button", function (e) {
    var data = $dataTable.row($(this).parents("tr"))

    var paramsForRequest = {
      filter: getFilter(),
      event: e,
      tableSelector: "table#episodes_by_indicator",
      id: $(data.selector.rows[0]).find("[data-row-id]").data("rowId"),
      degree: $(data.selector.rows[0])
        .find("[data-error-type]")
        .data("errorType"),
    }

    window.$SPECIFICITY_REPORT_TABLE.childTable = initSpecificityChildTable(
      "episodesList",
      paramsForRequest,
    )
  })

  window.$SPECIFICITY_REPORT_TABLE = $dataTable
}

function initSpecificityChildTable(tableIdString, params) {
  if (tableIdString === undefined) return

  var degreesToReview

  var $dataTable = $("#" + tableIdString).DataTable({
    dom: '<"toolbar">rtip',
    destroy: true,
    paging: false,
    info: false,
    columnDefs: [
      {orderable: false, targets: [-1]},
      {type: "date-euro", targets: [3]},
      {type: "custom", targets: [1, 2]},
      {
        targets: -1,
        data: null,
        render: function (data) {
          return (
            "<td> <a" +
            ' href="#" data-url="/episodes/review_episode?episode_id=' +
            data.id +
            "&indicator_category_id=" +
            window.gon.indicator_category_id +
            "&degrees=" +
            degreesToReview +
            '" ' +
            ' class="review-episode" onclick="requestReview(event)"><span class="ui basic red button">Review</span></a> </td>'
          )
        },
      },
    ],
    columns: [
      {data: "episode_details.hospital"},
      {data: "pii_data.mrn"},
      {data: "pii_data.ueid"},
      {data: "pii_data.dos"},
      {data: "episode_details.sex"},
      {data: "episode_details.drg"},
      {data: "episode_details.d01"},
      {data: "episode_details.p01"},
      {data: "episode_details.coder_id"},
      {data: null},
    ],
    drawCallback: function () {
      showDataLoading()
    },
    ajax: function (data, callback) {
      showDataLoading()

      if (checkUserRoleIs("standard_summary_only")) return false

      if ($(event.target)[0].localName === "button") {
        var targetElementParentTable =
          event.target.parentElement.parentElement.parentElement
      }

      if (ACTIVE_ELEM !== undefined) {
        $(ACTIVE_ELEM).removeClass("active")
      }
      ACTIVE_ELEM = $("[data-row-id=" + params.id + "]").parent()
      ACTIVE_ELEM.addClass("active")
      toggleEpisodesView("up")

      var defaultSelects = {
        coder: $("#coder_select").val(),
        hospital: $("#hospital_select").val(),
        tag: $("#tag_select").val(),
      }

      if (params.filter !== undefined) {
        defaultSelects.filter = params.filter
      }

      var compiledParams = paramsCompilerService.compileParams(
        defaultSelects,
        $("#report_type_select").val(),
        $("#date_filed_type_select").val(),
      )

      if (compiledParams === false) {
        return
      }

      compiledParams = Object.assign(compiledParams, data)
      compiledParams.indicator_identifier = params.id
      compiledParams.indicator_degree = params.degree

      $.ajax({
        url: "/reports/list_episodes_for_indicator.json",
        type: "GET",
        data: compiledParams,
        success: function (data) {
          degreesToReview =
            data.data.indicator.degree == "R" ? ["R"] : ["F", "W1", "W2"]
          callback({
            data: data.data.episodes,
          })
        },
        error: function (error) {
          hideDataLoading()
          raiseBackendServiceError(error)
        },
      })
    },
  })

  $dataTable.on("draw", function () {
    hideDataLoading()
  })

  return $dataTable
}
