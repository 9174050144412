import { initDataTable, toggleProfileCoderIdField } from "./common/helpers"



function toggleSubmitProfileButton(updateLocked) {
  if (updateLocked === true) {
    $("#updateProfileSubmitButton").hide();
    $("#updateProfileSubmitButtonDisabled").show();
  } else {
    $("#updateProfileSubmitButton").show();
    $("#updateProfileSubmitButtonDisabled").hide();
  }
}

function processCoderIdUpdate() {
  var editProfileModal = $("#editProfileModal");
  var editProfileModalForm = $("#editProfileModal form");
  var submitEditProfileFormBtn = $("#updateProfileSubmitButton");

  var coderIdInput = $("#editProfileModal [data-name=profileCoderIdInput]");

  var updateRelatedRecordsDialogModal = $("#updateCoderIdModal");
  var updateRelatedRecordsBtns = $("[data-name='update-related-records']");
  var hiddenInputState = $("#profile_update_related_records");

  function isCoderIdRequiredForSelectedRole() {
    return !coderIdInput.is(":hidden");
  }

  function isCoderIdPresent() {
    return coderIdInput.val().length > 0;
  }

  function coderIdChanged(storedCoderId) {
    return (
      isCoderIdPresent() &&
      coderIdInput.val() &&
      coderIdInput.val() !== storedCoderId
    );
  }

  submitEditProfileFormBtn.on("click", function(e) {
    var storedCoderId = coderIdInput.data("storedCoderId");

    if (
      isCoderIdRequiredForSelectedRole() &&
      coderIdChanged(storedCoderId) &&
      storedCoderId
    ) {
      $(".profileCoderIdStoredValue").text(storedCoderId);
      $(".profileCoderIdNewValue").text(coderIdInput.val());

      editProfileModal.modal("hide");
      updateRelatedRecordsDialogModal.modal("show");

      updateRelatedRecordsBtns.on("click", function(e) {
        hiddenInputState.val($(this).data("value"));
        editProfileModalForm.submit();
      });
    } else {
      hiddenInputState.val(false);
      editProfileModalForm.submit();
    }
  });
}

export function toggleProfileModal(modalId) {
  $(modalId)
    .modal({ autofocus: false })
    .modal("show");
  $("[data-trigger='dropdown']").dropdown();

  toggleProfileCoderIdField(modalId);

  $(modalId + " [data-name=profileRoleIdInput]")
    .off()
    .on("change", function() {
      toggleProfileCoderIdField(modalId);
    });
}

function prepareEditProfilePath(profileId, userId) {
  return "/users/" + userId + "/profiles/" + profileId + "/edit.json";
}

function addOptionsForSelect(selectId, options) {
  var $select = $("#editProfileModal " + selectId);

  $select.empty();

  $.each(options, function(_key, object) {
    $select.append(
      $("<option></option>")
        .attr("value", object.value)
        .attr("selected", object.selected)
        .text(object.text)
    );
  });
}

function populateCoderIdInput(coderId) {
  $("#editProfileModal [data-name=profileCoderIdInput]").attr("value", coderId);
}

function updateFormUrl(profileId) {
  var $editProfileModal = $("#editProfileModal form");
  var currentUrl = $editProfileModal.attr("action");
  var regexp = new RegExp(
    currentUrl.substr(currentUrl.lastIndexOf("/") + 1) + "$"
  );
  var newUrl = currentUrl.replace(regexp, profileId);

  $editProfileModal.attr("action", newUrl);
}

export function toggleEditProfileModal(element) {
  $.ajax({
    type: "GET",
    contentType: "application/json",
    url: prepareEditProfilePath(
      element.dataset.profileId,
      element.dataset.userId
    ),
    success: function(result) {
      updateFormUrl(element.dataset.profileId);
      addOptionsForSelect(
        "#profile_organisation_id",
        result.organisationsForSelect
      );
      addOptionsForSelect("#profile_role_id", result.rolesForSelect);
      populateCoderIdInput(result.profile.coderId);
      toggleSubmitProfileButton(result.profile.updateLocked);
      toggleProfileModal("#editProfileModal");
      $("#editProfileModal [data-name=profileCoderIdInput]").attr(
        "data-stored-coder-id",
        result.profile.coderId
      );
    }
  });
}

function resetTableRecords() {
  var profilesStatusTable = $("#profilesStatusTable").DataTable();

  profilesStatusTable.columns().search("").draw();
}

document.addEventListener("DOMContentLoaded", function() {
  if (!document.querySelector(".profiles.index, .users.admin_edit")) return;

  initDataTable({ tableId: "#profilesTable", orderVal: 1, targetsVal: [0, 4] });
  processCoderIdUpdate();

  $(".menu .item").tab();

  // Check if the DataTable has already been initialized
  initDataTable({
    tableId: "#profilesStatusTable",
    orderVal: 1,
    sortingDirection: "asc",
    targetsVal: [0, 11]
  });
  

  // Add event listener to filter dropdown change
  $("#organizationFilterDropdown").dropdown();

  // Attach the change event listener
  $("#organizationFilterDropdown").dropdown({
    onChange: function(value, text, $choice) {
      if (value === "Organisation") {
        // Reset option selected
        resetTableRecords(); // Call the function to reset the table records
      } else {
        var selectedOrganizationId = value;
  
        var profilesStatusTable = $("#profilesStatusTable").DataTable();
  
        profilesStatusTable.column(5).search(selectedOrganizationId).draw();
        
      }
    }
  });
  $("#parentOrganizationFilterDropdown").dropdown();
  // Add event listener to parent organization filter dropdown change
  $("#parentOrganizationFilterDropdown").dropdown({
    onChange: function(value, text, $choice) {
      if (value === "") {
        resetTableRecords();
      } else {
        $.ajax({
          url: "/profiles/filter_organizations",
          method: "GET",
          data: { selected_organization_id: value },
          success: function(data) {
    
            // Extract organization IDs from the data
            var filteredOrgIds = data;
    
            if (value !== "") {
              // If a parent organization is selected, add its ID to the list
              filteredOrgIds.push(parseInt(value));
            }
    
            var profilesStatusTable = $("#profilesStatusTable").DataTable();
    
            // Clear the existing search input and use the global search to filter based on organization IDs
            profilesStatusTable.search("").draw();
    
            // Convert the array of organization IDs to a pipe-separated string
            var orgIdsString = filteredOrgIds.join("|");
    
            // Use regex to match the IDs in the Org ID column (12th column)
            profilesStatusTable.column(12).search("^(" + orgIdsString + ")$", true, false);
    
            profilesStatusTable.draw();
          }
        });
      }
    }
  });
  
  // Event listener to the download button
  $('#downloadButton').on('click', function() {
    downloadReport();
  });

  function downloadReport() {
    var profilesStatusTable = $("#profilesStatusTable").DataTable();
  
    // Get the filtered data
    var filteredData = profilesStatusTable.rows({ search: "applied" }).data().toArray();
  
    // Remove the first and last columns
    var modifiedData = filteredData.map(function(row) {
      var modifiedRow = row.slice(1, -2); // Remove first and last 2 columns
      // Convert dates to local time
      modifiedRow[6] = convertToLocalTime(modifiedRow[6]);
      modifiedRow[7] = convertToLocalTime(modifiedRow[7]); // Last Updated at
      if (modifiedRow[8] !== "Never") {
        modifiedRow[8] = convertToLocalTime(modifiedRow[8]); // Last Login at
      }

      if (modifiedRow[3].includes("teal")) {
        modifiedRow[3] = "Confirmed"; // Modify the fourth column
      } else {
        modifiedRow[3] = "Not Confirmed"; // Modify the fourth column
      }
      return modifiedRow;
    });
  
    // Create custom column titles
    var columnTitles = ["Full Name", "Role", "Email", "Confirmed", "Organisation", "Coder ID", "Created at", "Last Updated at", "Last Login at", "Login Count"];
  
    // Create a new workbook
    var wb = XLSX.utils.book_new();
  
    // Create a worksheet
    var ws = XLSX.utils.aoa_to_sheet([columnTitles].concat(modifiedData));
  
    // Add bold font style to column headers
    var boldCellStyle = { font: { bold: true } };
    ws['!rows'] = [{}, boldCellStyle]; // Empty row with bold font style for column headers
  
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Profiles');
  
    // Save the workbook as an Excel file
    XLSX.writeFile(wb, 'profiles_report.xlsx');
  }
  function convertToLocalTime(dateString) {
    const dateTimeParts = dateString.split(' ');
    const datePart = dateTimeParts[0];
    const timePart = dateTimeParts[1];
    
    const dateSegments = datePart.split('/');
    const year = parseInt(dateSegments[0]);
    const month = parseInt(dateSegments[1]) - 1; 
    const day = parseInt(dateSegments[2]);
    
    const timeSegments = timePart.split(':');
    const hours = parseInt(timeSegments[0]) + 10; 
    const minutes = parseInt(timeSegments[1]);
    const seconds = parseInt(timeSegments[2]);
    
    const localDate = new Date(year, month, day, hours, minutes, seconds);
    
    // Create a date formatter
    const formatter = new Intl.DateTimeFormat('en-AU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    });
    
    // Format the local date and time
    const formattedLocalTime = formatter.format(localDate);
    
    return formattedLocalTime;
  }
  
  
  
  
  
   
  
  
  
  
});
