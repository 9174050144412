import {dateRangeComponent} from "../components/date_range_component"
import {getIndicatorDegreesSelectValue} from "../helpers"

export var paramsCompilerService = (function () {
  var _filterStrategy = {
    coder: function () {
      return {coder: $("#coder_select").val()}
    },
    clinician: function () {
      var clinicianSelect = _returnClinicianSelectValue()
      return {clinician: clinicianSelect}
    },
    hospital: function () {
      return {hospital: $("#hospital_select").val()}
    },
    hospitalGroup: function () {
      return {hospital_group: $("#hospital_group_select").val()}
    },
    hospitals: function () {
      return {hospitals: $("#hospitals_select").val()}
    },
    hospitalGroups: function () {
      return {hospital_groups: $("#hospital_groups_select").val()}
    },
    coderAndClinician: function () {
      var clinicianSelect = _returnClinicianSelectValue()
      return {coder: $("#coder_select").val(), clinician: clinicianSelect}
    },
    dos: function () {
      return {dos_time_period: $("#indicator_time_period_select").val()}
    },
    coding_date: function () {
      return {coding_date_time_period: $("#indicator_time_period_select").val()}
    },
    indicator_degrees_select: function () {
      return {degree: getIndicatorDegreesSelectValue()}
    },
    fund: function () {
      return {fund: $("#fund_select").val()}
    },
    los: function () {
      return {los: $("#los_select").val()}
    },
    care_type: function () {
      return {care_type: $("#care_type_select").val()}
    },
    mdc: function () {
      return {mdc: $("#mdc_select").val()}
    },
    indicator_set_select: function () {
      return {indicator_set: $("#indicator_set_select").val()}
    },
    indicator_select: function () {
      return {indicator: $("#indicator_select").val()}
    },
  }

  var _returnClinicianSelectValue = function () {
    var clinicianSelectValue = $("#clinician_select")[0].value
    return clinicianSelectValue === "All" ? "" : clinicianSelectValue
  }

  var _getSelectsByFilterStrategy = function (filterValue) {
    if (filterValue && _filterStrategy[filterValue]) {
      return _filterStrategy[filterValue]()
    } else {
      console.error("Invalid filter value:", filterValue)
      return {}
    }
  }

  var compileParams = function (defaultSelectsObj) {
    var resultSelectsObj = {}
    var selectsByFilterStrategy = {}

    // TODO: write unit tests!
    if (arguments.length > 1) {
      for (var i = 1; i < arguments.length; i++) {
        selectsByFilterStrategy = $.extend(
          selectsByFilterStrategy,
          _getSelectsByFilterStrategy(arguments[i]),
        )
      }
    }

    resultSelectsObj = $.extend(
      defaultSelectsObj,
      selectsByFilterStrategy,
      _commonRequiredParams(),
    )

    if (dateRangeComponent.checkTimePeriodParams(resultSelectsObj)) {
      return dateRangeComponent.changeParamsByTimePeriod(resultSelectsObj)
    } else {
      return false
    }
  }

  var _commonRequiredParams = function () {
    return {
      date_field_type: $("#date_filed_type_select").val(),
      degree: getIndicatorDegreesSelectValue(),
    }
  }

  return {
    compileParams: compileParams,
  }
})()
