import { optionsManagerService } from "../../reports/services/options-manager.service";
import LocalStorageService from "./local-storage.service";

var authenticationService = (function() {
  var _processLogout = function() {
    var signOutUrl = window.location.origin + "/user_session/sign_out";
    $.ajax({
      url: signOutUrl,
      method: "get",
      success: function() {
        location.reload();
      }
    });
  };

  var saveLastUserId = function() {
    var localStorage = new LocalStorageService();
    var lastIdObj = {
      key: "lastId",
      value: $("[data-user-id]").data("userId")
    };
    localStorage.setItem(lastIdObj);
  };

  var saveLastUserRole = function() {
    var localStorage = new LocalStorageService();
    var lastRoleObj = {
      key: "lastRole",
      value: $("[data-user-role]").data("userRole")
    };
    localStorage.setItem(lastRoleObj);
  };

  var onLogout = function() {
    saveLastUserId();
    saveLastUserRole();
    _processLogout();
  };

  var cleanSavedFromStorage = function() {
    var selectsKeys = Object.keys(optionsManagerService.selectsStateStorage);
    for (var i = 0; i < selectsKeys.length; i++) {
      localStorage.removeItem(selectsKeys[i]);
    }
    localStorage.removeItem("dontShowUnsupported");
  };

  // Add on new user first login + on impersonate login
  var onLogin = function() {
    cleanSavedFromStorage();
  };

  var checkIsUserImpersonated = function() {
    return $("[data-user-impersonated]").data("userImpersonated");
  };

  return {
    onLogin: onLogin,
    onLogout: onLogout,
    saveLastUserId: saveLastUserId,
    cleanSavedFromStorage: cleanSavedFromStorage,
    checkIsUserImpersonated: checkIsUserImpersonated
  };
})();

export default authenticationService;
