import { optionsManagerService } from "../services/options-manager.service";
import { dateRangeComponent } from "./date_range_component";
import { getURLParameter } from "../../common/helpers";

export var timePeriodSelectComponent = (function() {
  function checkIsTimePeriodByExtract() {
    if (
      $("#indicator_time_period_select") !== undefined &&
      $("#indicator_time_period_select").val() == "By Extract"
    ) {
      $("#tag_select_dropdown").show();
      $("#dateFieldTypeSelectWrapper").hide();
    } else {
      $("#tag_select_dropdown").hide();
      $("#dateFieldTypeSelectWrapper").show();
    }
  }

  var initDropdown = function() {
    var extractFromUrl = getURLParameter("tag_id");

    optionsManagerService.setActiveSavedOption(
      "indicator_time_period_select",
      "indicator_time_period_select",
      "7"
    );

    if (extractFromUrl) {
      optionsManagerService.processSavingChangedOption(
        "tag_select",
        extractFromUrl
      );
      optionsManagerService.processSavingChangedOption(
        "indicator_time_period_select",
        "By Extract"
      );

      optionsManagerService.setActiveSavedOption(
        "indicator_time_period_select",
        "indicator_time_period_select",
        "By Extract"
      );

      $("#indicator_time_period_select").val("By Extract");
      $("#tag_select").val(extractFromUrl);
    }
    dateRangeComponent.showHideSelectsByTimePeriod();
    $("#indicator_time_period_select").dropdown();
  };

  var initEventHandler = function() {
    var $indicator_time_period_select = $("#indicator_time_period_select");

    $indicator_time_period_select.on("focusin", function(e) {
      var targetId = e.currentTarget.id;
      optionsManagerService.selectsStateStorage[targetId].oldValue =
        e.currentTarget.value;
    });

    $indicator_time_period_select.off("change").on("change", function(e) {
      var targetId = e.currentTarget.id;
      if (
        optionsManagerService.selectsStateStorage[targetId].oldValue !==
        e.currentTarget.value
      ) {
        optionsManagerService.selectsStateStorage[targetId].newValue =
          e.currentTarget.value;
        optionsManagerService.selectsStateStorage[
          targetId
        ].optionIsChanged = true;
      }

      if (optionsManagerService.checkOptionIsChanged(targetId)) {
        optionsManagerService.processSavingChangedOption(
          targetId,
          optionsManagerService.selectsStateStorage[targetId].newValue
        );
      }

      setTimeout(function() {
        dateRangeComponent.showHideSelectsByTimePeriod();
      }, 0);

      return false;
    });
  };

  return {
    initDropdown: initDropdown,
    initEventHandler: initEventHandler,
    checkIsTimePeriodByExtract: checkIsTimePeriodByExtract
  };
})();
