import {getFilter, checkUserRoleIs} from "../common/helpers"
import {sendRequestToGenerateExcel} from "./services/reports-api.service"
import {paramsCompilerService} from "./services/params-compiler.service"
import {selectsStorageService} from "./services/selects-storage.service"
import {
  getErrorHitsReport,
  getDashboardBenchmarkReport,
} from "./services/reports-api.service"
import {
  showDataLoading,
  hideDataLoading,
} from "./components/loading_cirlce_component"
import {initReiviewEpisode} from "../episodes"

export var getIndicatorDegreesSelectValue = function () {
  if (document.querySelector(".reports.risq_indicators_by_episode")) {
    return $("#risq_indicator_degrees_select").val()
  } else {
    return $("#indicator_degrees_select").val()
  }
}

export var compileDashboardParams = function (params) {
  var mode, indicatorCategoryId
  mode = params !== undefined && params.mode ? params.mode : "update"
  indicatorCategoryId = -1
  if (params !== undefined && params.indicatorCategoryId) {
    indicatorCategoryId = params.indicatorCategoryId
  } else if (window.gon.indicator_category_id) {
    indicatorCategoryId = window.gon.indicator_category_id
  }

  var defaultSelects = {
    filter: getFilter(),
    benchmark: $("#benchmark_type_select").val(),
    tag: $("#tag_select").val(),
    width: 900,
    height: 400,
    mode: mode,
    indicator_category_id: indicatorCategoryId,
  }
  return paramsCompilerService.compileParams(
    defaultSelects,
    $("#report_type_select").val(),
    $("#date_filed_type_select").val(),
  )
}

export var getDashboardReports = function (filter, mode) {
  var params = {filter: filter, mode: mode}

  var compiledParams = compileDashboardParams(params)

  if (compiledParams === false) return

  var errorHitsRequest = getErrorHitsReport(compiledParams)
  var dashboardBenchmarkRequest = getDashboardBenchmarkReport(compiledParams)

  window.pendingAjaxRequests = [errorHitsRequest, dashboardBenchmarkRequest]

  showDataLoading()
  $.when(errorHitsRequest, dashboardBenchmarkRequest).then(
    hideDataLoading,
    hideDataLoading,
  )
}

export var initDashboardDropdowns = function () {
  $("#benchmark_type_select, #indicator_degrees_select").dropdown()
}

export var initDashboardSelectsHandlers = function () {
  $("#benchmark_type_select").change(function () {
    getDashboardBenchmarkReport(compileDashboardParams())
  })

  $("#before_filter_button").click(function () {
    $("#before_filter_button").removeClass("basic")
    $("#after_filter_button").addClass("basic")
    getDashboardReports("after", "update")
  })

  $("#after_filter_button").click(function () {
    $("#after_filter_button").removeClass("basic")
    $("#before_filter_button").addClass("basic")
    getDashboardReports("before", "update")
  })

  $("a#summary_report_download_excel_link").click(function (e) {
    e.preventDefault()

    var defaultSelects = {
      filter: getFilter(),
      coder: $("#coder_select").val(),
      hospital: $("#hospital_select").val(),
      tag: $("#tag_select").val(),
      indicator_category_id: window.gon.indicator_category_id,
    }

    var compiledParams = paramsCompilerService.compileParams(
      defaultSelects,
      $("#report_type_select").val(),
      $("#date_filed_type_select").val(),
    )

    if (compiledParams === false) {
      return
    }

    sendRequestToGenerateExcel($(this).attr("href"), compiledParams)
  })
}

export var displayPie = function (data, chart, color, index) {
  var data = [data]

  var width = 150,
    height = 150,
    radius = Math.min(width, height) / 2

  var color = d3.scaleOrdinal().range([color])

  var pie = d3.pie().value(function (d) {
    return 1
  })(data)

  var arc = d3
    .arc()
    .outerRadius(radius - 10)
    .innerRadius(0)

  var valueArc = d3
    .arc()
    .outerRadius(radius - 75)
    .innerRadius(radius - 75)

  var labelArc = d3
    .arc()
    .outerRadius(radius - 30)
    .innerRadius(radius - 30)

  var svg = d3
    .select(chart)
    .append("svg")
    .attr("width", width)
    .attr("height", height)
    .attr("class", "pie_" + index)
    .append("g")
    .attr("id", chart)
    .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")")

  var g = svg
    .selectAll("arc")
    .data(pie)
    .enter()
    .append("g")
    .attr("class", "arc")

  g.append("path")
    .attr("d", arc)
    .style("fill", function (d) {
      return color(d.data.label)
    })

  g.append("text")
    .attr("transform", function (d) {
      return "translate(" + valueArc.centroid(d) + ")"
    })
    .attr("text-anchor", "middle")
    .attr("font-weight", "bold")
    .attr("font-size", "25px")
    .text(function (d) {
      return d.data.value
    })
    .style("fill", "#fff")

  g.append("text")
    .attr("transform", function (d) {
      return "translate(" + labelArc.centroid(d) + ")"
    })
    .attr("text-anchor", "middle")
    .text(function (d) {
      return d.data.label
    })
    .style("fill", "#fff")
}

export var updatePie = function (data, chart) {
  var data = [data]

  d3.select(chart)
    .select("text")
    .data(data)
    .attr("text-anchor", "middle")
    .attr("font-weight", "bold")
    .text(function (d) {
      return d.value
    })
    .style("fill", "#fff")
}

export var displayPieCharts = function (result, mode) {
  $.each(result.numerator_data, function (i, ele) {
    if (mode == "display") {
      displayPie(ele, ele.chart, ele.color, i + 1)
    } else {
      updatePie(ele, ele.chart)
    }
  })
}

export var displayBarChart = function (data, width, height) {
  width = $("#benchmark_graph").width()
  $(".bar-chart").width("100%")
  var margin = {top: 20, right: 40, bottom: 30, left: 40},
    width = width - margin.left - margin.right,
    height = height - margin.top - margin.bottom

  var x0 = d3.scaleBand().rangeRound([0, width]).paddingInner(0.1)

  var x1 = d3.scaleBand().padding(0.05)

  var y = d3.scaleLinear().rangeRound([height, 0])

  var color = d3.scaleOrdinal().range(["#D64423", "#ED6C1F", "#FED530"])

  var xAxis = d3.axisBottom().scale(x0)

  var yAxis = d3.axisLeft().scale(y).ticks(4).tickFormat(d3.format("d"))

  var divTooltip = d3.select("body").append("div").attr("class", "toolTip")

  var svg = d3
    .select("#benchmark_graph")
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .attr("class", "bar_chart")
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

  var options = d3.keys(data[0]).filter(function (key) {
    return key !== "label" && key !== "chapterTitle"
  })

  data.forEach(function (d) {
    d.valores = options.map(function (name) {
      return {name: name, value: +d[name]}
    })
  })

  x0.domain(
    data.map(function (d) {
      return d.label
    }),
  )
  x1.domain(options).rangeRound([0, x0.bandwidth()])
  y.domain([
    0,
    d3.max(data, function (d) {
      var minimumUpperGraphBound = 3
      return d3.max(d.valores, function (d) {
        return d.value <= minimumUpperGraphBound
          ? minimumUpperGraphBound
          : d.value
      })
    }),
  ])

  svg
    .append("g")
    .attr("class", "x axis")
    .attr("transform", "translate(0," + height + ")")
    .call(xAxis)

  svg
    .append("g")
    .attr("class", "y axis")
    .call(yAxis)
    .append("text")
    .attr("transform", "rotate(-90)")
    .attr("y", 6)
    .attr("dy", ".71em")
    .style("text-anchor", "end")

  var bar = svg
    .selectAll(".bar")
    .data(data)
    .enter()
    .append("g")
    .attr("class", "rect")
    .attr("transform", function (d) {
      return "translate(" + x0(d.label) + ",0)"
    })

  bar
    .selectAll("rect")
    .data(function (d) {
      return d.valores
    })
    .enter()
    .append("rect")
    .attr("width", x1.bandwidth())
    .attr("x", function (d) {
      return x1(d.name)
    })
    .attr("y", function (d) {
      if (!isNaN(d.value)) {
        return y(d.value)
      }
    })
    .attr("value", function (d) {
      return d.name
    })
    .attr("height", function (d) {
      if (!isNaN(d.value)) {
        return height - y(d.value)
      }
    })
    .style("fill", function (d) {
      if (d.name !== "chapterTitle") return color(d.name)
    })

  bar.on("mousemove", function (d) {
    divTooltip.style("left", d3.event.pageX + 10 + "px")
    divTooltip.style("top", d3.event.pageY - 25 + "px")
    divTooltip.style("display", "inline-block")
    var x = d3.event.pageX,
      y = d3.event.pageY
    var elements = document.querySelectorAll(":hover")
    var elementData = elements[elements.length - 1].__data__

    if (d.chapterTitle) {
      divTooltip.html(
        d.label +
          "(" +
          elementData.name +
          ")<br>" +
          "Amount:" +
          elementData.value +
          "<br>" +
          d.chapterTitle,
      )
    } else {
      divTooltip.html(
        d.label +
          "(" +
          elementData.name +
          ")<br>" +
          "Amount:" +
          elementData.value,
      )
    }
  })

  bar.on("mouseout", function (d) {
    divTooltip.style("display", "none")
  })

  bar.on("click", function (d) {
    if (checkUserRoleIs("coder")) return
    var elements = document.querySelectorAll(":hover")
    var elementData = elements[elements.length - 1].__data__
    var storageKey = document.querySelector(".reports.risq_dashboard")
      ? "risq_indicator_degrees_select"
      : "indicator_degrees_select"
    var item = {
      key: storageKey,
      value: elementData.name,
    }
    selectsStorageService.removeSelectFromStorage("disease_chapters_select")
    selectsStorageService.removeSelectFromStorage("procedure_chapters_select")
    selectsStorageService.pushSelectToStorage(item)
    if ($("#benchmark_type_select").val() == "icd_chapter") {
      var disease = {
        key: "disease_chapters_select",
        value: d.label,
      }
      selectsStorageService.pushSelectToStorage(disease)
    }
    if ($("#benchmark_type_select").val() == "achi_chapter") {
      var procedure = {
        key: "procedure_chapters_select",
        value: d.label,
      }
      selectsStorageService.pushSelectToStorage(procedure)
    }
    var hideReviewed = $("#before_filter_button").hasClass("basic")

    selectsStorageService.pushSelectToStorage({
      key: "before_filter",
      value: getFilter(),
    })

    window.location.href = document.querySelector(".reports.risq_dashboard")
      ? `/reports/risq_episodes_by_indicator?hide_reviewed=${hideReviewed}`
      : `/reports/episodes_by_indicator?hide_reviewed=${hideReviewed}`
  })

  d3.select("#graphLabelsWrapper")
    .selectAll("p")
    .data(options)
    .enter()
    .append("p")
    .text(function (d, i) {
      return d
    })
    .style("background", color)
    .attr("class", "five wide column")

  $(".y.axis .tick").each(function () {
    var line = $(this).find("line")
    $(line)
      .append("<g></g>")
      .append("<line></line>")
      .attr("x2", width)
      .css("stroke", "#bccad2")
      .css("stroke-width", "2px")
  })
}

export var displayDonutChart = function (result, chart, mode) {
  var div = d3.select("body").append("div").attr("class", "toolTip")
  var dataset = result.data
  var margin = {top: 0, right: 0, bottom: 0, left: 0}

  var width = 220 - margin.left - margin.right,
    height = 220 - margin.top - margin.bottom,
    radius = Math.min(width, height) / 2

  var color = d3.scaleOrdinal().range(result.color)

  var arc = d3
    .arc()
    .outerRadius(radius - 10)
    .innerRadius(radius - 50)

  var labelArc = d3
    .arc()
    .outerRadius(radius - 30)
    .innerRadius(radius - 30)

  var pie = d3
    .pie()
    .sort(null)
    .startAngle(1.1 * Math.PI)
    .endAngle(3.1 * Math.PI)
    .value(function (d) {
      if (result.data.length > 1) {
        return d.value
      } else {
        return 1
      }
    })

  var svg = d3
    .select(chart)
    .append("svg")
    .attr("width", width)
    .attr("height", height)
    .style("border", "1px solid grey")
    .style("border-radius", "100%")
    .style("margin", "0 auto")
    .style("display", "block")
    .append("g")
    .attr("transform", "translate(" + 109 + "," + 109 + ")")

  var g = svg
    .selectAll(".arc")
    .data(pie(dataset))
    .enter()
    .append("g")
    .attr("class", "arc")
    .style("font-size", "18px")

  if (mode == "display") {
    g.append("path")
      .style("fill", function (d) {
        return color(d.data.name)
      })
      .transition()
      .delay(function (d, i) {
        return i * 500
      })
      .duration(500)
      .attrTween("d", function (d) {
        var i = d3.interpolate(d.startAngle + 0.1, d.endAngle)
        return function (t) {
          d.endAngle = i(t)
          return arc(d)
        }
      })
  } else {
    g.append("path")
      .style("fill", function (d) {
        return color(d.data.name)
      })
      .transition()
      .duration(1000)
      .attrTween("d", function (d) {
        var i = d3.interpolate(this._current, d)
        this._current = i(0)
        return function (t) {
          return arc(i(t))
        }
      })
  }

  var innerCircle = g
    .append("g")
    .attr("class", "inner-circle")
    .append("circle")
    .attr("r", "50")
    .style("fill", "transparent")
    .style("stroke", "grey")

  var label_text = svg
    .append("text")
    .style("fill", "#00B9F8")
    .attr("font-weight", "bold")
    .attr("font-size", "40px")
    .attr("transform", "translate(5, 10)")
    .transition()

  var value_text = g
    .append("text")
    .attr("transform", function (d) {
      return "translate(" + arc.centroid(d) + ")"
    })
    .style("fill", "#FFF")
    .attr("dy", ".35em")
    .attr("font-weight", "bold")
    .style("display", function (d) {
      return d.value === 0 ? "none" : "inline"
    })
    .transition()

  if (mode == "display") {
    label_text = label_text.delay(1000)
    value_text = value_text.delay(1000)
  }

  //if (result.percentage == "No Data") {}
  label_text
    .attr("text-anchor", "middle")
    .text(result.percentage)
    .attr("font-size", "20px")
    .attr("transform", "translate(1,6)")

  value_text.attr("text-anchor", "middle").text(function (d) {
    return d.data.label
  })

  d3.selectAll("path").on("mousemove", function (d) {
    div.style("left", d3.event.pageX + 10 + "px")
    div.style("top", d3.event.pageY - 25 + "px")
    div.style("display", "inline-block")
    div.html(
      d.data.name + "<br>" + d.data.value + "<br>" + d.data.percent + "%",
    )
  })

  $("div[data-d3-tooltip-listener]").on("mouseout", function (d) {
    div.style("display", "none")
  })
}

export var displayEpisodeIndicators = function (result, mode) {
  $.each(result, function (i, ele) {
    if (mode == "display") {
      displayEpisodeIndicator(ele, ele.chart, ele.color)
    } else {
      displayEpisodeIndicator(ele, ele.chart, ele.color)
    }
  })
}

export var displayEpisodeIndicator = function (data, chart, color) {
  // This is charts which displays numerator_data from report.rb config instead of overloaded dynamic generated svg's -->
  $(data.chart + " .chart-name").text(data.label)
  $(data.chart + " .chart-value").text(data.value)
}

// TODO: triggered in specificity report - fix.
export var displayDonutCharts = function (result, mode) {
  if (mode == "display") {
    displayDonutChart(result.compliance_data, "#compliance_chart", mode)
    displayDonutChart(result.specificity_data, "#specificity_chart", mode)
  } else {
    d3.select("#compliance_chart").selectAll("*").remove()
    d3.select("#specificity_chart").selectAll("*").remove()

    displayDonutChart(result.compliance_data, "#compliance_chart", mode)
    displayDonutChart(result.specificity_data, "#specificity_chart", mode)
  }
}

export function displaySpecificityDonutChart(result, mode) {
  if (mode != "display") {
    d3.select("#specificity_chart").selectAll("*").remove()
  }
  displayDonutChart(result.specificity_data, "#specificity_chart", mode)
}

function reviewEpisodeListener(event) {
  event.preventDefault()

  var dataset = event.currentTarget.dataset
  var currentElement = $(event.currentTarget)
  var nextEpisodeButton = $("#nextEpisode")
  var previousEpisodeButton = $("#previousEpisode")

  nextEpisodeButton.off("click")
  previousEpisodeButton.off("click")
  currentElement.addClass("loading")
  currentElement.addClass("disabled")

  $.ajax({
    url: "/episodes/review_episode.js",
    type: "GET",
    data: {
      episode_id: dataset.episodeId,
      indicator_category_id: window.gon.indicator_category_id,
    },
    success: function (response) {
      initReiviewEpisode()
      currentElement.removeClass("loading")
      var indicator_category_id = window.gon.indicator_category_id
      var episode_url = `/episodes/review_episode?episode_id=${dataset.episodeId}&indicator_category_id=${window.gon.indicator_category_id}`
      $("#external-episode").wrap(`<a href="${episode_url}" target="_blank"/>`)

      var nextEpisodeId = $(`a[data-episode-id="${dataset.episodeId}"]`).data(
        "nextEpisodeId",
      )
      var previousEpisodeId = $(
        `a[data-episode-id="${dataset.episodeId}"]`,
      ).data("previousEpisodeId")
      if (nextEpisodeId) {
        nextEpisodeButton.removeClass("disabled")
        nextEpisodeButton.attr("data-episode-id", nextEpisodeId)

        nextEpisodeButton.on("click", reviewEpisodeListener)
      } else {
        nextEpisodeButton.addClass("disabled")
      }
      if (previousEpisodeId) {
        previousEpisodeButton.removeClass("disabled")
        previousEpisodeButton.attr("data-episode-id", previousEpisodeId)
        previousEpisodeButton.on("click", reviewEpisodeListener)
      } else {
        previousEpisodeButton.addClass("disabled")
      }

      $("#reviewEpiosdeModal")
        .modal({
          onHidden: function () {
            $("body").removeClass("dimmable scrolling")
          },
        })
        .modal("show")
    },
    error: function (error) {
      currentElement.removeClass("loading")
      toastr.error(error.statusText)
    },
  })
}

export function addReviewEpisodeListeners() {
  document
    .querySelectorAll('[data-class="reviewEpisodeLink"]')
    .forEach(function (element, index, array) {
      element.addEventListener("click", reviewEpisodeListener)

      if (index > 0) {
        element.setAttribute(
          "data-previous-episode-id",
          array[index - 1].getAttribute("data-episode-id"),
        )
      }

      if (index < array.length - 1) {
        element.setAttribute(
          "data-next-episode-id",
          array[index + 1].getAttribute("data-episode-id"),
        )
      }
    })
}

export function removeReviewEpisodeListeners() {
  document
    .querySelectorAll('[data-class="reviewEpisodeLink"]')
    .forEach(function (element) {
      element.removeEventListener("click", reviewEpisodeListener)
    })
}

export function updateRisqIndicatorList() {
  let indicators = document
    .getElementById("risq_hac_indicators_select")
    .parentElement.querySelector(".menu").children
  let indicatorsToArray = Array.from(indicators)
  let degrees = $("#risq_indicator_degrees_select").val()
  let categories = $("#risq_hac_categories_select").val()
  let re_list = []
  if (categories === "" || categories === null) {
    categories = []
  }
  categories = categories.filter(c => c !== "")
  categories.forEach(v => {
    re_list.push(new RegExp(" " + v + "\\.", "g"))
  })
  let show_hacs = false
  let show_rs = false
  if (degrees.includes("HAC")) {
    show_hacs = true
  }
  if (degrees.includes("RS1") || degrees.includes("RS2")) {
    show_rs = true
  }

  indicatorsToArray.forEach(indicator => {
    if (
      ((show_hacs && indicator.textContent.startsWith("HAC")) ||
        (show_rs && indicator.textContent.startsWith("RISQ"))) &&
      (re_list.length === 0 ||
        checkIndicatorAgainstCategories(indicator.textContent, re_list))
    ) {
      indicator.style.display = "block"
    } else {
      indicator.style.display = "none"
    }
  })
  $("#risq_hac_indicators_select").dropdown("clear")
}

function checkIndicatorAgainstCategories(indicator_text, re_list) {
  for (const re of re_list) {
    if (indicator_text.match(re) === null) {
    } else {
      return true
    }
  }
  return false
}
