import LocalStorageService from "../../common/services/local-storage.service";
import authenticationService from "../../common/services/authentication.service";

export var SelectsStorageService = function() {
  this.__proto__ = new LocalStorageService();
  var self = this;

  var config = {
    optionSavingIsDisabledForURL: [],
    optionSavingIsDisabledForValue: {
      isUserImpersonated: true
    }
  }; // write function that checks config to avoid saving by rules

  var checkStorageOnKeyPresence = function(keyNameString) {
    return self.getItemByKeyName(keyNameString) !== null;
  };

  var functionsDecorator = function(callback) {
    var args = [];
    var originalArgs;

    if (typeof arguments[1] === "object") {
      originalArgs = arguments[1];

      if (checkConfigValues()) {
        return callback(originalArgs);
      }
    } else {
      if (arguments.length > 1) {
        for (var property in arguments) {
          if (property === "0") continue;
          args.push(arguments[property]);
        }
        if (checkConfigValues()) {
          return callback(args.join());
        }
      }
    }

    function checkConfigValues() {
      var isDisabledByLocation =
        config.optionSavingIsDisabledForURL.indexOf(window.location.pathname) >
        -1;
      var isDisabledByValue =
        config.optionSavingIsDisabledForValue.isUserImpersonated ===
        authenticationService.checkIsUserImpersonated();

      if (isDisabledByLocation === true || isDisabledByValue === true) {
        return false;
      }

      return true;
    }
  };

  return {
    pushSelectToStorage: functionsDecorator.bind(this, this.setItem),
    pullSelectFromStorage: functionsDecorator.bind(this, this.getItemByKeyName),
    removeSelectFromStorage: functionsDecorator.bind(
      this,
      this.removeItemByKeyName
    ),
    checkStorageOnKeyPresence: functionsDecorator.bind(
      this,
      checkStorageOnKeyPresence
    )
  };
};

export var selectsStorageService = new SelectsStorageService();
