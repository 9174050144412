export function requestGuideDownload() {
  var http = new XMLHttpRequest();
  var url = "/guides.json";

  http.onreadystatechange = function() {
    if (http.readyState === http.DONE) {
      if (http.status == 200) {
        window.location = url;
      } else if (http.status == 422) {
        toastr.error(JSON.parse(http.responseText).message);
      }
    }
  };
  http.open("GET", url, true);
  http.setRequestHeader("Content-Type", "application/json");
  http.send();
}
