import React from "react";

const Kind = ({ name, value, fieldMapId, disabled, checked, checkUpdateChanges }) => (
  <div className="field">
    <div className="ui radio checkbox">
      { checked ?
        <input type="radio" name={`field_map[${fieldMapId}][kind]`} value={value} disabled={disabled} defaultChecked='checked' onChange={checkUpdateChanges}/> :
        <input type="radio" name={`field_map[${fieldMapId}][kind]`} value={value} disabled={disabled} onChange={checkUpdateChanges}/>
      }
      <label htmlFor={value}>{name}</label>
    </div>
  </div>
);

export default Kind;
