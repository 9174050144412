import {
  restyleCheckboxes,
  raiseBackendServiceError,
  handleUncheckInputByName
} from "./common/helpers";
import { dateRangeComponent } from "./reports/components/date_range_component";
import Mappings from "./mappings";
import Validations from "./common/validations";
import CsvFile from "./common/csv_file";
$(".uploads.index").ready(function() {
  var _drawCounterEcho;

  var $uploadsTable = $("#uploads_list_table").DataTable({
    dom: '<"toolbar">flrtip',
    order: [[7, "desc"]], // default sorting field (updated_at)
    language: {
      searchPlaceholder: "Filename"
    },
    paging: true,
    page: 1,
    bAutoWidth: false,
    processing: true,
    serverSide: true,
    lengthMenu: [
      [10, 25, 50, 100],
      [10, 25, 50, 100]
    ],
    columnDefs: [{ orderable: false, targets: [0] }],
    searchDelay: 1500,
    ajax: function(d, callback) {
      var data = d;
      _drawCounterEcho = data.draw;
      data.page =
        $("#uploads_list_table")
          .DataTable()
          .page() + 1;
      data.query = data.search.value;
      data.direction = data.order[0]["dir"];

      if (window.$uploadsTable !== undefined) {
        data.sort = window.$uploadsTable.columns().header()[
          data.order[0]["column"]
        ].dataset.sortValue;
      }
      // Add the selected organization filter
      var selectedOrgId = $("#organisationFilterDropdown").val();
      if (selectedOrgId !== "") {
        data.organisation_id = selectedOrgId;
      }

      delete data.draw;
      delete data.columns;
      delete data.order;
      delete data.search;
      delete data.start;

      $.ajax({
        url: "/uploads.json",
        type: "GET",
        data: data,
        success: function(data) {
          handleUncheckInputByName("select_all");
          callback({
            draw: _drawCounterEcho,
            data: data.data,
            recordsTotal: data.recordsTotal,
            recordsFiltered: data.recordsTotal
          });
        }
      });
    },
    columns: [
      {
        data: null,
        render: function(data) {
          return (
            "<input type='checkbox'data-upload-id='" +
            data["id"] +
            "'><label></label>"
          );
        }
      },
      { data: "file_name" },
      {
        data: "organisation_id",
        render: function(data) {
          return "<span>" + data + "</span>";
        }
      },
      { data: "source_file_size" },
      { data: "description" },
      { data: "source_type" },
      { data: "status_description" },
      {
        data: "updated_at",
        render: function(data) {
          function formatDate(date) {
            function addZero(value) {
              if (value < 10) return `0${value}`;
              else return value;
            }
            return `${date.getFullYear()}-${addZero(
              date.getMonth() + 1
            )}-${addZero(date.getDate())} ${addZero(date.getHours())}:${addZero(
              date.getMinutes()
            )}`;
          }
          return `<td>${formatDate(new Date(data))}</td>`;
        }
      }
    ],
    drawCallback: function() {
      restyleCheckboxes();
    },
    createdRow: function(row, data) {
      if (data.delete_prohibited) {
        $("td", row)
          .find("input")
          .attr("disabled", "disabled");
        $("td", row)
          .eq(0)
          .parent()
          .attr("data-delete-prohibited", data["delete_prohibited"]);
        $("td", row)
          .eq(0)
          .attr("data-tooltip", data["delete_prohibition_message"]);
        $("td", row)
          .eq(0)
          .attr("data-position", "right center");
      }
      if (data.validation_errors) {
        $("td", row)
          .eq(1)
          .append(
            "<div class='csv-error' data-tooltip='Validation errors for extract'>" +
              "<a href='" +
              data["validation_errors_path"] +
              "' >" +
              "<i id='validation_errors' class='fa fa-warning error'> </i>" +
              "</a>" +
              "</div>"
          );
      }
      $("td", row)
        .eq(0)
        .parent()
        .attr("data-status", data["status"]);
    }
  });

  $('#uploads_list_table').on("preDraw.dt", function() { 
    clearSelectAll('#uploads_list_table .select-all');
  })

  if ($("#table_actions") !== undefined) {
    $("div.toolbar").html($("#table_actions"));
  }

  window.$uploadsTable = $uploadsTable; 
  $("#organisationFilterDropdown").on("change", function() {
    $uploadsTable.ajax.reload(); // Reload the DataTable when the filter changes
  }); 
});

export function handleDeleteUploads(e, itemName) {
  e.preventDefault();
  e.stopPropagation();

  var myForm;
  var checkedIds = [];

  for (var i = 0; i < document.forms.length; ++i) {
    myForm = document.forms[i];
  }

  for (var i = 0; i < myForm.elements.length; ++i) {
    if (
      myForm.elements[i].type == "checkbox" &&
      myForm.elements[i].name != "select_all"
    ) {
      if (myForm.elements[i].checked) {
        checkedIds.push(myForm.elements[i].dataset["uploadId"]);
      }
    }
  }

  if (checkedIds.length > 0) {
    if (confirm("Are you sure you want to delete selected extract(s)?")) {
      $.ajax({
        url: "/uploads/delete.json",
        type: "DELETE",
        data: {
          ids: checkedIds
        },
        success: function() {
          window.location.reload();
        },
        error: function(error) {
          raiseBackendServiceError(error);
        }
      });
    }
  } else {
    alert(
      "Please check the checkbox next to the extract(s) you want to delete, then click Delete"
    );
  }
}

$(".uploads.edit").ready(function() {
  var _scope = this;
  var _mappings = new Mappings();
  var _validations = new Validations();
  var _organisationId;
  var _file;
  var _uploadId;
  var _csvFile;
  var _mapping;

  var $progressBlock = $("#progressBlock");
  var $progressBlockText = $("#progressBlock .text-counter");

  dateRangeComponent.initSimpleDatePicker();

  $("a#ignore_errors").click(function() {
    doUpload();
  });

  $("a#export_csv").click(function() {
    var args = [$("#validation_errors_panel>table"), "upload_errors.csv"];
    exportTableToCSV.apply(this, args);
  });

  var displayErrors = function(errors) {
    if (errors != null && errors != "") {
      // Remove row with 'no data' info
      $("#picq_field_table tbody tr .dataTables_empty")
        .parent()
        .remove();

      $("#validation_message_panel").show();
      $("#validation_errors_panel").show();

      $.each(errors, function(i, ele) {
        $("#picq_field_table > tbody:last-child").append(
          "<tr class= 'row-alt'><td>" +
            ele.line +
            "</td><td>" +
            ele.column +
            "</td><td>" +
            ele.mrn +
            "</td><td>" +
            ele.ueid +
            "</td><td>" +
            ele.column_name +
            "</td><td>" +
            ele.mapped_to +
            "</td><td>" +
            ele.error +
            "</td></tr>"
        );
      });
    }

    if ($("#picq_field_table tbody tr").length - 1 > 500) {
      return true;
    }

    return false;
  };

  var doUpload = function() {
    $("#mapping_panel").hide();
    $("#upload_progress_panel").show();
    $("#validation_errors_panel").hide();
    $("#progress_current_activity").text("Uploading...");
    $("html, body").scrollTop(0);
    document.getElementById("csv_file_form").submit();
  };

  var doValidation = function(mapping) {
    _mapping = mapping;
    _validations.init({
      csvFile: _csvFile,
      onValidationComplete: doUpload,
      mapping: mapping
    });
  };

  this.init = function() {
    $("#upload_csv_file").on("change", function(e) {
      $("#upload_progress_panel").addClass("hide-progress-block");
      $("#upload_progress_panel").removeClass("show-progress-block");
      _scope.handleFileSelected(e);
    });

    $("#csv_file_form").on("submit", function(e) {
      _scope.handleSubmit(e);
    });
  };

  this.handleFileSelected = function(evt) {
    var files = evt.target.files;
    var is_valid = false;
    var file_selected = files.length > 0;

    if (file_selected) {
      // Add additional extension:
      // Example: RegExp("(.*?)\.(csv|doc|pdf|xml|bmp|ppt|xls)$");
      var regex = new RegExp("(.*?).(csv)$");
      $.each(files, function(i, file) {
        return (is_valid = regex.test(file.name.toLowerCase()));
      });

      // Show alert message if one of selected files with wrong extension
      if (file_selected && !is_valid) {
        toastr.error("Please select only CSV files", "File uploader");
        // Cleanup selected files
        $("#upload_csv_file").val("");
      }
    }
    $("#csv_file_submit").prop("disabled", !is_valid);
  };

  this.handleSubmit = function(evt) {
    evt.preventDefault();
    $("#csv_file_submit")
      .val("Please wait...")
      .prop("disabled", true);
    var fileElement = $("#upload_csv_file")[0];
    if (fileElement.files.length > 0) {
      _csvFile = new CsvFile({
        inputFileElement: fileElement
      });
      _mappings.getMapping(_csvFile, _mappings.displayMapping, doValidation);
    }
  };

  $(this.init);
});
