import {
  restyleCheckboxes,
  raiseBackendServiceError,
  handleUncheckInputByName
} from "./common/helpers";

$(".ecls.index").ready(function() {
  initEclsDataTable();
});

$(".ecls.edit, .ecls.new").ready(function() {
  $("#ecl_code_type").dropdown();
});

$(".ecls.upload").ready(function() {
  $("#ecl_code_type").dropdown();
  $("#uploadFileSection").hide();

  // Validate input file extention
  $("#ecl_csv_file").on("change", function(event) {
    var files = event.target.files;
    var regex = new RegExp("(.*?).(csv)$");

    if (files.length > 0) {
      var fileName = files[0].name.toLowerCase();

      if (!regex.test(fileName)) {
        toastr.error("Please select only CSV files", "File uploader");
        $("#ecl_csv_file").val("");
      } else {
        $("#nextButton").prop("disabled", false);
      }
    }
  });

  // Handle click next Button
  $("#nextButton").on("click", function(event) {
    $("#nextButton")
      .val("Please wait")
      .prop("disabled", true);
    // Get Csv File headers
    var inputFileElement = $("#ecl_csv_file")[0];
    new CsvFile({ inputFileElement: inputFileElement })
      .getColumnHeaders()
      .then(function(headers) {
        if (headers && headers.length > 0) {
          // Populate dropdowns
          $("#ecl_code_field").append(
            $("<option />")
              .val("")
              .text("")
          );
          $("#ecl_description_field").append(
            $("<option />")
              .val("")
              .text("")
          );
          headers.map(function(header) {
            $("#ecl_code_field").append(
              $("<option />")
                .val(header)
                .text(header)
            );
            $("#ecl_description_field").append(
              $("<option />")
                .val(header)
                .text(header)
            );
          });
          // Initiate dropdowns
          $("#ecl_code_field").dropdown();
          $("#ecl_description_field").dropdown();
          // Show file name
          $("#inputFileName").append(inputFileElement.files[0].name);
          // Show/hide sections
          $("#uploadFileSection").show();
          $("#selectFileSection").hide();
        } else {
          toastr.error("Please CSV with some content", "File uploader");
          $("#nextButton")
            .val("Next")
            .prop("disabled", false);
          $("#ecl_csv_file").val("");
        }
      });
  });

  // Validate selected fields
  $("#ecl_code_field, #ecl_description_field").on("change", function(event) {
    var codeField = $("#ecl_code_field");
    var descriptionField = $("#ecl_description_field");

    if (codeField.val()) {
      codeField.parent().removeClass("empty-field");
    }

    if (descriptionField.val()) {
      descriptionField.parent().removeClass("empty-field");
    }

    if (codeField.val() && descriptionField.val()) {
      $("#submitButton").prop("disabled", false);
    } else {
      $("#submitButton").prop("disabled", true);
    }
  });
});

export function handleDeleteEcl(e) {
  e.preventDefault();
  e.stopPropagation();

  var myForm = document.forms[0];
  var checkedIds = [];

  for (var i = 0; i < myForm.elements.length; ++i) {
    if (
      myForm.elements[i].type == "checkbox" &&
      myForm.elements[i].name != "select_all"
    ) {
      if (myForm.elements[i].checked) {
        checkedIds.push(myForm.elements[i].dataset["id"]);
      }
    }
  }

  if (checkedIds.length > 0) {
    if (confirm("Are you sure you want to delete the Ecl(s)")) {
      $.ajax({
        url: window.location.pathname + "/bulk_destroy.json",
        type: "DELETE",
        data: {
          ids: checkedIds
        },
        success: function() {
          window.location.reload();
        },
        error: function(error) {
          raiseBackendServiceError(error);
        }
      });
    }
  } else {
    alert(
      "Please check the checkbox next to the Ecl(s) you want to delete, then click Delete"
    );
  }
}

function initEclsDataTable() {
  var _drawCounterEcho;

  var $eclsTable = $("#ecls_table").DataTable({
    dom: '<"toolbar">flrtip',
    order: [[2, "desc"]],
    language: {
      searchPlaceholder: "Code"
    },
    paging: true,
    page: 1,
    bAutoWidth: false,
    processing: true,
    serverSide: true,
    lengthMenu: [
      [10, 25, 50, 100],
      [10, 25, 50, 100]
    ],
    columnDefs: [{ orderable: false, targets: [0, 4] }],
    searchDelay: 1500,
    ajax: function(d, callback) {
      var data = d;
      _drawCounterEcho = data.draw;
      data.page =
        $("#ecls_table")
          .DataTable()
          .page() + 1;
      data.query = data.search.value;
      data.direction = data.order[0]["dir"];

      if (window.$eclsTable !== undefined) {
        data.sort = window.$eclsTable.columns().header()[
          data.order[0]["column"]
        ].dataset.sortValue;
      }

      delete data.draw;
      delete data.columns;
      delete data.order;
      delete data.search;
      delete data.start;

      $.ajax({
        url: window.location.pathname + ".json",
        type: "GET",
        data: data,
        success: function(data) {
          handleUncheckInputByName("select_all");
          callback({
            draw: _drawCounterEcho,
            data: data.data,
            recordsTotal: data.recordsTotal,
            recordsFiltered: data.recordsTotal
          });
        }
      });
    },
    columns: [
      {
        data: null,
        render: function(data) {
          return (
            "<input type='checkbox'data-id='" + data["id"] + "'><label></label>"
          );
        }
      },
      { data: "code" },
      { data: "code_type" },
      { data: "description" },
      {
        data: null,
        render: function(data) {
          return (
            '<a class="icon-action" title="Edit" href="' +
            data.edit_ecl_path +
            '">' +
            '<i class="edit icon"></i>' +
            "</a>"
          );
        }
      }
    ],
    drawCallback: function() {
      restyleCheckboxes();
    }
  });

  if ($("#table_actions") !== undefined) {
    $("div.toolbar").html($("#table_actions"));
  }

  window.$eclsTable = $eclsTable;
}
