import {
  getIndicatorDegreesSelectValue,
  compileDashboardParams,
  getDashboardReports,
} from "../reports/helpers"
import {paramsCompilerService} from "../reports/services/params-compiler.service"
import {
  getBenchmarkParams,
  displayBenchmarkChart,
} from "../reports/benchmark-report"
import {
  compileIndicatorsJustifiedParams,
  getJustificationReport,
} from "../reports/justification-report"
import {
  compileIndicatorsParams,
  getSummaryReport,
} from "../reports/summary-report"
import {
  compileSpecificityIndicatorsParams,
  getSpecificityReport,
} from "../reports/specificity-report"
import {getNumeratorReport} from "../reports/numerator-report"
import {getImpactReport} from "../reports/impact-report"
import {getIndicatorManagementReport} from "../indicator-management"
import {getRevenueReport} from "../reports/min-max-drg"

// TODO: Vlad - use raiseBackendServiceError in new dataTable ajax reports..

export var paramsDecorator = {
  paramsObj: "",
  setParamsObj: function (obj) {
    if (!obj) {
      return false
    }
    this.paramsObj = obj
  },
  getParamsObj: function () {
    return this.paramsObj
  },
  removeKeys: function (keysArr) {
    for (var i = 0; i < keysArr.length; i++) {
      if (keysArr[i] in this.paramsObj) {
        var objName = keysArr[i]
        delete this.paramsObj[objName]
      }
    }
  },
  addKeys: function (keysObj) {
    for (var obj in keysObj) {
      this.paramsObj[obj] = keysObj[obj]
    }
  },
}

export var listenersRepository = {
  listeners: [],

  addListener: function (listenerName) {
    this.listeners.push(listenerName)
  },

  removeListener: function (listenerName) {
    for (var i = this.listeners.length - 1; i >= 0; i--) {
      if (this.listeners[i] === listenerName) {
        $(this.listeners[i]).off("click")
        this.listeners.splice(i, 1)
      }
    }
  },
}

export var requestReportDataByReportName = function (event) {
  var limit = event && event.currentTarget.dataset.limit
  var reports_classes =
    $(".reports")[0]?.className.split(" ").slice(0, 2).join(" ") ||
    $(".indicator_management")[0]?.className.split(" ").slice(0, 2).join(" ")

  switch (reports_classes) {
    // Dashboard
    case "reports index":
    case "reports risq_dashboard":
      if (!confirmReportRequest(limit, compileDashboardParams())) return

      getDashboardReports()

      break

    // Summary Report
    case "reports episodes_by_indicator":
      if (!confirmReportRequest(limit, compileIndicatorsParams())) return

      getSummaryReport()

      break

    // Numerator Report
    case "reports indicators_by_episode":
    case "reports risq_indicators_by_episode":
      if (!confirmReportRequest(limit, compileEpisodeIndicatorsParams())) return

      getNumeratorReport()

      break

    // Specificity Report
    case "reports specificity_report":
      if (!confirmReportRequest(limit, compileSpecificityIndicatorsParams()))
        return

      getSpecificityReport()

      break

    // Justification Report
    case "reports justification_report":
    case "reports risq_justification_report":
      if (!confirmReportRequest(limit, compileIndicatorsJustifiedParams()))
        return

      getJustificationReport()

      break

    // Benchmark Report
    case "reports benchmark_report":
      if (!confirmReportRequest(limit, getBenchmarkParams())) return

      displayBenchmarkChart()

      break

    // Impact Report
    case "reports risq_impact_report":
      if (!confirmReportRequest(limit, compileEpisodeIndicatorsParams())) return

      getImpactReport()

      break

    case "indicator_management index":
      if (!confirmReportRequest(limit, compileIndicatorManagementParams()))
        return

      getIndicatorManagementReport()

      break

    // Load Revenue Report
    case "reports min_max_drg":
      if (!confirmReportRequest(limit, compileRevenueSelectEpisodesParams()))
        return
      getRevenueReport()

      break
  }

  return false
}

export var abortPendingAjaxRequests = function () {
  if (!window.pendingAjaxRequests) return

  for (var i = 0; i < window.pendingAjaxRequests.length; i++) {
    window.pendingAjaxRequests[i].abort(["Request canceled"])
  }
}

var confirmReportRequest = function (limit, params) {
  if (!limit || !params) return true

  var periodDays = 0
  var oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds

  if (
    params.date_field_type === "coding_date" &&
    !!params.coding_date_from &&
    !!params.coding_date_to
  ) {
    var from = new Date(params.coding_date_from)
    var to = new Date(params.coding_date_to)

    periodDays = Math.round(Math.abs((to - from) / oneDay))
  }

  if (
    params.date_field_type === "dos" &&
    !!params.dos_from &&
    !!params.dos_to
  ) {
    var from = new Date(params.dos_from)
    var to = new Date(params.dos_to)

    periodDays = Math.round(Math.abs((to - from) / oneDay))
  }

  if (params.coding_date_time_period) {
    periodDays = parseInt(params.coding_date_time_period)
  }

  if (params.dos_time_period) {
    periodDays = parseInt(params.dos_time_period)
  }

  if (periodDays < limit) return true

  var message =
    "You've requested a report for more than " +
    limit +
    " days. This may take a while. Do you want to continue?"
  var confirmation = window.confirm(message)
  return confirmation
}

export var objectValuesPolyfill = function (obj) {
  var res = []
  for (var i in obj) {
    if (obj.hasOwnProperty(i)) {
      res.push(obj[i])
    }
  }
  return res
}

export var raiseBackendServiceError = function (error) {
  var serverSideError = error.responseJSON && error.responseJSON.message
  var clientSideError = error.statusText

  if (serverSideError) {
    console.warn(error.responseJSON)
    toastr.error(error.responseJSON.message)
  } else {
    toastr.error(clientSideError)
  }
}

export var checkUserRoleIs = function (roleNameStirng) {
  return $("[data-user-role]").data("userRole") === roleNameStirng
}

export function requestReview(e) {
  e.preventDefault()

  var xhr = new XMLHttpRequest()
  var url = e.target.parentElement.dataset.url

  xhr.onload = xhr.onerror = function () {
    if (xhr.status == 200) {
      window.open(url, "_blank")
    } else {
      toastr.error("Internal Server Error")
    }
  }

  xhr.open("GET", url, true)
  xhr.setRequestHeader("Content-Type", "application/json")
  xhr.send()
}

export var getFilter = function () {
  if (
    $("#before_filter_button").attr("class").split(" ").indexOf("basic") == -1
  ) {
    return "before"
  } else {
    return "after"
  }
}

export var initVisualEffects = function () {
  $("#hideIcon").on("click", function () {
    $("#hideIcon i").toggleClass("down up")
    $("#separateEpisodesTable").toggle("fast")
  })
}

export var toggleEpisodesView = function (direction) {
  if (direction == "down") {
    $("#separateEpisodesTable").slideUp("fast")
    $("#hideIcon i").removeClass("down").addClass("up")
  } else if (direction == "up") {
    $("#separateEpisodesTable").slideDown("fast")
    $("#hideIcon i").removeClass("up").addClass("down")
  }
}

export function debounce(func, delay) {
  var debounceTimer
  return function () {
    var context = this
    var args = arguments

    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => func.apply(context, args), delay)
  }
}

export function restyleCheckboxes() {
  $("th.checkbox-column").addClass("custom-checkbox ui checkbox")
  $("td input[type='checkbox']")
    .parent()
    .addClass("custom-checkbox ui checkbox")

  var $checkboxWrapperList = $(".custom-checkbox")

  $.each($checkboxWrapperList, function (i, wrapper) {
    var checkbox = $(wrapper).find("input[type='checkbox']")
    var id = "0" + i
    checkbox.attr("id", id)
    $(wrapper).find("label").attr("for", id)
  })
}

export function handleDelete(e, itemName) {
  e.preventDefault()

  var numChecked = 0
  var myForm
  for (var i = 0; i < document.forms.length; ++i) {
    myForm = document.forms[i]
    if (myForm.action.indexOf("bulk_actions") != -1) {
      break
    }
  }
  for (var i = 0; i < myForm.elements.length; ++i) {
    if (
      myForm.elements[i].type == "checkbox" &&
      myForm.elements[i].name != "delete_all"
    ) {
      if (myForm.elements[i].checked) {
        ++numChecked
      }
    }
  }
  if (numChecked > 0) {
    var msg = ""
    if (itemName == "upload") {
      msg =
        "Are you sure you want to PERMANENTLY delete the selected data file" +
        (numChecked > 1 ? "s" : "") +
        " from PICQ? You will " +
        "not be able to re-run dependent analyses and reports. Reports already generated will not be affected."
    } else if (itemName == "analysis") {
      msg =
        "Are you sure you want to PERMANENTLY delete " +
        (numChecked == 1 ? "this analysis" : "these analyses") +
        " from PICQ? You will not be able to re-run dependent reports. Reports already generated will not be affected."
    } else if (itemName == "organisation") {
      msg =
        "Are you sure you want to delete the selected organisation(s) from PICQ? All user accounts for this organisation " +
        "will also be deleted."
    } else if (itemName == "indicator") {
      msg =
        "Are you sure you want to PERMANENTLY delete the selected indicator(s) from PICQ? The indicator will be deleted from this set only."
    } else {
      msg =
        "Are you sure you want to PERMANENTLY delete the selected " +
        itemName +
        "(s) from PICQ?"
    }
    if (confirm(msg)) {
      myForm.bulk_action.value = "delete"
      myForm.submit()
    }
  } else {
    alert(
      "Please check the checkbox next to the " +
        itemName +
        "(s) you want to delete, then click Delete",
    )
  }
}

export function toggleAll(checkBox) {
  checkBox = $(checkBox)
  setCheckboxes(checkBox, checkBox.prop("checked"))
}

export function clearSelectAll(checkBox) {
  checkBox = $(checkBox)
  checkBox.prop("checked", false)
  setCheckboxes(checkBox, false)
}

function setCheckboxes(checkBox, value) {
  var table = checkBox.closest("table")
  var index = table.find(".select-all").closest("th").index() + 1
  table
    .find("tbody tr td:nth-child(" + index + ") input:checkbox")
    .each(function () {
      if ($(this).prop("disabled") == false) {
        $(this).prop("checked", value)
      }
    })
}

export function toggleCheckboxes(checkboxName, state) {
  var myForm = document.forms[0]
  for (var i = 0; i < myForm.elements.length; ++i) {
    if (
      myForm.elements[i].type == "checkbox" &&
      myForm.elements[i].name == checkboxName
    ) {
      myForm.elements[i].checked = state && !myForm.elements[i].disabled
    }
  }
}

export function exportTableToCSV($table, filename) {
  var $rows = $.merge($table.find("tr:has(th)"), $table.find("tr:has(td)")),
    tmpColDelim = String.fromCharCode(11), // vertical tab character
    tmpRowDelim = String.fromCharCode(0), // null character
    // actual delimiter characters for CSV format
    colDelim = '","',
    rowDelim = '"\r\n"',
    // Grab text from table into CSV formatted string
    csv =
      '"' +
      $rows
        .map(function (i, row) {
          var $row = $(row)
          var $cols = $row.find("td")
          if ($cols.length === 0) $cols = $row.find("th")

          return $cols
            .map(function (j, col) {
              var $col = $(col),
                text = $col.text()

              return text.replace(/"/g, '""') // escape double quotes
            })
            .get()
            .join(tmpColDelim)
        })
        .get()
        .join(tmpRowDelim)
        .split(tmpRowDelim)
        .join(rowDelim)
        .split(tmpColDelim)
        .join(colDelim) +
      '"'

  if (false && window.navigator.msSaveBlob) {
    var blob = new Blob([decodeURIComponent(csv)], {
      type: "text/csv;charset=utf8",
    })

    window.navigator.msSaveBlob(blob, filename)
  } else if (window.Blob && window.URL) {
    // HTML5 Blob
    var blob = new Blob([csv], {type: "text/csv;charset=utf8"})
    var csvUrl = URL.createObjectURL(blob)

    $(this).attr({download: filename, href: csvUrl})
  } else {
    // Data URI
    var csvData =
      "data:application/csv;charset=utf-8," + encodeURIComponent(csv)

    $(this).attr({download: filename, href: csvData, target: "_blank"})
  }
}

export var showToast = function (flash) {
  var mapTypes = {
    notice: "success",
    alert: "error",
    error: "error",
    warning: "warning",
    info: "info",
  }
  var mapOptions = {
    notice: {},
    error: {},
    alert: {timeOut: "0", extendedTimeOut: "0"},
    warning: {timeOut: "0", extendedTimeOut: "0"},
    info: {},
  }

  $.each(flash, function (index, flash) {
    toastr[mapTypes[flash[0]]] &&
      toastr[mapTypes[flash[0]]](flash[1], null, mapOptions[flash[0]])
  })
}

export var getURLParameter = function (name) {
  return (
    decodeURIComponent(
      (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
        location.search,
      ) || [, ""])[1].replace(/\+/g, "%20"),
    ) || null
  )
}

export var openAcsDescriptionTab = function () {
  event.preventDefault()
  var thisAcsId = $(event.target).attr("data-acs-id")
  var thisIndicatorEdition = $(event.target).attr("data-edition")
  window.open(
    "/acs/" + thisIndicatorEdition + "/" + thisAcsId + ".htm",
    "_blank",
  )
}

export var initDataTable = function (params) {
  // colspan is not supported by dataTable

  if (
    Object.keys(params).length === 1 &&
    params.tableId !== undefined &&
    $(params.tableId) !== undefined
  ) {
    // simple common table visualization
    var thisDataTable = $(params.tableId).DataTable({
      ordering: false,
      searching: false,
      lengthChange: false,
      info: false,
      paging: false,
      columnDefs: [{render: null, targets: '_all'}],
      language: {
        emptyTable: "No data available in table",
      },
    })

    return thisDataTable
  }

  var tableId =
    params.tableId == undefined
      ? console.log("tableId is missed")
      : params.tableId
  var orderVal =
    params.orderVal == undefined ? (params.orderVal = 1) : params.orderVal
  var targetsVal =
    params.targetsVal == undefined
      ? (params.targetsVal = [0])
      : params.targetsVal
  var sortableColumns = []
  var sortingDirection =
    params.sortingDirection !== undefined ? params.sortingDirection : "desc"

  function checkIfColumnSortable(columnIndex) {
    return $(tableId + " thead tr").children().length - 1 >= columnIndex
  }

  for (var i = 0; i < targetsVal.length; i++) {
    if (checkIfColumnSortable(targetsVal[i])) {
      sortableColumns.push(targetsVal[i])
    }
  }

  var resultTable = $(tableId).DataTable({
    order: [[orderVal, sortingDirection]],
    columnDefs: [
      {orderable: false, targets: sortableColumns},
      {width: "3em", targets: [0]},
      {render: null, targets: '_all'}
    ],
    dom: '<"toolbar">flrtip',
    language: {
      search: "",
      searchPlaceholder: "Search...",
      sSearch: '<i class="search icon"></i>',
    },
    drawCallback: restyleCheckboxes(),
  })

  $(tableId).on("preDraw.dt", function () {
    clearSelectAll(tableId + " .select-all")
  })

  $(tableId).css("width", "100%")

  if ($("#table_actions") !== undefined) {
    $("div.toolbar").html($("#table_actions"))
  }

  $(tableId).css("width", "100%")

  return resultTable
}

export var gon = {
  roles: {
    role_standard: 1,
    role_standard_summary_only: 7,
    role_organisation_admin: 2,
    role_site_admin: 4,
    role_picq_admin: 3,
    role_coder: 5,
    role_clinician: 6,
    role_sales: 8,
  },
  organisation_types: {
    state_health_department: 1,
    local_health_district: 2,
    hospital_group: 3,
    hospital: 4,
  },
}

export function toggleProfileCoderIdField(parentElementId) {
  var userRole = $(parentElementId + " [data-name=profileRoleIdInput]").val()
  var selector = parentElementId + " [data-name=profileCoderIdFieldPair]"
  var $profileCoderIdFieldPair = $(selector)
  var $coderIdInput = $(selector + " [data-name=profileCoderIdInput]")

  if (
    (userRole &&
      gon.roles &&
      (userRole == gon.roles.role_coder ||
        userRole == gon.roles.role_standard ||
        userRole == gon.roles.role_standard_summary_only)) ||
    !userRole
  ) {
    $profileCoderIdFieldPair.show()

    // require coder id only for role CODER
    if (userRole == gon.roles.role_coder) {
      $coderIdInput.prop("required", true)
    } else {
      $coderIdInput.removeAttr("required")
    }
  } else {
    $profileCoderIdFieldPair.hide()
    $coderIdInput.removeAttr("required")
  }
}

export var getFilterByCheckbox = function () {
  var isChecked = $("#hideReviewed").prop("checked")
  if (isChecked === undefined) return ""

  if (!isChecked) {
    return "before"
  } else {
    return "after"
  }
}

export var compileEpisodeIndicatorsParams = function () {
  var defaultSelects = {
    filter: getFilterByCheckbox(),
    degree: getIndicatorDegreesSelectValue(),
    tag: $("#tag_select").val(),
    indicator_category_id: window.gon.indicator_category_id,
    user_filters: window.gon.user_defined_filters,
  }

  if (document.querySelector(".reports.risq_indicators_by_episode")) {
    var risqSelects = {
      clinician_review: window.gon.clinician_review,
      hac_category:
        $("#risq_hac_categories_select").val() === null ||
        ($("#risq_hac_categories_select").val().length === 1 &&
          $("#risq_hac_categories_select").val()[0] == "")
          ? []
          : $("#risq_hac_categories_select").val(),
      hac_indicators:
        $("#risq_hac_indicators_select").val() === null ||
        ($("#risq_hac_indicators_select").val().length === 1 &&
          $("#risq_hac_indicators_select").val()[0] == "")
          ? []
          : $("#risq_hac_indicators_select").val(),
    }
    defaultSelects = {...defaultSelects, ...risqSelects}
  }

  if (document.querySelector(".reports.risq_impact_report")) {
    defaultSelects = {...{fund: $("#fund_select").val()}}
  }

  return paramsCompilerService.compileParams(
    defaultSelects,
    $("#report_type_select").val(),
    $("#date_filed_type_select").val(),
  )
}

export const compileIndicatorManagementParams = () => {
  var defaultSelects = {
    indicator_category_id: 3,
  }
}

export const compileRevenueSelectEpisodesParams = () => {
  var defaultSelects = {
    tag: $("#tag_select").val(),
  }

  return paramsCompilerService.compileParams(
    defaultSelects,
    $("#report_type_select").val(),
    $("#date_filed_type_select").val(),
  )
}

export function handleUncheckInputByName(name) {
  var selector = "input[name='" + name + "']"
  var input = document.querySelector(selector)
  if (input !== null) {
    input.checked = false
  }
}

var showAndEnableInputs = function (block) {
  block.classList.remove("hidden")
  block.querySelectorAll("input").forEach(function (input) {
    input.disabled = false
  })
}

var hideAndDisableInputs = function (block) {
  block.classList.add("hidden")
  block.querySelectorAll("input").forEach(function (input) {
    input.disabled = true
  })
}

export var initMappingKindsBlocksVisibility = function () {
  document
    .querySelectorAll('[data-class="kinds-block"]')
    .forEach(function (kindBlock, index, array) {
      var select = document.querySelector(
        `select#${kindBlock.dataset.fieldMapSelectId}`,
      )
      var selectedOptionText = select.options[select.selectedIndex].text
      if (selectedOptionText === "USER (Text)") {
        showAndEnableInputs(kindBlock)
      } else {
        hideAndDisableInputs(kindBlock)
      }
    })
}

export var initMappingKindsBlocksEventListener = function () {
  document
    .querySelector("#field-maps")
    .addEventListener("change", function (event) {
      var element = event.target
      if (element.dataset.class !== "field-map-select") return

      var selectedOptionText = element.options[element.selectedIndex].text
      var kindBlock = document.querySelector(
        `div#${element.dataset.kindsBlockId}`,
      )

      if (selectedOptionText === "USER (Text)") {
        showAndEnableInputs(kindBlock)
      } else {
        hideAndDisableInputs(kindBlock)
      }
    })
}
