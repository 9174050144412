var CsvFile = function(opts) {
  var _inputFileElement = opts.inputFileElement;
  var _inputFile;
  var _chunksQueued = 0;
  var _async = opts.async === undefined ? true : opts.async;
  var _totalBytes = 0;
  var _currentBytes = 0;
  var _inputDescription;
  var _inputDataExtractionDate;

  Papa.LocalChunkSize = 1 * 1024 * 1024; // 1MB

  var getFile = function() {
    if (_inputFile) return _inputFile;
    _inputFile = _inputFileElement.files[0];
    if (_inputFile === undefined) {
      throw "CsvFile#getFile : No file selected";
    }
    return _inputFile;
  };

  var getDescription = function() {
    _inputDescription = $("#upload_description").val();
    if (_inputDescription === "") {
      _inputDescription = null;
    }
    return _inputDescription;
  };

  var getDataExtractionDate = function() {
    _inputDataExtractionDate = $("#upload_data_extraction_date").val();
    if (_inputDataExtractionDate === "") {
      _inputDataExtractionDate = null;
    }
    return _inputDataExtractionDate;
  };

  var waitForQueuedChunks = function(callback) {
    setTimeout(function() {
      if (_chunksQueued <= 0) {
        callback();
      } else {
        waitForQueuedChunks(callback);
      }
    }, 500);
  };

  this.getColumnHeaders = function() {
    return new Promise(function(onFulfilled, onRejected) {
      try {
        //
        // Read in only the header row
        //
        var f = getFile();
        Papa.parse(f, {
          preview: 1,
          skipEmptyLines: true,
          complete: function(results, file) {
            onFulfilled(results.data[0]);
          },
          error: function(error, file) {
            onRejected(error);
          }
        });
      } catch (exc) {
        onRejected(exc);
      }
    });
  };

  this.readFile = function(mappedFields, opts) {
    var f = getFile();
    var fileData = {
      name: f.name,
      size: f.size
    };

    _totalBytes = f.size;
    var chunkNum = 1;
    var maxErrors;

    Papa.parse(f, {
      header: opts.header,
      transformHeader: opts.transformHeader,
      skipEmptyLines: true,
      beforeFirstChunk: function(chunk) {
        if (opts.onReadFirstChunk !== undefined) {
          opts.onReadFirstChunk(
            fileData,
            getDescription(),
            getDataExtractionDate()
          );
        }
      },
      chunk: function(results, parser) {
        // NOTE: Skip empty objects:
        // The data object will be removed if all VALUES of this object
        // do not contain any character from a-z|A-Z|0-9
        var filteredResults = results.data.filter(function(e) {
          var values = Object.keys(e).map(function(key) {
            return e[key];
          });
          return /[a-zA-Z0-9]/.test(values);
        });

        if (opts.onReadLines !== undefined) {
          maxErrors = opts.onReadLines(filteredResults).then(
            // onSuccess, onFailure callbacks
            null,
            function() {
              parser.abort();
            }
          );
        }

        if (opts.onUploadReadLines !== undefined) {
          maxErrors = opts.onUploadReadLines(
            filteredResults,
            _currentBytes,
            _totalBytes
          );

          if (maxErrors) {
            parser.abort();
          }
        }

        _currentBytes = chunkNum * Papa.LocalChunkSize;
        chunkNum++;
      },
      complete: function(results, file) {
        waitForQueuedChunks(opts.onComplete);
      },
      error: function(error, file) {
        opts.onError(error);
      }
    });
  };

  this.getFileSize = function() {
    var f = getFile();
    return f.size;
  };

  this.getFileSizeHuman = function() {
    var s = this.getFileSize();
    var suffix = "bytes";
    if (s > 1048576) {
      s = Math.round((s / 1048576) * 10) / 10;
      suffix = "MB";
    } else if (s > 1024) {
      s = Math.round((s / 1024) * 10) / 10;
      suffix = "KB";
    }
    return s + " " + suffix;
  };

  this.getFileName = function() {
    var f = getFile();
    return f.name;
  };

  this.getLastModified = function() {
    var f = getFile();
    return f.lastModifiedDate;
  };

  this.getUploadedDate = function() {
    return new Date();
  };
};

export default CsvFile;
