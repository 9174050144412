import {raiseBackendServiceError} from "../common/helpers"
import {timePeriodSelectComponent} from "./components/indicator_time_period_select_component"
import {dateRangeComponent} from "./components/date_range_component"
import {
  showDataLoading,
  hideDataLoading,
} from "./components/loading_cirlce_component"
import {sendRequestToGenerateExcel} from "./services/reports-api.service"
import {dateFiledTypeSelectComponent} from "./components/date_filed_type_select_component"
import {reportsSelectsComponent} from "./components/reports_selects_component"
import {paramsCompilerService} from "./services/params-compiler.service"
import {addReviewEpisodeListeners} from "./helpers"

var $dataTable

$(document).ready(function () {
  if (!document.querySelector(".reports.min_max_drg")) return

  const setupDropdownSelected = (elementId, allText, defaultValue) => {
    $(`#${elementId}`).dropdown({
      useLabels: false,
      onChange: function (value, text, $choice) {
        document
          .getElementById(elementId)
          .parentElement.querySelector("i.icon")
          .classList.remove("display-none")

        if (text === allText || !value) {
          $(`#${elementId}`).dropdown("set value", defaultValue)
        }
      },
      defaultValue: defaultValue,
    })
  }

  const setupDropdownIcon = elementId => {
    const element = document.getElementById(elementId)
    if (!element) return

    const icon = element.parentElement.querySelector("i.icon")
    if (!icon) return

    icon.classList.remove("dropdown")
    icon.classList.add("delete")

    icon.addEventListener("click", function (e) {
      $(`#${elementId}`).dropdown("clear")
    })
  }

  setupDropdownSelected("care_type_select", "All Care Types", "All")
  setupDropdownSelected("fund_select", "All Funds", "All")
  setupDropdownSelected("hospital_groups_select", "All", "All")
  setupDropdownSelected("hospitals_select", "All Hospitals", "All")
  setupDropdownSelected("mdc_select", "All MDCc", "All")

  setupDropdownIcon("hospitals_select")
  setupDropdownIcon("hospital_groups_select")
  setupDropdownIcon("care_type_select")
  setupDropdownIcon("fund_select")
  setupDropdownIcon("mdc_select")

  window.hideDiagnosisList = hideDiagnosisList
  dateFiledTypeSelectComponent.initDropdown()
  dateFiledTypeSelectComponent.initEventHandler()
  dateRangeComponent.initDatepicker()
  reportsSelectsComponent.initReportsDependentDropdowns()
  timePeriodSelectComponent.initDropdown()
  timePeriodSelectComponent.initEventHandler()

  $dataTable = initMinMaxReportTable("episode_revenue")

  $("#care_type_select").dropdown()
  $("#clinician_select").dropdown()
  $("#coder_select").dropdown()
  $("#indicator_time_period_select").dropdown("set selected", "Date range")
  $("#date_filed_type_select").dropdown("set selected", "dos")
  $("#datepickerSelectInput").show()
  $("#hospitals_select").dropdown()
  $("#hospital_groups_select").dropdown()
  $("#indicator_degrees_select").dropdown()
  $("#los_select").dropdown()
  $("#number_of_episode_select").dropdown()
  $("#tag_select").dropdown()

  $("a#revenue_report_download_excel_link").click(function (e) {
    e.preventDefault()

    const careTypevalue = $("#care_type_select").val()
    const mdcValue = $("#mdc_select").val()
    const fundValue = $("#fund_select").val()
    const reportTypeSelect = $("#report_type_select").val()
    const dateRangeFrom = $("#limitedDatepickerSelectFrom").val()
    const dateRangeTo = $("#limitedDatepickerSelectTo").val()
    const emptyDateRange = !dateRangeFrom || !dateRangeTo
    var compiledParams

    var defaultSelects = {
      caretypes: careTypevalue ? careTypevalue : ["All"],
      episode_count: $("#number_of_episode_select").val(),
      funds: fundValue ? fundValue : ["All"],
      los: $("#los_select").val(),
      mdcs: mdcValue ? mdcValue : ["All"],
      tag: $("#tag_select").val(),
      hospitals: ["All"],
      hospital_groups: ["All"],
      date_range_from: dateRangeFrom,
      date_range_to: dateRangeTo,
      empty_date_range: emptyDateRange,
    }

    if (reportTypeSelect === "hospital") {
      defaultSelects.hospitals = $("#hospitals_select").val() || ["All"]
    } else if (reportTypeSelect === "hospitalGroup") {
      defaultSelects.hospital_groups = $("#hospital_groups_select").val() || [
        "All",
      ]
    }

    compiledParams = paramsCompilerService.compileParams(
      defaultSelects,
      $("#report_type_select").val(),
      $("#date_filed_type_select").val(),
    )

    toastr.info("Your file download has started.")
    sendRequestToGenerateExcel($(this).attr("href"), compiledParams)
  })

  window.addEventListener("resize", function () {
    "use strict"
    $dataTable.draw()
    resizeIndicatorsListTables()
  })

  getRevenueReport()
})

export const getRevenueReport = () => {
  showDataLoading()

  const request = $.ajax({
    type: "GET",
    contentType: "application/json",
    url: "/reports/load_revenue_report.json",
    data: getMinMaxParams(),
    success: function (result) {
      minMaxDrgTable(result)
    },
    error: function (error) {
      hideDataLoading()
      raiseBackendServiceError(error)
    },
  })
  window.pendingAjaxRequests = [request]

  return request
}

export const getMinMaxParams = () => {
  const getValueOrDefault = (selector, defaultValue = ["All"]) =>
    $(selector).val() || defaultValue

  const dateRangeFrom = $("#limitedDatepickerSelectFrom").val()
  const dateRangeTo = $("#limitedDatepickerSelectTo").val()
  const emptyDateRange = !dateRangeFrom || !dateRangeTo

  const defaultSelects = {
    caretypes: getValueOrDefault("#care_type_select"),
    episode_count: $("#number_of_episode_select").val(),
    funds: getValueOrDefault("#fund_select"),
    los: $("#los_select").val(),
    mdcs: getValueOrDefault("#mdc_select"),
    tag: $("#tag_select").val(),
    hospitals: ["All"],
    hospital_groups: ["All"],
    date_range_from: dateRangeFrom,
    date_range_to: dateRangeTo,
    empty_date_range: emptyDateRange,
  }

  const reportTypeSelect = $("#report_type_select").val()

  if (reportTypeSelect === "hospital") {
    defaultSelects.hospitals = getValueOrDefault("#hospitals_select")
  } else if (reportTypeSelect === "hospitalGroup") {
    defaultSelects.hospital_groups = getValueOrDefault(
      "#hospital_groups_select",
    )
  }

  return paramsCompilerService.compileParams(
    defaultSelects,
    reportTypeSelect,
    $("#date_filed_type_select").val(),
  )
}

var initMinMaxReportTable = function (tableId) {
  var num_user_filters = window.gon.user_defined_filters?.length || 0
  var default_number_columns = 12
  var non_fixed_columns = default_number_columns + num_user_filters
  var no_order_columns = [0, 1, 2, non_fixed_columns, non_fixed_columns + 1]
  var columnsParams = new Array(default_number_columns + num_user_filters)

  columnsParams.push(
    {
      data: null,
      defaultContent:
        '<div class="ui checkbox">' +
        '<input type="checkbox" class="select-row-checkbox">' +
        "<label></label>" +
        "</div>",
    },
    {
      data: null,
      defaultContent:
        "<div>" +
        '<button id="showListBoosters" class="ui basic red button" style="text-wrap: nowrap">' +
        "Prevalent Dx" +
        "</button>" +
        '<button id="hideIndicators" onclick="hideDiagnosisList(event)" style="display:none;" class="ui basic red button">' +
        "Hide" +
        "</button>" +
        "</div>",
    },
  )

  var $dataTable = $("#" + tableId).DataTable({
    scroller: true,
    scrollX: true,
    columns: columnsParams,
    columnDefs: [
      {orderable: false, targets: no_order_columns},
      {width: "0em", targets: [0]},
      {
        targets: 3,
        createdCell: function (td) {
          $(td).css({
            "white-space": "nowrap",
            width: "auto",
          })
        },
      },
    ],
    order: [[5, "desc"]],
    createdRow: function (row, data) {
      $("td", row)
        .eq(0)
        .attr("data-row-id", data[0])
        .css("color", "transparent")
        .text("")
      $("td", row).last().find("a").attr("data-episode-id", data[0])
    },
    dom: '<"toolbar">flrtip',
    language: {
      search: "",
      searchPlaceholder: "Search...",
      sSearch: '<i class="search icon"></i>',
    },
    drawCallback: function () {
      showDataLoading()
    },
  })

  $dataTable.on("draw", function () {
    hideDataLoading()
    addReviewEpisodeListeners()
  })

  $("#" + tableId + " tbody").on("click", "button", function (e) {
    var data = $dataTable.row($(this).parents("tr")).data()
    var id = data[0]

    if (e.currentTarget.id === "showListBoosters") {
      $(e.target).addClass("loading")
      $.ajax({
        url: "/reports/list_boosters_for_episode.json",
        type: "GET",
        data: {episode_id: id},
        success: function (response) {
          $(e.target).removeClass("loading")
          updateDiagnosisList(response, e)
        },
        error: function (error) {
          $(e.target).removeClass("loading")
          raiseBackendServiceError(error)
        },
      })
    }
  })

  $("#" + tableId + " tbody").on("change", ".select-row-checkbox", function () {
    var $checkbox = $(this)
    var $row = $checkbox.closest("tr")
    var id = $dataTable.row($row).data()[0]

    if ($checkbox.is(":checked")) {
      $.ajax({
        url: "/reports/update_episode_audit_value",
        type: "PUT",
        data: JSON.stringify({
          episode_id: id,
          audited: true,
        }),
        contentType: "application/json",
        success: function (response) {
          $row.css("background-color", "#F2C9D6")
        },
        error: function (error) {
          raiseBackendServiceError(error)
        },
      })
    } else {
      $.ajax({
        url: "/reports/update_episode_audit_value",
        type: "PUT",
        data: JSON.stringify({
          episode_id: id,
          audited: false,
        }),
        contentType: "application/json",
        success: function (response) {
          $row.css("background-color", "")
        },
        error: function (error) {
          raiseBackendServiceError(error)
        },
      })
    }
  })

  return $dataTable
}

// result table
function updateDiagnosisList(data, event) {
  var button = $(event.target)

  var tr = button.closest("tr")
  var row = $dataTable.row(tr)
  const hasData = !!data.length

  if (hasData) {
    var table = $(
      '<div class="noscroll">' +
        '<table class="ui basic table nested-table" style="border-top: 4px solid #F2C9D6">' +
        "<thead>" +
        "<tr>" +
        "<th style='display: table-cell; visibility: visible; padding-bottom: 35px; text-align: center; vertical-align: middle; font-weight: bold'>Code</th>" +
        "<th style='display: table-cell; visibility: visible; padding-bottom: 35px; text-align: center; vertical-align: middle; font-weight: bold'>Boost</th>" +
        "<th style='display: table-cell; visibility: visible; padding-bottom: 35px; text-align: center; vertical-align: middle; font-weight: bold'>Description</th>" +
        "</tr>" +
        "</thead>" +
        "<tbody>" +
        diagnosisTable(data) +
        "</tbody>" +
        "</table></div>",
    )
  }
  row.child(table).show()
  tr.addClass("shown")
  button.hide()
  button.next("button#hideIndicators").show()
  resizeIndicatorsListTables()
}

function diagnosisTable(data) {
  return data
    .map(function (element) {
      return (
        "<tr>" +
        "<td>" +
        element.code +
        "</td>" +
        "<td>" +
        element.boost +
        "</td>" +
        '<td class="table-description">' +
        element.description +
        "</td>" +
        "</tr>"
      )
    })
    .join("")
}

function resizeIndicatorsListTables() {
  var table = $("#episode_revenue")

  var left_offset = table.position()?.left - 110 + "px"

  $(".noscroll").each(function () {
    var table_height = $(this).height() + 20

    $(this).css("left", left_offset + "px")
    $(this).css("width", 1024 + "px")
    $(this).css("transform", "translateY(-50%)")
    $(this).css("margin-top", 10 + "px")

    $(this)
      .parents("tr")
      .first()
      .css("height", table_height + "px")
  })
}

function hideDiagnosisList(event) {
  var button = $(event.target)

  var tr = button.closest("tr")
  var row = $dataTable.row(tr)

  row.child.hide()
  tr.removeClass("shown")
  button.hide()
  button.prev("button#showListBoosters").show()
}

// main table
var minMaxDrgTable = function (data) {
  if ($dataTable !== undefined) {
    $dataTable.clear()

    var user_defined_filters = window.gon.user_defined_filters

    data.forEach(function (elem) {
      var default_values = [
        elem.id,
        elem.ueid,
        elem.mrn,
        elem.dos,
        elem.los,
        elem.nwau_difference,
        elem.drg,
        elem.drg_description,
        elem.target_drg,
        elem.target_drg_description,
        elem.reason_for_inclusion,
        elem.coder_id,
      ]

      var user_defined_values = []
      if (user_defined_filters) {
        user_defined_filters.forEach(function (value) {
          user_defined_values.push(elem.details ? elem.details[value] : "")
        })
      }

      var end_row_empty_cols = ["", ""]
      $dataTable.row.add(
        default_values.concat(user_defined_values).concat(end_row_empty_cols),
      )
    })

    $dataTable.draw()
  }
}
