var LocalStorageService = function() {
  var getItemByKeyName = function(keyName) {
    return localStorage.getItem(keyName);
  };

  var setItem = function(item) {
    localStorage.setItem(item.key, item.value);
  };

  var removeItemByKeyName = function(keyNameString) {
    return localStorage.removeItem(keyNameString);
  };

  return {
    setItem: setItem,
    getItemByKeyName: getItemByKeyName,
    removeItemByKeyName: removeItemByKeyName
  };
};

export default LocalStorageService;
